export class CreateEditRequest {
}

export enum RequestCategory{
    LinguisticMatchCall = 2,
    InterpretationRequest = 1,
}
export enum ScheduleOptions{
    OnDemand = 1,
    PreScheduled = 2
}
export enum CommunicationType{
    PhoneCall = "Phone Call",
    InPerson = "In-Person",
    VideoCall = "Video Call",
    EsPhoneCall = "Llamada telefónica",
    EsInPerson = "En persona",
    EsVideoCall = "Videollamada",
}

export enum CommunicationTypes{
  InPerson = 1,
  PhoneCall = 2,
  VideoCall = 3,
}

export enum ShowFormView{
    FormStart = 1,
    FormMiddle = 2,
    FormLast = 3,
    FormUpdate = 4
}

export class CreateRequestModel {
    id:number=0;
    scheduleOptionsId: number|number=0;
    appointmentCategoryId: number;
    phoneNumber: string|null=null;
    phoneCodeId: string|number|null=null;
    link: string|null=null;
    serviceId: number|null=null;
    interpreterGenderRequestId: number | null;
    communicationTypeId: number|null=null;
    userId: string|null=null;
    organizationId: string|null=null;
    accessCode: string|null=null;
    // nameOfLocation: string|null=null;
    appointmentLangTypeId:number;
    // notes: any[];
    internalNote: string;
    requesterNote: string;
    interpretationLanguages:any[];
    location = new LocationObj();
    timeDuration = new TimeDuration();
    matchCallLanguages = new MatchCallLanguage();
}
export class CompleteAppoinmentModel {
  appointmentId:number|null=null;
  appointmentCompletionStateTypeId:number|string|null=null;
  actualEndTime:string|null=null;
  reasonsForEndedEarly:any[];
  interpreterNote :string|null=null;
}

  

  export class MatchCallLanguage{
    id?:number|null;
    language: string;
    langVariants: any[];
  }
  
  export class TimeDuration {
    date: string='';
    startTime: string;
    endTime: string;
    startDateTime: string;
    endDateTime: string;
  }

  export class NotesType{
    id:number|null=null;
    noteTypeId: number;
    body: string;
  }
  
  export class AppointmentAcceptableLanguage {
    id:number|null=null;
    entryState:number = 1;
    interpretationLangTypeId: number = 2;
    fromLangVariantId: number | null;
    toLangVariantId: number | null;
  }

  export class AppointmentPreferredLanguage {
    id:number|null=null;
    entryState:number = 1
    interpretationLangTypeId: number = 1;
    fromLangVariantId: number | null;
    toLangVariantId: number | null;
  }
  
  export class LocationObj {
    nameOfLocation : string|null=null;
    addressLine1: string|null=null;
    addressLine2: string|null=null
    city: string|null=null
    zipCode: string|null=null
    stateId: number|null=null;
  }

  export class checkbox{
    checkbox1:boolean;
    checkbox2:boolean;
    checkbox3:boolean;

  }

  export enum NoteType{
      InternalNote = 1,
      InterpreterNote = 2,
      RequesterNote = 3
  }

  export class InternalNotes{
    id:number;
    entryState:number;
    internalDesc:string;
  }

  export class ReqursterNotes{
    id:number;
    entryState:number;
    reqursterDesc:string;
  }

  export class InterpreterNotes{
    id:number;
    entryState:number;
    InterpreterDesc:string;
  }

  export enum EntryState
{
    POST = 1,
    PUT = 2,
    DELETE = 3
}

export const Time ={
  Hours_4 : 4,
  Minutes_15 : 0.25,
  Hours_2 : 2,
  Hours_12 : 12,
}