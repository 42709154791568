import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ModalTitle } from '../../enum/common-enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-popup',
  templateUrl: './common-popup.component.html',
  styleUrls: ['./common-popup.component.scss']
})
export class CommonPopupComponent implements OnInit {

  enumPopUpTitles = ModalTitle;
  @Input() modalTitle:string;
  isReloadAfterSave: EventEmitter<boolean> = new EventEmitter();
  isReloadAfterCancel: EventEmitter<boolean> = new EventEmitter();
  constructor(public activeModal: NgbActiveModal){}

  secondText = false;
  firstText = false;

  ngOnInit(): void {}

  confirm(){
    this.isReloadAfterSave.emit(true);
    this.activeModal.close(true);
  }

  cancel(){
    this.isReloadAfterCancel.emit(false);
    this.activeModal.close();
  }
}