import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[onlyAlphabets]'
})
export class OnlyAlphabetsDirective {
  @HostBinding('autocomplete') public autocomplete;
  @Input() onlyAlphabets: boolean = true;  
  constructor() {
    this.autocomplete = 'off';
  }
  @HostListener('keypress', ['$event']) public disableKeys(e) {
    if (!this.onlyAlphabets) {
      return true; // Do not restrict keys if onlyAlphabets is set to false
    }

    const keyCode = document.all ? e.keyCode : e.keyCode;
    return (keyCode === 8 || keyCode === 32 || (keyCode >= 97 && keyCode <= 122) || (keyCode >= 65 && keyCode <= 90));
  }
}
