import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-request-closed-modal',
  templateUrl: './request-closed-modal.component.html',
  styleUrls: ['./request-closed-modal.component.scss']
})
export class RequestClosedModalComponent {
constructor(public activeModal: NgbActiveModal,){}
}
