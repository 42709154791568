import { LoadDropdownDataService } from './load-dropdown-data.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ResponseModel } from '../../models/response-model.model';

@Component({
  selector: 'app-load-dropdown-data',
  templateUrl: './load-dropdown-data.component.html',
  styleUrls: ['./load-dropdown-data.component.scss']
})
export class LoadDropdownDataComponent {
  @Input() ids: number[];
  @Output() emitDropdownData: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(public loadDropdownDataService: LoadDropdownDataService){}

  ngOnChanges() {
    this.loadDropdownDataService.DropDownGetData(this.ids).subscribe({
      next: (res: ResponseModel) => {
        if (res.isSucceeded === true) {
          this.emitDropdownData.emit(res.response);
        } else {
          if (res.message != undefined) {
          }
          if (res?.errors?.length > 0) {
          }
        }
      },
      error: (err) => {
      },
    });
  }

}
