import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentInternalStatusString } from '../../request-list.model';

@Component({
  selector: 'app-cancel-request-confirmation',
  templateUrl: './cancel-request-confirmation.component.html',
  styleUrls: ['./cancel-request-confirmation.component.scss']
})
export class CancelRequestConfirmationComponent {

  constructor(public activeModal: NgbActiveModal){}

}
