import { LoadDropdownDataService } from './../../../../../shared/common-components/load-dropdown-data/load-dropdown-data.service';
import { NgbActiveModal, NgbDateParserFormatter, NgbModal, NgbModalOptions, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { AppointmentAcceptableLanguage, AppointmentPreferredLanguage, CommunicationType, CommunicationTypes, CompleteAppoinmentModel, CreateRequestModel, EntryState, LocationObj, MatchCallLanguage, NoteType, NotesType, RequestCategory, ScheduleOptions, ShowFormView, Time, TimeDuration, checkbox } from "./create-edit-request.model";
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { RequestSubmittedOrUnableToProceedComponent } from "../request-submitted-or-unable-to-proceed/request-submitted-or-unable-to-proceed.component";
import { CheckBoxType, FormSettingTitle, GetLookupFormEnum, ModalTitle, Roles } from "src/app/shared/enum/common-enum";
import { ResponseModel } from "src/app/shared/models/response-model.model";
import { FormSettingsService } from "src/app/components/AdminSettings/form-settings/form-settings.service";
import { ToastrService } from "ngx-toastr";
import { RequestListService } from "../../request-list.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { CommonService } from "src/app/shared/services/common.service";
import { parse, addHours, format } from 'date-fns';
import { DatePipe } from '@angular/common';
import { AppoinmentUpdatedPopupComponent } from '../appoinment-updated-popup/appoinment-updated-popup.component';
import { gender_NoPreference } from 'src/app/shared/models/common.model';
import { ApplicationLanguages, CaliforniaId, CALL_LINK_LINK_NUMBER_REGEX, CALL_LINK_VIDEO_LINK_REGEX, HOUR_EXTRACTOR_FROM_TIMESTAMPS, RegexPattern, SELECTED_LANGUAGE, USER_ACCOUNT_DETAILS } from '../../../../../shared/enum/common-enum';
import { PermissionService } from '../../../../../shared/services/permission.service';
@Component({
    selector: "app-create-edit-request",
    templateUrl: "./create-edit-request.component.html",
    styleUrls: ["./create-edit-request.component.scss"],
    providers: [DatePipe]
})
export class CreateEditRequestComponent extends CommonService {
    public currentFormattedTime: any;
    @Input() editAppointmentListData: any;
    @Input() ModalTitleFromReqList: string;
    @Input() afterAndDuringAppointmentFlag: any;
    @Output() sendAppointmentNo = new EventEmitter();
    requestCategory = RequestCategory;
    scheduleOptions = ScheduleOptions;
    communicationType = CommunicationType;
    ApplicationLanguages = ApplicationLanguages;
    languagesListData: any = [];
    formSettingTitle = FormSettingTitle;
    getLookupFormEnum = GetLookupFormEnum;
    modalTitle = ModalTitle;
    showFormView = ShowFormView;
    startTime: any;
    endTime: any;
    editNotesData: any;
    userDetails: any;
    roles = Roles;
    noteType = NoteType;
    isError = false;
    isInputError1 = false;
    isInputError2 = false;
    currentData = new Date();

    preferredFromVariantList = [];
    preferredToVariantList = [];
    acceptableFromVariantList = [];
    acceptableToVariantList = [];
    languageList:any[] = [];
    notesArray: any;
    appointmentNumber: any;
    preferedfromValue: any;
    preferedToValue: any;
    acceptableFromValue: any;
    acceptableToValue: any;
    ScheduleOptionsValue: any;
    genderDropdownList = []
    isApiProcessing : boolean = false;
    selectedLanguage : any;
    checkbox1: boolean = false;
    checkbox2: boolean = false;
    checkbox3: boolean = false;
    checkbox = new checkbox();

    check_box_type = CheckBoxType;
    currentlyChecked: CheckBoxType;

    sharedSelectedOptionId: number | null = null;
    sharedSelectedOptionId2: number | null = null;
    isDisableWhileClick = false;

    regexPattern = RegexPattern
    createRequestModel = new CreateRequestModel();
    appointmentAcceptableLanguage = new AppointmentAcceptableLanguage();
    appointmentPreferredLanguage = new AppointmentPreferredLanguage();
    completeAppoinmentModel = new CompleteAppoinmentModel();
    matchCallLanguageModel = new MatchCallLanguage();
    notesType = new NotesType();
    visibleForm: number = 0;
    timeInvalid: boolean = false;
    validLink: boolean = false;
    communicationTypes = CommunicationTypes;
    entryState = EntryState;

    isClientNoShowDisabled: boolean = false;
    isEndedEarlyDisabled: boolean = false;
    isEndedLateDisabled: boolean = false;

    @ViewChild("CreateRequestForm1") CreateRequestForm1!: NgForm;
    @ViewChild("CreateRequestForm2") CreateRequestForm2!: NgForm;
    @ViewChild("CreateRequestForm3") CreateRequestForm3!: NgForm;
    @ViewChild("CreateRequestForm4") CreateRequestForm4!: NgForm;

    idemPotencyKey: string
    communicationTypeName: string | null = null;
    isCheckReasonForCancellation = [];
    requestedEndTime=""

    isCloseRequest = false;

    checkReasonForCancellation(id) {
        return this.isCheckReasonForCancellation?.includes(id);
    }

    reasonList: string[] = ["Incorrect Language Variant",
        "Services Not Needed",
        "Used Spanish Interpreter Only"]
    public formattedCurrentTime: string;

    reasonForCancellation: any;

    isForm1Valid: boolean = false; // Assuming Form1 was previously valid
    isForm2Valid: boolean = false; // Assuming Form2 is initially not valid
    isForm3Valid: boolean = false; // Assuming Form3 is initially not valid

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        public formSettingsService: FormSettingsService,
        public toaster: ToastrService,
        public requestListService: RequestListService,
        public storageservice: StorageService,
        private ngbDateParserFormatter: NgbDateParserFormatter,
        private cdr: ChangeDetectorRef,
        public loadDropdownDataService: LoadDropdownDataService,
        public datePipe: DatePipe,
        public permissionService: PermissionService,
    ) {
        super();
    }


    ngOnInit() {
        this.userDetails = this.storageservice.retrieve(USER_ACCOUNT_DETAILS);
        this.selectedLanguage = this.storageservice.retrieve(SELECTED_LANGUAGE);
        if (this.loadDropdownDataService?.commonGetDropdownModel?.genders?.some(item => item?.id !== 0)) this.genderDropdownList = [...(this.loadDropdownDataService.commonGetDropdownModel.genders ?? []), gender_NoPreference];

        // this.formatCurrentTime();

        this.ModalTitleFromReqList
        this.loadLangageAndVariantsData();
        if (this.userDetails?.organizations[0]?.role?.value == this.roles?.Requester) {
            this.createRequestModel.userId = this.userDetails?.id;
        }
        this.assignEditData();
    }


    // formatCurrentTime(): void {
    //     const now = new Date(); // Get current date and time
    //     const utcDate = now?.toISOString(); // Converts to ISO 8601 format in UTC
    //     const offsetMatch = this.userDetails?.timezone?.match(/\(UTC([+-]\d{2}:\d{2})\)/);
    //     const dt = this.convertUTCToLocalFormat(utcDate, offsetMatch ? offsetMatch[1] : undefined);
    //     const [, year, month, date, hours, minutes, seconds] = dt?.localDate?.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/);
    //     this.currentFormattedTime = `${hours}:${minutes}`;
    // }

    ngAfterViewInit() {
        this.sharedSelectedOptionId = null;
        this.sharedSelectedOptionId2 = null;
        this.idemPotencyKey = this.generateGUID();

        if (this.userDetails?.organizations[0]?.role?.value == this.roles?.Requester) setTimeout(() => this.createRequestModel.organizationId = this.userDetails?.organizations?.[0]?.organization?.id, 100);
    }

    // convertAndAddHours(): string {
    //     if (this.createRequestModel?.timeDuration?.startTime) {
    //         const originalDate = parse(this.createRequestModel?.timeDuration?.startTime, 'HH:mm', new Date());
    //         const newDate = addHours(originalDate, 2);
    //         const result = format(newDate, 'HH:mm');
    //         return result;
    //     }
    // }

    ensureSeconds(dateTimeString: string): string {
        // Check if the time part of the string is missing seconds (e.g., '2025-02-12T21:35')
        if (/T\d{2}:\d{2}$/.test(dateTimeString)) {
            return `${dateTimeString}:00`; // Append seconds if missing
        }
        // Return the string as-is if it already includes seconds
        return dateTimeString;
    }



    validateTimeInput() {
        if (this.createRequestModel?.communicationTypeId === CommunicationTypes.InPerson) this.createRequestModel.location.stateId = CaliforniaId;
        if (!(this.createRequestModel?.timeDuration?.date && this.createRequestModel?.timeDuration?.startTime && this.createRequestModel?.timeDuration?.endTime)) return this.timeInvalid = false;
        const startDateTime = `${this.createRequestModel?.timeDuration?.date}T${this.createRequestModel?.timeDuration?.startTime}`
        const selectedDateStartTime = this.ensureSeconds(startDateTime);
        const endDateTime = `${this.createRequestModel?.timeDuration?.date}T${this.createRequestModel?.timeDuration?.endTime}`
        const selectedDateEndTime = this.ensureSeconds(endDateTime);

        const dt: any = this.convertLocalToUTC(selectedDateStartTime);
        const selectedDateStartTimeMillisecond = this.dateTimeStringToTotalMilliseconds(dt);
        const dt1: any = this.convertLocalToUTC(selectedDateEndTime);
        let selectedDateEndTimeMillisecond = this.dateTimeStringToTotalMilliseconds(dt1);

        var isDateDifferent = false;

        const endTimeMatch = selectedDateEndTime?.match(HOUR_EXTRACTOR_FROM_TIMESTAMPS);
        const startTimeMatch = selectedDateStartTime?.match(HOUR_EXTRACTOR_FROM_TIMESTAMPS);
        if (endTimeMatch && startTimeMatch) {
            const hours = parseInt(endTimeMatch[1], 10);
            const hours1 = parseInt(startTimeMatch[1], 10);
            if (hours1 > Time.Hours_12 && hours < Time.Hours_12) {
                const millisIn24Hours = 24 * 60 * 60 * 1000;
                selectedDateEndTimeMillisecond += millisIn24Hours;
                isDateDifferent = true;
            }
        }

        const timeDifference = (selectedDateEndTimeMillisecond - selectedDateStartTimeMillisecond) / (1000 * 60 * 60);
        if (this?.createRequestModel?.communicationTypeId === CommunicationTypes.InPerson && timeDifference < Time.Hours_4) return this.timeInvalid = true;
        if ((this?.createRequestModel?.communicationTypeId === CommunicationTypes.VideoCall || this?.createRequestModel?.communicationTypeId === CommunicationTypes.PhoneCall) && timeDifference < Time.Minutes_15) return this.timeInvalid = true;
        if (timeDifference < Time.Minutes_15) return this.timeInvalid = true;


        // update time duration data
        this.createRequestModel.timeDuration.startDateTime = this.createRequestModel.timeDuration.date + 'T' + this.createRequestModel.timeDuration.startTime;
        if (isDateDifferent) {
            var nextDate = this.getNextDate(this.createRequestModel.timeDuration.date)
            this.createRequestModel.timeDuration.endDateTime = this.datePipe.transform(nextDate, 'yyyy-MM-dd') + 'T' + this.createRequestModel.timeDuration.endTime;
        }
        else {
            this.createRequestModel.timeDuration.endDateTime = this.createRequestModel.timeDuration.date + 'T' + this.createRequestModel.timeDuration.endTime;
        }

        return this.timeInvalid = false;
    }

    validTimeGap : boolean = false;

    checkScheduleId(){
        if(this.ModalTitleFromReqList==this.modalTitle.EditAppointmentForm){
        return this.validTimeGap = this.editAppointmentListData?.scheduleOptionsId == this?.createRequestModel?.scheduleOptionsId ? false : true;
        }
    }

    onHideForm1() {
        if (this.createRequestModel.appointmentCategoryId == undefined) return;
        this.isForm1Valid = this.CreateRequestForm1?.valid ?? false;
        return this.isForm1Valid;
    }

    onHideForm2() {
        if (this.createRequestModel.appointmentCategoryId == undefined) return;
        this.isForm2Valid = this.CreateRequestForm2?.valid ?? false;
        return this.isForm2Valid;
    }

    onHideForm3(isValid) {
        if (this.createRequestModel.appointmentCategoryId == undefined) return;
        this.isForm3Valid = this.CreateRequestForm3?.valid ?? false;
        if (this.isForm3Valid && isValid) {
            this.submitAppointment();
        }
    }

    addLanguageVariant() {
        if (this.languageVariants.length > 4) return;
        const newLanguageVariant =
        {
            id: 0,
            variant: "",
            description: "",
            entryState: 1
        };
        this.languageVariants.push(newLanguageVariant);
    }

    shouldDisableAddVariant(): boolean {
        // const hasEmptyVariant = this.languageVariants.some(
        //     (item) => !item.variant.trim() || !item.description.trim()
        // );
        return false;
    }

    SendAppointmentNumber(appointmentNumber: number) {
        this.sendAppointmentNo.emit(appointmentNumber);
    }

    submitAppointment() {
        if (this.createRequestModel.appointmentCategoryId === 1) {
            this.setupInterpretationLanguages();
        } else {
            this.setupMatchCallLanguages();
        }
        // this.notes()
        this.convertTimeToFullFormatForDuration();
        this.insertOrUpdateAppointment();
    }

    setupInterpretationLanguages() {
        this.createRequestModel.appointmentLangTypeId = 1;
        this.createRequestModel.interpretationLanguages = [
            this.appointmentAcceptableLanguage,
            this.appointmentPreferredLanguage,
        ];
        if( this.editAppointmentListData?.interpretationLanguages?.length == 1){
            this.appointmentPreferredLanguage.id = this.modalTitle.EditAppointmentForm ? this.editAppointmentListData?.interpretationLanguages[0]?.id : null;
        } else {
            this.appointmentAcceptableLanguage.id = this.modalTitle.EditAppointmentForm ? this.editAppointmentListData?.interpretationLanguages[0]?.id : null;
            this.appointmentPreferredLanguage.id = this.modalTitle.EditAppointmentForm ? this.editAppointmentListData?.interpretationLanguages[1]?.id : null;
        }
    }

    setupMatchCallLanguages() {
        this.createRequestModel.appointmentLangTypeId = RequestCategory.LinguisticMatchCall;
        this.createRequestModel.matchCallLanguages.langVariants =
            this.languageVariants;
    }

    convertTimeToFullFormatForDuration() {
        this.createRequestModel.timeDuration.startTime =
            this.convertTimeToFullFormat(
                this.createRequestModel?.timeDuration?.startTime
            );
        if (this.requestCategory.LinguisticMatchCall == this.createRequestModel.appointmentCategoryId) {
            this.createRequestModel.timeDuration.endTime = null;

        } else {
            this.createRequestModel.timeDuration.endTime =
                this.convertTimeToFullFormat(
                    this.createRequestModel?.timeDuration?.endTime
                );
        }

    }

    extendEndTimeBy30Minutes(startTime) {
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        const endMinutes = startMinutes + 119;
        const endHours = startHours + Math.floor(endMinutes / 60);
        const updatedMinutes = endMinutes % 60;
        const updatedEndTime = `${String(endHours).padStart(2, '0')}:${String(updatedMinutes).padStart(2, '0')}:00`;
        return updatedEndTime;
    }

    insertOrUpdateAppointment() {
        if(this.createRequestModel.appointmentCategoryId == RequestCategory.LinguisticMatchCall) this.createRequestModel.timeDuration.startDateTime = this.createRequestModel.timeDuration.date + 'T' + this.createRequestModel.timeDuration.startTime;
        if (this.ModalTitleFromReqList === this.modalTitle.AppointmentForm) {
            this.insertAppointment();
        } else {
            this.updateAppointment();
        }
    }

    updateInterpretationLanguageEntryState(createRequestModel){
        if(createRequestModel?.interpretationLanguages?.length){
            createRequestModel?.interpretationLanguages?.forEach((x ,i)=>{
               if(x?.id && (!x?.fromLangVariantId || !x?.toLangVariantId) ){
                x.entryState = EntryState.DELETE;
                Reflect.deleteProperty(x , "fromLangVariantId");
                Reflect.deleteProperty(x , "toLangVariantId");
               } else if (!x?.id && (!x?.fromLangVariantId || !x?.toLangVariantId)) {
                createRequestModel?.interpretationLanguages?.splice(i ,1)
                } else if( x?.id) {
                    x.entryState = EntryState.PUT;
                } else if (!x?.id) {
                    x.entryState = EntryState.POST;
                    Reflect.deleteProperty(x , "id")
                }
            })
        }
    }

    
    insertAppointment() {
        if (this.isDisableWhileClick) return;
        this.isDisableWhileClick = true;
        const createRequestModel = JSON.parse(JSON.stringify(this.createRequestModel));
        createRequestModel?.interpreterGenderRequestId == 0 ? createRequestModel.interpreterGenderRequestId = null : null;

        if (createRequestModel?.interpretationLanguages?.length && (!createRequestModel?.interpretationLanguages[0]?.fromLangVariantId || !createRequestModel?.interpretationLanguages[0]?.toLangVariantId)) {
            createRequestModel?.interpretationLanguages?.shift();
        }

        delete createRequestModel.timeDuration.date;
        delete createRequestModel.timeDuration.startTime;
        delete createRequestModel.timeDuration.endTime;
        if(this.createRequestModel.appointmentCategoryId == RequestCategory.LinguisticMatchCall) delete createRequestModel.scheduleOptionsId;

        this.requestListService
            .InsertAppointment(createRequestModel, this.idemPotencyKey)
            .subscribe({
                next: (res: ResponseModel) => {
                    this.isDisableWhileClick = false;
                    if (res.isSucceeded) {
                        this.appointmentNumber = res.response.appointmentNumber;
                        this.SendAppointmentNumber(this.appointmentNumber);
                        this.toaster.success(res.message);
                        this.activeModal.close({ isSuccess: true });
                        this.requestSubmitted();
                    } else {
                        if (res.message != undefined) this.toaster?.error(res?.message);
                        if (res?.errors?.length > 0) this.toaster?.error(res?.errors[0]);
                        this.idemPotencyKey = this.generateGUID();
                    }
                }, error: (err) => {
                    console.log(err);
                    this.isDisableWhileClick = false;
                    this.idemPotencyKey = this.generateGUID();
                }
            });
    }

    removeVariant(i, variant , variantList){
        if(!variant?.id)
            { variantList?.splice(i,1);
            } else {
                variant.entryState = EntryState.DELETE;
            }
    }

    isShowRemoveIconForVariantList(){
     return (this.languageVariants?.filter((x)=> x.entryState != EntryState.DELETE))?.length > 1;

    }

    updateAppointment() {
        if (this.isDisableWhileClick) return
        this.isDisableWhileClick = true;
        const createUpdateRequestModel = JSON.parse(JSON.stringify(this.createRequestModel));
        createUpdateRequestModel?.interpreterGenderRequestId == 0 ? createUpdateRequestModel.interpreterGenderRequestId = null : null;
        // if (createUpdateRequestModel?.interpretationLanguages?.length &&  (!createUpdateRequestModel?.interpretationLanguages[0]?.fromLangVariantId || !createUpdateRequestModel?.interpretationLanguages[0]?.toLangVariantId)) {
        //     // createUpdateRequestModel?.interpretationLanguages?.shift();
        // }

        delete createUpdateRequestModel.timeDuration.date;
        delete createUpdateRequestModel.timeDuration.startTime;
        delete createUpdateRequestModel.timeDuration.endTime;
        if(this.createRequestModel.appointmentCategoryId == RequestCategory.LinguisticMatchCall){
            delete createUpdateRequestModel.scheduleOptionsId;
        }

        if(this.createRequestModel.appointmentCategoryId == RequestCategory.InterpretationRequest) this.updateInterpretationLanguageEntryState(createUpdateRequestModel);
        
        this.requestListService
            .UpdateAppointment(createUpdateRequestModel)
            .subscribe({
                next: (res: ResponseModel) => {
                    this.isDisableWhileClick = false;
                    if (res.isSucceeded) {
                        this.toaster.success(res.message);
                        this.activeModal.close({ isSuccess: true });
                        this.appointmentNumber = this.editAppointmentListData?.appointmentNumber;
                        this.updateAppoinmentSubmitted();
                    } else {
                        if (res.message != undefined) {
                            this.toaster.error(res.message);
                        }
                        if (res?.errors?.length > 0) {
                            this.toaster.error(res.errors[0]);
                        }
                    }
                }, error: (err) => {
                    console.log(err);
                    this.isDisableWhileClick = false;
                }
            });
    }


    getNextDate(date){
        var selectedDate = new Date(date);
        return (new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1))
    }

    updateAndValidateActualEndTime(time){
        var endDate = this.datePipe.transform(this.editAppointmentListData.endDateTime, 'yyyy-MM-dd');

        if (!(endDate)) return this.timeInvalid = false;
        const requestedDateTime = this.ensureSeconds(this.editAppointmentListData.endDateTime);
        const actualDateTime = this.ensureSeconds(`${endDate}T${time}`);

        const dt: any = this.convertLocalToUTC(requestedDateTime);
        const requestedDateTimeMillisecond = this.dateTimeStringToTotalMilliseconds(dt);
        const dt1: any = this.convertLocalToUTC(actualDateTime);
        let actualDateTimeMillisecond = this.dateTimeStringToTotalMilliseconds(dt1);

        let isDateDifferent = false;

        const endTimeMatch = actualDateTime?.match(HOUR_EXTRACTOR_FROM_TIMESTAMPS);
        const startTimeMatch = requestedDateTime?.match(HOUR_EXTRACTOR_FROM_TIMESTAMPS);
        if (endTimeMatch && startTimeMatch) {
            const hours = parseInt(endTimeMatch[1], 10);
            const hours1 = parseInt(startTimeMatch[1], 10);
            if (hours1 > 12 && hours < 12) {
                const millisIn24Hours = 24 * 60 * 60 * 1000;
                actualDateTimeMillisecond += millisIn24Hours;
                isDateDifferent = true;
            }
        }

        if (isDateDifferent) {
            var nextDate = this.getNextDate(endDate);
            this.completeAppoinmentModel.actualEndTime = `${this.datePipe.transform(nextDate, 'yyyy-MM-dd')}T${time.substring(0, 5)}:00`;
        }
        else {
            this.completeAppoinmentModel.actualEndTime = `${endDate}T${time.substring(0, 5)}:00`;
        }

        this.timeInvalid = actualDateTimeMillisecond <= requestedDateTimeMillisecond && this.checkReasonForCancellation(this.check_box_type.ENDEDLATE);
        if(!this.checkReasonForCancellation(this.check_box_type.ENDEDLATE)) this.createRequestModel.timeDuration.endTime = this.requestedEndTime;
    }

    
    updateCompleteAppointmentByInterpreter() {
        this.isApiProcessing = true;
        this.completeAppoinmentModel.appointmentId = this.createRequestModel?.id;
        if(this.completeAppoinmentModel.appointmentCompletionStateTypeId != this?.check_box_type.ENDEDLATE) this.completeAppoinmentModel.actualEndTime = null;
        this.requestListService
            .completeAppointmentByInterpreter(this.completeAppoinmentModel)
            .subscribe(
                (res: ResponseModel) => {
                    if (res.isSucceeded) {
                        this.toaster.success(res.message);
                        this.activeModal.close({ isSuccess: true, isCompletedAppointment: true });
                    } else {
                        if (res.message) {
                            this.toaster.error(res.message);
                        }
                        if (res.errors?.length) {
                            this.toaster.error(res.errors[0]);
                        }
                    }
                    this.isApiProcessing = false;
                },
                (error) => {
                    this.isApiProcessing = false;
                    this.toaster.error(error?.errors[0]);
                    console.error(error);
                }
            );
    }

    getSelectedDates(event: any) {
        this.createRequestModel.timeDuration.date = this.ngbDateParserFormatter?.format(event?.startDate);
        this.selectSheduledOption();
    }

    requestSubmitted() {
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "sm",
            windowClass: "reqSubmitted",
        };
        const modalRef = this.modalService.open(
            RequestSubmittedOrUnableToProceedComponent, modalOption
        );
        modalRef.componentInstance.appointmentNumber = this.appointmentNumber;
        modalRef.componentInstance.modalTitle = this.modalTitle.RequestSubmitted;
    }


    updateAppoinmentSubmitted() {
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "sm",
            windowClass: "reqSubmitted",
        };
        const modalRef = this.modalService.open(
            AppoinmentUpdatedPopupComponent, modalOption
        );
        modalRef.componentInstance.modalTitle = this.modalTitle.AppointmentUpdated;
    }

    clearProgress() {
        this.isForm1Valid = false;
        this.isForm2Valid = false;
        this.isForm3Valid = false;
    }

    isPreventAssignSheduledIdForEdit = false;


    assignEditData() {
        this.visibleForm = ShowFormView.FormStart;
        if (!this.editAppointmentListData) return;
        this.isPreventAssignSheduledIdForEdit = true;
        this.editAppointmentListData.startDateTime = this.removeZFromDateTime(this.editAppointmentListData.startDateTime);
        this.editAppointmentListData.endDateTime = this.removeZFromDateTime(this.editAppointmentListData.endDateTime);
        let timeDurationObj: any = {
            date: this.datePipe.transform(this.editAppointmentListData.startDateTime, 'yyyy-MM-dd'),
            startTime: this.datePipe.transform(this.editAppointmentListData.startDateTime, 'HH:mm'),
            endTime: this.datePipe.transform(this.editAppointmentListData.endDateTime, 'HH:mm'),
        };
        this.requestedEndTime = timeDurationObj?.endTime;
        let locationObj: any = {
            nameOfLocation: this.editAppointmentListData?.nameOfLocation,
            addressLine1: this.editAppointmentListData?.addressLine1,
            addressLine2: this.editAppointmentListData?.addressLine2,
            city: this.editAppointmentListData?.city,
            zipCode: this.editAppointmentListData?.zipCode,
            stateId: this.editAppointmentListData?.state.id,
        };

        if (
            this.editAppointmentListData.appointmentCategoryId ==
            this.requestCategory.InterpretationRequest
        ) {
            this.appointmentPreferredLanguage.entryState = 2;
            this.appointmentAcceptableLanguage.entryState = 2;

            if (this.editAppointmentListData?.interpretationLanguages?.length == 1) {
                let preferedLanguages: any = this.editAppointmentListData?.interpretationLanguages[0];

                this.preferedfromValue = preferedLanguages?.fromLang.id;
                this.appointmentPreferredLanguage.fromLangVariantId = preferedLanguages?.fromLangVariant?.id;

                this.preferedToValue = preferedLanguages?.toLang.id;
                this.appointmentPreferredLanguage.toLangVariantId = preferedLanguages?.toLangVariant?.id;
            } else {
                let preferedfrom: any = this.editAppointmentListData?.interpretationLanguages[1];
                this.preferedfromValue = preferedfrom?.fromLang.id;
                this.appointmentPreferredLanguage.fromLangVariantId = preferedfrom?.fromLangVariant.id;

                let preferedTo: any =
                    this.editAppointmentListData?.interpretationLanguages[1];
                this.preferedToValue = preferedTo?.toLang.id;
                this.appointmentPreferredLanguage.toLangVariantId =
                    preferedfrom?.toLangVariant.id;

                let acceptableFrom: any =
                    this.editAppointmentListData?.interpretationLanguages[0];
                this.acceptableFromValue = acceptableFrom?.fromLang.id;
                this.appointmentAcceptableLanguage.fromLangVariantId =
                    acceptableFrom?.fromLangVariant.id;

                let acceptableTo: any =
                    this.editAppointmentListData?.interpretationLanguages[0];
                this.acceptableToValue = acceptableTo?.toLang.id;
                this.appointmentAcceptableLanguage.toLangVariantId =
                    acceptableTo?.toLangVariant.id;
            }

        }

        this.createRequestModel.id = this.editAppointmentListData.id;
        this.createRequestModel.appointmentCategoryId =
            this.editAppointmentListData?.appointmentCategoryId;

        this.createRequestModel.phoneNumber =
            this.editAppointmentListData?.phoneNumber;
        this.createRequestModel.phoneCodeId =
            this.editAppointmentListData?.phoneCodeId;
        this.createRequestModel.scheduleOptionsId =
            this.editAppointmentListData?.scheduleOptionsId;
        this.createRequestModel.link = this.editAppointmentListData?.link;
        this.createRequestModel.accessCode =
            this.editAppointmentListData?.accessCode;

        this.createRequestModel.serviceId =
            this.editAppointmentListData?.service?.id;
        this.createRequestModel.interpreterGenderRequestId =
            this.editAppointmentListData?.interpreterGenderRequest?.id ?? 0;
        this.createRequestModel.userId = this.editAppointmentListData?.user?.id;
        this.createRequestModel.organizationId = this.editAppointmentListData?.organization?.id;
        this.createRequestModel.communicationTypeId =
            this.editAppointmentListData?.communicationType?.id;

        this.createRequestModel.internalNote = this.editAppointmentListData?.internalNote;
        this.createRequestModel.requesterNote = this.editAppointmentListData?.requesterNote;
        this.completeAppoinmentModel.interpreterNote = this.editAppointmentListData?.interpreterNote;

        this.createRequestModel.timeDuration = timeDurationObj;
        this.createRequestModel.location = locationObj;

        let communicationTypeIdAsString =
            this.createRequestModel?.communicationTypeId?.toString();
        communicationTypeIdAsString =
            this.editAppointmentListData?.communicationType;

        let matchCallData: any = this.editAppointmentListData?.matchCallLanguage;
        this.transformData(matchCallData);
    }

    removeZFromDateTime(dateTime: string): string {
        if(dateTime) return dateTime.replace(/Z$/, '');
    }

    //#region for getting appointment Id while editing the Appointment
    getSelectedAppointmentId(){
        const selectedAppointment = this?.loadDropdownDataService?.commonGetDropdownModel?.requestCategories?.find(
            x => x?.id === this?.createRequestModel?.appointmentCategoryId);
            return selectedAppointment?.value
    }
    //#endregion for getting appointment Id while editing the Appointment end.

    transformData(arrayData: any[]) {
        if (arrayData.length > 0) {
            const transformedObject: any = {
                language: "",
                langVariants: [],
            };
            transformedObject.language = arrayData[0].language;
            arrayData.forEach((item) => {
                transformedObject.langVariants.push({
                    id: this.modalTitle.EditAppointmentForm ? item.id : null,
                    variant: item.variant,
                    entryState: this.modalTitle.EditAppointmentForm ? EntryState?.PUT : EntryState.POST,
                    description: item.description,
                });
            });
            this.createRequestModel.matchCallLanguages = transformedObject;
            this.languageVariants = transformedObject.langVariants;
        }

    }

    private formatEnumKey(key: string): string {
        return key.replace(/([a-z])([A-Z])/g, "$1 $2");
    }

    languageVariants: any[] = [{ variant: "", description: "" , entryState : EntryState.POST }];

    reset() {
        if (this.visibleForm == this.showFormView.FormStart) {
            this.CreateRequestForm1.reset();
            this.createRequestModel.timeDuration = new TimeDuration();
            this.createRequestModel.communicationTypeId = null;
            this.createRequestModel.location = new LocationObj();
            this.createRequestModel.link = null;
            this.createRequestModel.scheduleOptionsId = 0;
            this.createRequestModel.phoneNumber = null;
            this.createRequestModel.accessCode = null;
            this.validateTimeInput();
        } else if (this.visibleForm == this.showFormView.FormMiddle) {
            this.CreateRequestForm2.reset();
            this.createRequestModel.serviceId = null;
            this.preferedfromValue = '';
            this.preferedToValue = '';
            this.acceptableFromValue = '';
            this.acceptableToValue = '';
            this.appointmentAcceptableLanguage = new AppointmentAcceptableLanguage();
            this.appointmentPreferredLanguage = new AppointmentPreferredLanguage();
            this.languageVariants = [];
        } else if (this.visibleForm == this.showFormView.FormLast) {
            this.CreateRequestForm3.reset();
            this.createRequestModel.interpreterGenderRequestId = null;
            this.createRequestModel.requesterNote = ""
            this.createRequestModel.internalNote = ""
        }
    }

    clearForm() {
        if (this.createRequestModel.appointmentCategoryId != undefined) {
            this.createRequestModel = new CreateRequestModel();
            this.appointmentPreferredLanguage.fromLangVariantId = null;
            this.appointmentAcceptableLanguage.toLangVariantId = null;
            this.preferedToValue = null;
            this.preferedfromValue = null;
            this.acceptableToValue = null;
            if (this.userDetails?.organizations[0]?.role?.value == this.roles?.Requester) {
                this.createRequestModel.userId = this.userDetails?.id;
                this.createRequestModel.organizationId = this.userDetails?.organizations?.[0]?.organization?.id;
            }
        } else {
            this.activeModal.close();
        }
    }

    validateLink() {
        if (this?.createRequestModel?.communicationTypeId == this?.communicationTypes?.VideoCall || this?.createRequestModel?.appointmentCategoryId == this?.requestCategory?.LinguisticMatchCall) {
            if (this?.createRequestModel?.appointmentCategoryId == this?.requestCategory?.LinguisticMatchCall) {
                const callLinkAndUrl = this?.createRequestModel?.link;
                if (!callLinkAndUrl) {
                    this.validLink = false;
                } else {
                    const regex = CALL_LINK_LINK_NUMBER_REGEX;
                    if (regex?.test(callLinkAndUrl)) {
                        this.validLink = false;
                    } else {
                        this.validLink = true;
                    }
                }
            } else if (this?.createRequestModel?.appointmentCategoryId == this?.requestCategory?.InterpretationRequest) {
                if (this?.createRequestModel?.link) {
                    const regex = CALL_LINK_VIDEO_LINK_REGEX;
                    if (regex?.test(this?.createRequestModel?.link)) {
                        this.validLink = false;
                    } else {
                        this.validLink = true;
                    }
                }
            }
        } else {
            this.validLink = false;
        }
    }

    showPreviousForm(formStatus) {
        // Update form progress variables to revert one step back
        if (this.isForm3Valid) {
            this.isForm3Valid = false;
        } else if (this.isForm2Valid) {
            this.isForm2Valid = false;
        } else if (this.isForm1Valid) {
            this.isForm1Valid = false;
            this.isPreventAssignSheduledIdForEdit = true;
        }
        // Update form category and visibleForm based on formStatus
        if ((formStatus == this.showFormView.FormMiddle) && (this.createRequestModel.appointmentCategoryId == this.requestCategory.InterpretationRequest)) {
            this.createRequestModel.appointmentCategoryId = this.requestCategory.InterpretationRequest;
            this.visibleForm = this.showFormView.FormStart;
        } else if ((formStatus == this.showFormView.FormMiddle) && (this.createRequestModel.appointmentCategoryId == this.requestCategory.LinguisticMatchCall)) {
            this.createRequestModel.appointmentCategoryId = this.requestCategory.LinguisticMatchCall;
            this.visibleForm = this.showFormView.FormStart;
        } else if ((formStatus == this.showFormView.FormLast) && (this.createRequestModel.appointmentCategoryId == this.requestCategory.LinguisticMatchCall)) {
            this.createRequestModel.appointmentCategoryId = this.requestCategory.LinguisticMatchCall;
            this.visibleForm = this.showFormView.FormMiddle;
        } else if ((formStatus == this.showFormView.FormLast) && (this.createRequestModel.appointmentCategoryId == this.requestCategory.InterpretationRequest)) {
            this.createRequestModel.appointmentCategoryId = this.requestCategory.InterpretationRequest;
            this.visibleForm = this.showFormView.FormMiddle;
        }
    }

    loadLangageAndVariantsData() {
        this.formSettingsService
            .getAllFormSettings(
                this.getLookupFormEnum.LanguageAndVariant,
                undefined
            )
            .subscribe((res: ResponseModel) => {
                if (res.isSucceeded === true) {
                    const filteredLookupTable = res.response.lookupTables.find(
                        item => item.value === this.formSettingTitle.languagesAndVariants
                    );

                    if (filteredLookupTable) {
                        const filteredLookupValues = filteredLookupTable.lookupValues.filter(
                            lookupValue => lookupValue.isActive === true
                        ).map(filteredLookupValue => {
                            const filteredLanguageVariant = filteredLookupValue.languageVariant.filter(
                                languageVariant => languageVariant.isActive === true
                            );
                            return {
                                ...filteredLookupValue,
                                languageVariant: filteredLanguageVariant
                            };
                        });

                        this.languagesListData = filteredLookupValues;
                    }

                    if (this.languagesListData) {
                        for (const languageItem of this.languagesListData) {
                            const languageData = {
                                id: languageItem.id,
                                value: this.selectedLanguage == ApplicationLanguages.English ? languageItem.value : languageItem.spanishValue
                            };
                            this.languageList.push(languageData);
                        }
                    }
                }
                else {
                    if (res.message != undefined) {
                        this.toaster.error(res.message);
                    }
                    if (res?.errors?.length > 0) {
                        this.toaster.error(res.errors[0]);
                    }
                }
            });
    }

    getVariant(selectedOptionId, dropdownName) {
        const selectedLanguage = this.languagesListData.find(
            (language) => language.id === selectedOptionId
        );
        switch (dropdownName) {
            case "PreferredFrom":
            case "AcceptableFrom":
                this.preferredFromVariantList =
                    selectedLanguage?.languageVariant?.map((item) => ({
                        id: item.id,
                        value: this.selectedLanguage == ApplicationLanguages.English ? item?.value : item?.spanishValue,
                        description: this.selectedLanguage == ApplicationLanguages.English ? item?.description : item?.spanishDescription,
                    }));
                this.acceptableFromVariantList = this.preferredFromVariantList;
                if (this.acceptableFromValue == this.acceptableToValue || !selectedOptionId) this.acceptableToValue = null;

                break;
            case "PreferredTo":
                this.preferredToVariantList =
                    selectedLanguage?.languageVariant?.map((item) => ({
                        id: item.id,
                        value: this.selectedLanguage == ApplicationLanguages.English ? item?.value : item?.spanishValue,
                        description: this.selectedLanguage == ApplicationLanguages.English ? item?.description : item?.spanishDescription,
                    }));
                break;
            case "AcceptableTo":
                this.acceptableToVariantList =
                    selectedLanguage?.languageVariant?.map((item) => ({
                        id: item.id,
                        value: this.selectedLanguage == ApplicationLanguages.English ? item?.value : item?.spanishValue,
                        description: this.selectedLanguage == ApplicationLanguages.English ? item?.description : item?.spanishDescription,
                    }));
                break;
        }

        if (dropdownName == "PreferredFrom" || dropdownName == "PreferredTo") {
            this.sharedSelectedOptionId = selectedOptionId;
        } else if (
            dropdownName == "AcceptableFrom" ||
            dropdownName == "AcceptableTo"
        ) {
            this.sharedSelectedOptionId2 = selectedOptionId;
        }
        this.cdr.detectChanges();
    }

   //#region clear language varient dropdown based on language
    clearVarient(dropdownName , list , id){
        const validVariant = list?.some(variant => variant.id === id);
        if (dropdownName == "PreferredFrom" && !validVariant) {
            this.appointmentPreferredLanguage.fromLangVariantId = null;
            this.appointmentAcceptableLanguage.fromLangVariantId = null;
        }
        if(dropdownName == "PreferredTo"   && !validVariant) this.appointmentPreferredLanguage.toLangVariantId = null;
        if(dropdownName == "AcceptableTo"   && !validVariant) this.appointmentAcceptableLanguage.toLangVariantId = null
    }
    //#endregion

    selectCheckBox(isCheck, value) {
        // this.reasonForCancellation = value;
        if (value === CheckBoxType.ENDEDLATE) {
            this.isClientNoShowDisabled = true;
            this.isEndedEarlyDisabled = true;
        } else if (value === CheckBoxType.ENDEDEARLY) {
            this.isClientNoShowDisabled = true;
            this.isEndedLateDisabled = true;
        } else if (value === CheckBoxType.CLIENTNOSHOW) {
            this.isEndedEarlyDisabled = true;
            this.isEndedLateDisabled = true;
        }

        if (isCheck) {
            this.isCheckReasonForCancellation = [];
            this.isCheckReasonForCancellation?.push(value);
            this.currentlyChecked = value;
            // this.completeAppoinmentModel.appointmentCompletionStateTypeId =value
            if (value === CheckBoxType.ENDEDLATE) {
                this.isClientNoShowDisabled = true;
                this.isEndedEarlyDisabled = true;
            } else if (value === CheckBoxType.ENDEDEARLY) {
                this.isClientNoShowDisabled = true;
                this.isEndedLateDisabled = true;
            } else if (value === CheckBoxType.CLIENTNOSHOW) {
                this.isEndedEarlyDisabled = true;
                this.isEndedLateDisabled = true;
            }
        } else {
            this.isCheckReasonForCancellation = [];
            this.isClientNoShowDisabled = false;
            this.isEndedEarlyDisabled = false;
            this.isEndedLateDisabled = false;
            this.completeAppoinmentModel.appointmentCompletionStateTypeId = null;
        }
        // this.completeAppoinmentModel.appointmentCompletionStateTypeId = this.isCheckReasonForCancellation?.length ? this.isCheckReasonForCancellation?.length[0] :null
    }

    // checkCharacterCount() {
    //     this.isError = this.editNotesData?.length > 750;
    // }

    //#region  local to utc date time
    convertLocalToTargetTimeZone(targetTimezoneOffset, hours?) {
        const date = new Date();
        // date.setHours(24);
        const utcDateTime = date.toISOString();  // Convert local date-time to UTC
        return this.convertTimes(utcDateTime, targetTimezoneOffset, hours);
    }

    convertTimes(utcDateTime, targetTimezoneOffset, hours): number {
        if (utcDateTime || targetTimezoneOffset) {
            const convertedTime = this.datePipe.transform(utcDateTime, 'yyyy-MM-ddTHH:mm:ss', targetTimezoneOffset);  // Adjust UTC date-time to the target timezone offset

            const dt = this.convertLocalToUTC(convertedTime);
            const dtTimeInMillisecond = this.dateTimeStringToTotalMilliseconds(dt, hours);
            return dtTimeInMillisecond;
        } else {
            return 0
        }
    }

    convertLocalToUTC(localDateTimeString: string): string | null {
        return this.datePipe.transform(localDateTimeString, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
    }

    dateTimeStringToTotalMilliseconds(dateTimeString: string, hours?): number {
        const dt = dateTimeString + 'Z'
        const dateTime = new Date(dt);
        if (hours) dateTime.setHours(hours);
        return Date.parse(dt);
        // return dateTime.getTime();
    }




    selectSheduledOption() {
        if (this.createRequestModel?.timeDuration?.startTime && this.createRequestModel?.timeDuration?.date) {
            const dateTime = `${this.createRequestModel?.timeDuration?.date}T${this.createRequestModel?.timeDuration?.startTime}`
            // const selectedDateStartTime = !this.editAppointmentListData ?  `${dateTime}:00` : dateTime;
            const selectedDateStartTime = this.ensureSeconds(dateTime);
            const dt = this.convertLocalToUTC(selectedDateStartTime);
            const selectedDateStartTimeMillisecond = this.dateTimeStringToTotalMilliseconds(dt);
            const userDetails = this.storageservice.retrieve(USER_ACCOUNT_DETAILS);
            const offsetMatch = userDetails?.timezone?.match(/\(UTC([+-]\d{2}:\d{2})\)/);
            let dateTimeMillisecond = offsetMatch ? this.convertLocalToTargetTimeZone(offsetMatch[1]) : this.convertLocalToTargetTimeZone('UTC');
            dateTimeMillisecond += 1 * 60 * 1000;
            // const millisecondsIn24Hours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
            const millisecondsIn4Hours = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
            const added24HoursDateTimeMillisecond = dateTimeMillisecond + millisecondsIn4Hours;
            if (selectedDateStartTimeMillisecond < dateTimeMillisecond) {
                if (!this.isPreventAssignSheduledIdForEdit) this.createRequestModel.scheduleOptionsId = undefined;
            } else if (selectedDateStartTimeMillisecond < added24HoursDateTimeMillisecond) {
                this.createRequestModel.scheduleOptionsId = 1
            } else {
                this.createRequestModel.scheduleOptionsId = 2
            }
            // this.createRequestModel.scheduleOptionsId = selectedDateStartTimeMillisecond < added24HoursDateTimeMillisecond ? 1 : 2 ;
        } else {
            this.createRequestModel.scheduleOptionsId = undefined;
        }
        this.isPreventAssignSheduledIdForEdit = false;
    }

}