import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tiemFormatChange'
})
export class TiemFormatChangePipe implements PipeTransform {
  transform(timeString: string): string {
    const date = new Date(`2000-01-01T${timeString}`);
    const formattedTime = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
    return formattedTime;
  }


}
