import { RelativeTimePipe } from './../../../../custom-pipes/relative-time.pipe';
import { ToastrService } from 'ngx-toastr';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationSidebarComponent } from '../../../notification-sidebar/notification-sidebar.component';
import { NotificationService } from './notification.service';
import { getMessaging, onMessage } from "firebase/messaging";
import { Ids, Notifications } from "./notification.model"
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { catchError, interval, map, startWith } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [RelativeTimePipe]
})
export class NotificationComponent implements OnInit {
  public openNotification: boolean = false;
  public openClosedSidebarNotification: boolean = false;
  @ViewChild("notificationSidebarComponent") notificationSidebarComponent!: NotificationSidebarComponent;

  notificationList: Notifications[] = [];
  notificationIds = new Ids();

  constructor(private offcanvasService: NgbOffcanvas, public notificationService: NotificationService, public toaster: ToastrService,
    private relativeTimePipe: RelativeTimePipe) { }
  ngOnInit() {
    // this.listen();
    this.getAllNotifications();
    setInterval(() => {
      this.updateTimeForNotifications();
    }, 1000);
  }

  updateTimeForNotifications() {
    this.notificationList.map(a => a.timeDuration)
    this.notificationList.forEach(item => {
      item.timeDuration = this.relativeTimePipe.transform(item.triggeredDateTime);
    });
  }

  getAllNotifications() {
    this.notificationService.getAllNotifications()
      .subscribe((res: ResponseModel) => {
        if (res.isSucceeded) {
          this.notificationList = res.response;
        } else {
          if (res.message != undefined) {
            this.toaster.error(res.message);
          }
          if (res?.errors?.length > 0) {
            this.toaster.error(res.errors[0]);
          }
        }
      });
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }
  openEnd(content: TemplateRef<any>) {
    this.openClosedSidebarNotification = true;
    setTimeout(() => {
      const offcanvasRef: NgbOffcanvasRef = this.offcanvasService.open(this.notificationSidebarComponent.content, { position: 'end' });
      offcanvasRef.dismissed.subscribe(() => {
        this.openClosedSidebarNotification = false;
      });
    }, 100);

  }

  markAsread(item) {
    item.isRead = true;
    this.notificationIds.ids = [item.id];
    this.notificationService.markAsReadNotifications(this.notificationIds)
      .subscribe((res: ResponseModel) => {
        if (res.isSucceeded) {
          // this.notificationList = res.response;
          this.toaster.success(res.message);
        } else {
          if (res.message != undefined) {
            this.toaster.error(res.message);
          }
          if (res?.errors?.length > 0) {
            this.toaster.error(res.errors[0]);
          }
        }
      });
  }

  markAllAsread() {
    this.notificationList?.map((x) => x.isRead = true);
    this.notificationIds.ids = this.notificationList?.map(a => a.id);
    this.notificationService.markAsReadNotifications(this.notificationIds)
      .subscribe((res: ResponseModel) => {
        if (res.isSucceeded) {
          // this.notificationList = res.response;
          this.toaster.success(res.message);
        } else {
          if (res.message != undefined) {
            this.toaster.error(res.message);
          }
          if (res?.errors?.length > 0) {
            this.toaster.error(res.errors[0]);
          }
        }
      });
  };

  checkAsmarkStatus(): boolean {
    return this.notificationList?.some(x => x.isRead !== true);
  }


  getNotificationCount(): number {
    return this.notificationList?.filter(x => x?.isRead !== true).length;
  }

}