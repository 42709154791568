<div class="container-fluid p-4">
    <div class="row px-4">
        <div class="col-12 mb-4 px-0">
            <div class="d-flex align-items-center justify-content-between mt-2">
                <p *ngIf="userDetails?.organizations[0]?.role?.value  == roles?.Interpreter" class="modalTitle f-18 color-primary-grey font-bold mb-0">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.HEADING.ACCEPTREQUEST' | translate}}?</p>
                <p *ngIf="userDetails?.organizations[0]?.role?.value != roles?.Interpreter" class="modalTitle f-18 color-primary-grey font-bold mb-0">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.HEADING.APPROVEREQUEST' | translate}}?</p>
                <div class="cursor-pointer" (click)="activeModal.close()">
                    <svg id="close_1_" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                        <path id="Path_35127" data-name="Path 35127" d="M1.423,17.434a1.411,1.411,0,0,1-1.009-.345,1.449,1.449,0,0,1,0-2.027l12.064-12.2a1.41,1.41,0,0,1,2.008.067,1.45,1.45,0,0,1,.066,1.888L2.417,17.089a1.411,1.411,0,0,1-.995.345Z" transform="translate(-0.001 -2.442)" fill="#e0532f"/>
                        <path id="Path_35128" data-name="Path 35128" d="M14.928,16.836a1.413,1.413,0,0,1-.995-.417L1.87,4.216A1.449,1.449,0,0,1,2.025,2.19a1.409,1.409,0,0,1,1.848,0l12.134,12.2a1.449,1.449,0,0,1,.066,2.032q-.032.035-.066.067a1.41,1.41,0,0,1-1.08.345Z" transform="translate(-1.458 -1.844)" fill="#e0532f"/>
                    </svg>                  
                </div>
            </div>
            <div class="col" *ngIf="acceptedRequestlistData?.appointmentCategoryId == requestCategory.InterpretationRequest">
                <div class="d-flex align-items-center gap-4 mb-4">
                    <div class="color-primary text-nowrap font-semi-bold f-13">{{acceptedRequestlistData?.appointmentCategoryId == requestCategory.LinguisticMatchCall?('APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.LINGUISTICMATCHCALL' | translate):('APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.INTERPRETATIONREQUEST' | translate)}}</div> 
                    <div class="font-Regular f-10 color-greylight">{{'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_CREATED_ON' | translate}} {{acceptedRequestlistData?.createdDate | date:'mediumDate'}}</div>
                </div>
                <div class="d-flex gap-4 mb-3">
                    <div class="col-4">
                        <div class="color-primary font-semi-bold f-13 mb-1">{{acceptedRequestlistData?.interpretationLanguages[0]?.fromLang?.value}} {{acceptedRequestlistData?.interpretationLanguages[0]?.fromLang?.value? 'to':('COMMON.NOTAVAILABLE' | translate)}} {{acceptedRequestlistData?.interpretationLanguages[0]?.toLang?.value}}</div>
                        <div class="color-primary font-Regular f-13">{{acceptedRequestlistData?.organization?.value}}</div>
                    </div>
                    <div class="col-7 gap-4 gap-sm-0 d-flex">
                        <span class="col-4 color-primary font-Regular f-13 mb-1">{{acceptedRequestlistData?.startDateTime  | dateFormat}}</span>
                        <span class="color-primary font-Regular f-13">{{acceptedRequestlistData?.startDateTime  | timeFormatAMPM :true}} - {{acceptedRequestlistData?.endDateTime | timeFormatAMPM :true}}</span>
                    </div>
                </div>
                <div class="d-flex align-items-center gap-4 mb-3">
                    <div class="col-3">
                        <div class="color-primary-grey font-semi-bold f-13 mb-1">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.SERVICETYPE' | translate}}</div>
                        <div class="color-primary-grey font-Regular f-13">{{acceptedRequestlistData?.service?.value}}</div>
                    </div>
                    <div class="col-4">
                        <div class="color-primary-grey font-semi-bold f-13 mb-1">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.COMMUNICATIONTYPE' | translate}}</div>
                        <div class="color-primary-grey font-Regular f-13">{{acceptedRequestlistData?.communicationType?.value}}</div>
                    </div>
                    <div class="col">
                        <div class="color-primary-grey font-semi-bold f-13 mb-1">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.SCHEDULEOPTION' | translate}}</div>
                        <div class="color-primary-grey font-Regular f-13">{{acceptedRequestlistData?.scheduleOptionsId == scheduleOptions.OnDemand ? ('COMMON.SCHEDULEOPTION.ONDEMAND' | translate):('COMMON.SCHEDULEOPTION.PRESCHEDULED' | translate)}}</div>
                    </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                    <div class="col">
                        <div class="color-primary-grey font-semi-bold f-13 mb-1">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.LOCATION' | translate}}</div>
                        <div class="color-primary-grey font-Regular f-13">{{acceptedRequestlistData?.location}}</div>
                    </div> 
                </div>
                <div class="d-flex align-items-center gap-4 mb-2">
                    <div class="col-4">
                        <div class="color-primary-grey font-semi-bold f-13 mb-1">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.GENDERREQUEST' | translate}}</div>
                        <div class="color-primary-grey font-Regular f-13">{{acceptedRequestlistData?.interpreterGenderRequest?.value ??'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.GENDERREQUEST.OPTION.NOPREFERENCE' | translate }}</div>
                    </div> 
                    <div class="col-4" *ngIf="acceptedRequestlistData?.communicationType?.id==communicationType.PhoneCall">
                        <div class="color-primary-grey font-semi-bold f-13 mb-1">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.CALLLINK/NUMBER' | translate}}</div>
                        <div class="color-primary-grey font-Regular f-13">{{acceptedRequestlistData?.displayContactNumber}}</div>
                    </div>
                </div>
            </div>
            
            <div class="col" *ngIf="acceptedRequestlistData?.appointmentCategoryId == requestCategory.LinguisticMatchCall"> 
                <div class="d-flex align-items-center gap-4 mb-4">                    
                    <div class="col-4 color-primary font-semi-bold f-13 mb-1">{{acceptedRequestlistData?.appointmentCategoryId == requestCategory.LinguisticMatchCall?'Linguistic Match Call':'Interpretation Request'}}</div> 
                    <div class="col-4 font-Regular f-10 color-greylight">{{'REQUESTLIST.ASSIGN_INTERPRETER_POPUP_CREATED_ON' | translate}} {{acceptedRequestlistData?.createdDate | date:'mediumDate'}}</div>
                </div>
                <div class="d-flex align-items-center gap-4 mb-3">
                    <div class="col-4">
                        <div class="color-primary font-Regular f-13">{{acceptedRequestlistData?.organization?.value}}</div>
                    </div>
                    <div class="col-7  gap-4 gap-sm-0 d-flex">
                        <span class="col-4 color-primary font-Regular f-13 mb-1">{{acceptedRequestlistData?.startDateTime | dateFormat}}</span>
                        <span class="color-primary font-Regular f-13">{{acceptedRequestlistData?.startDateTime | timeFormatAMPM :true }}</span>
                    </div>
                </div>
                <div class="d-flex gap-4 mb-3">
                    <div class="col-3">
                        <div class="color-primary-grey font-semi-bold f-13 mb-1">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.LOCATION' | translate}}</div>
                        <div class="color-primary-grey font-Regular f-13">{{acceptedRequestlistData?.location}}</div>
                    </div>
                    <div class="col-4">
                        <div class="color-primary-grey font-semi-bold f-13 mb-1">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.GENDERREQUEST' | translate}}</div>
                        <div class="color-primary-grey color-primary font-Regular f-13">{{acceptedRequestlistData?.interpreterGenderRequest?.value ??'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.GENDERREQUEST.OPTION.NOPREFERENCE' | translate }}</div>
                    </div>
                    <div class="col">
                        <div class="color-primary-grey font-semi-bold f-13 mb-1">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.CALLLINK/NUMBER' | translate}}</div>
                        <div class="color-primary-grey font-Regular f-13 four-line-truncate">{{acceptedRequestlistData?.link || ('COMMON.NOTAVAILABLE' | translate)}}</div>
                    </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                    <div class="col">
                        <div class="color-primary-grey font-semi-bold f-13 mb-1">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.LANGUAGE' | translate}}</div>
                        <div class="color-primary-grey font-Regular f-13">{{acceptedRequestlistData?.matchCallLanguage[0]?.language}}</div>
                    </div> 
                </div>
                <div class="row d-flex align-items-center">
                    <div class="col-4 mb-2" *ngFor="let item of acceptedRequestlistData?.matchCallLanguage; let i=index">
                        <div class="color-primary-grey font-semi-bold f-13 mb-1">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.LOGS.TABLE.VARIENT' | translate}} {{i+1}}</div>
                        <div class="color-primary-grey font-Regular f-13">{{item?.variant || ('COMMON.NOTAVAILABLE' | translate)}}</div>
                    </div> 
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center gap-4 pt-2 pb-3">
            <app-button 
                [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.DECLINE'"
                [buttonClass]="'button-danger-outline buttonBig w-65px'" 
                [buttonType]="'submit'" 
                [id]="'decline_button'"
                [name]="'decline_button'"
                (buttonOutputEvent)="getdeclined(acceptedRequestlistData?.id)"
                >
                <!-- (click)="getdeclined(acceptedRequestlistData?.id)" -->
            </app-button>
            <app-button 
                [buttonLabel]="userDetails?.organizations[0]?.role?.value == roles?.Interpreter? 'COMMON.BUTTON.BUTTONLABEL.ACCEPT': 'COMMON.BUTTON.BUTTONLABEL.APPROVE'"
                [buttonClass]="'button-primary buttonBig w-65px'"
                [buttonType]="'submit'" 
                [isProcessing]="enableButton"
                [id]="'accept_button'" 
                [name]="'accept_button'"
                (buttonOutputEvent)="userDetails?.organizations[0]?.role?.value == roles?.Interpreter ? openInterpreterCodeOfEthicsPopup() : getAccepted(acceptedRequestlistData?.id)">
            </app-button>           
        </div> 
    </div>
</div>