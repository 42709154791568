import { DataService } from './../../../../../shared/services/data.service';
import { ResponseModel } from './../../../../../shared/models/response-model.model';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { NewGetInterpreterModel } from './assign-interpreters.model';

@Injectable({
  providedIn: 'root'
})
export class AssignInterpretersService {

  constructor(public service: DataService) {}

  getInterpreterList(data:NewGetInterpreterModel):Observable<ResponseModel>{
    let url = "clo-api/cielo/metadata/appointments/matched-interpreters";
      return this.service.get(url,true,data).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
}
