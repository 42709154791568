import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule)
  },
  {
    path:"appointmentsAndScheduling/request-list",
    loadChildren: () => import("../../components/appointments-and-scheduling/request-list/request-list.module").then((m) => m.RequestListModule)
  },
  {
    path:"appointmentsAndScheduling/calendar",
    loadChildren: () => import("../../components/appointments-and-scheduling/calendar/calendar.module").then((m) => m.CalenderModule)
  },
  {
    path:"appointmentsAndScheduling/logs/:id",
    loadChildren: () => import("../../components/appointments-and-scheduling/appointments-scheduling-logs/appointments-scheduling-logs.module").then((m) => m.AppointmentsSchedulingLogsModule)
  },
  {
    path: "reports",
    loadChildren: () => import("../../components/reports/reports.module").then((m) => m.ReportsModule)
  },
  {
    path: "admin-setting/user-management",
    loadChildren: () => import("../../components/AdminSettings/user-management/user-management.module").then((m) => m.UserManagementModule)
  },
  {
    path: "admin-setting/form-settings",
    loadChildren: () => import("../../components/AdminSettings/form-settings/form-settings.module").then((m) => m.FormSettingsModule)
  },
  {
    path: "admin-setting/audit-log",
    loadChildren: () => import("../../components/AdminSettings/audit-log/audit-log.module").then((m) => m.AuditLogModule)
  },
  {
    path:"user-settings/userdetails/:rowId",
    loadChildren: () => import("../../components/user-settings/user-settings.module").then((m) => m.UserSettingsModule)
  }
];
