export class Notification {
}

export class Notifications {
    id:string;
    userId:string | null;
    title:string | null;
    body:string | null;
    subtitle: string | null;
    isRead:boolean;
    isNotified:boolean;
    triggeredDateTime:string;
    timeDuration?:string;
}

export class Ids{
    ids:string[]=[];
}
