<div class="container-fluid p-4">
    <div class="row px-3">
        <div class="col-12 mb-4 px-0">
            <div class="d-flex align-items-center justify-content-center mt-2 mb-4">
                <p  class="f-13 color-primary font-semi-bold mb-0">{{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.CONFIRMEDETHICCODE" | translate}}</p>
            </div>
            <div>
                <p  class="f-13 color-primary-grey font-semi-bold mb-2 text-center">
                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.CONFIRMEDETHICMESSAGE" | translate}}
                </p>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center gap-4 py-1">
            <p class="f-13 color-red font-semi-bold mb-1 cursor-pointer" (click)="activeModal.close()">{{"COMMON.BUTTON.BUTTONLABEL.CLOSE" | translate }}</p>      
            <p class="f-13 color-primary font-semi-bold mb-1 cursor-pointer" (click)="confirm()">{{"COMMON.BUTTON.BUTTONLABEL.CONFIRM" | translate }}</p>      
 
        </div> 
    </div>
</div>