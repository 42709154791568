export class Dashboard {
}

export class DateModel {
    startDate: string;
    endDate: string;
}

export class GetDashboardData
{
    appointmentCategoryId?: number;
    languageVariantId?: number;
    organizationId?: number;
    communicationTypeId?: number;
    dateRange = new DateModel();
    requestsHappensInNextDays?:number;
    // isRequestLanguages?:boolean = false;
    languageTypeId:number = LanguageType.Requested;
}

export class RadialChartModel
{
    label:string = "NA";
    count:number = 0;
    colorHexCode:string;
    percentage:number = 0;
}

export enum AppointementRequestCategory {
    InterprterRequest = 1,
    LinguisticMatchCall = 2
}

export enum LanguageType
{
    Actual = 1,
    Requested = 2
}


export const MarkerData = [
    { latLng: [32.806671, -86.79113], state: 'Alabama', count: 0 },
    { latLng: [58.3019, -134.4197], state: 'Alaska', count: 0 },
    { latLng: [33.729759, -111.431221], state: 'Arizona', count: 0 },
    { latLng: [34.969704, -92.373123], state: 'Arkansas', count: 0 },
    { latLng: [36.116203, -119.681564], state: 'California', count: 0 },
    { latLng: [39.059811, -105.311104], state: 'Colorado', count: 0 },
    { latLng: [41.597782, -72.755371], state: 'Connecticut', count: 0 },
    { latLng: [38.896198, -77.026298], state: 'Delaware', count: 0 },
    { latLng: [27.766279, -81.686783], state: 'Florida', count: 0 },
    { latLng: [32.678125, -83.222976], state: 'Georgia', count: 0 },
    { latLng: [20.796936, -156.331924], state: 'Hawaii', count: 0 },
    { latLng: [44.240459, -114.478828], state: 'Idaho', count: 0 },
    { latLng: [40.349457, -88.986137], state: 'Illinois', count: 0 },
    { latLng: [39.849426, -86.258278], state: 'Indiana', count: 0 },
    { latLng: [42.011539, -93.210526], state: 'Iowa', count: 0 },
    { latLng: [38.5266, -96.726486], state: 'Kansas', count: 0 },
    { latLng: [37.66814, -84.670067], state: 'Kentucky', count: 0 },
    { latLng: [31.169546, -91.867805], state: 'Louisiana', count: 0 },
    { latLng: [45.076076, -69.386171], state: 'Maine', count: 0 },
    { latLng: [39.045755, -76.641271], state: 'Maryland', count: 0 },
    { latLng: [42.407211, -71.382439], state: 'Massachusetts', count: 0,},
    { latLng: [44.182205, -84.506836], state: 'Michigan', count: 0 },
    { latLng: [46.39241, -94.63623], state: 'Minnesota', count: 0 },
    { latLng: [32.741646, -89.678696], state: 'Mississippi', count: 0 },
    { latLng: [38.456085, -92.288368], state: 'Missouri', count: 0 },
    { latLng: [46.921925, -110.454353], state: 'Montana', count: 0 },
    { latLng: [41.12537, -98.268082], state: 'Nebraska', count: 0 },
    { latLng: [38.313515, -117.055374], state: 'Nevada', count: 0 },
    { latLng: [43.452492, -71.563896], state: 'New Hampshire', count: 0,},
    { latLng: [40.298672, -74.521973], state: 'New Jersey', count: 0 },
    { latLng: [35.682839, -105.939728], state: 'New Mexico', count: 0 },
    { latLng: [42.953167, -75.526672], state: 'New York', count: 0 },
    { latLng: [35.492207, -79.453522], state: 'North Carolina', count: 0,},
    { latLng: [47.536232, -99.90181], state: 'North Dakota', count: 0 },
    { latLng: [40.388783, -82.764915], state: 'Ohio', count: 0 },
    { latLng: [35.565342, -96.928917], state: 'Oklahoma', count: 0 },
    { latLng: [44.572021, -122.070938], state: 'Oregon', count: 0 },
    { latLng: [40.590752, -77.209755], state: 'Pennsylvania', count: 0,},
    { latLng: [41.680893, -71.51178], state: 'Rhode Island', count: 0 },
    { latLng: [33.856892, -80.945007], state: 'South Carolina', count: 0,},
    { latLng: [44.299782, -99.438828], state: 'South Dakota', count: 0,},
    { latLng: [35.747845, -86.692345], state: 'Tennessee', count: 0 },
    { latLng: [31.054487, -97.563461], state: 'Texas', count: 0 },
    { latLng: [39.550051, -111.546667], state: 'Utah', count: 0 },
    { latLng: [44.045876, -72.710686], state: 'Vermont', count: 0 },
    { latLng: [37.769337, -78.169968], state: 'Virginia', count: 0 },
    { latLng: [47.400902, -121.490494], state: 'Washington', count: 0 },
    { latLng: [38.491226, -80.954071], state: 'West Virginia', count: 0,},
    { latLng: [44.268543, -89.616508], state: 'Wisconsin', count: 0 },
    { latLng: [42.755966, -107.30249], state: 'Wyoming', count: 0 },
];
//vector map

export const stateMappings = {
    'US-AL': 'Alabama',
    'US-AK': 'Alaska',
    'US-AZ': 'Arizona',
    'US-AR': 'Arkansas',
    'US-CA': 'California',
    'US-CO': 'Colorado',
    'US-CT': 'Connecticut',
    'US-DE': 'Delaware',
    'US-FL': 'Florida',
    'US-GA': 'Georgia',
    'US-HI': 'Hawaii',
    'US-ID': 'Idaho',
    'US-IL': 'Illinois',
    'US-IN': 'Indiana',
    'US-IA': 'Iowa',
    'US-KS': 'Kansas',
    'US-KY': 'Kentucky',
    'US-LA': 'Louisiana',
    'US-ME': 'Maine',
    'US-MD': 'Maryland',
    'US-MA': 'Massachusetts',
    'US-MI': 'Michigan',
    'US-MN': 'Minnesota',
    'US-MS': 'Mississippi',
    'US-MO': 'Missouri',
    'US-MT': 'Montana',
    'US-NE': 'Nebraska',
    'US-NV': 'Nevada',
    'US-NH': 'New Hampshire',
    'US-NJ': 'New Jersey',
    'US-NM': 'New Mexico',
    'US-NY': 'New York',
    'US-NC': 'North Carolina',
    'US-ND': 'North Dakota',
    'US-OH': 'Ohio',
    'US-OK': 'Oklahoma',
    'US-OR': 'Oregon',
    'US-PA': 'Pennsylvania',
    'US-RI': 'Rhode Island',
    'US-SC': 'South Carolina',
    'US-SD': 'South Dakota',
    'US-TN': 'Tennessee',
    'US-TX': 'Texas',
    'US-UT': 'Utah',
    'US-VT': 'Vermont',
    'US-VA': 'Virginia',
    'US-WA': 'Washington',
    'US-WV': 'West Virginia',
    'US-WI': 'Wisconsin',
    'US-WY': 'Wyoming',
};

export const UsStates = [
    'US-AL', 'US-AK', 'US-AZ', 'US-AR', 'US-CA', 'US-CO', 'US-CT', 'US-DE', 'US-FL', 'US-GA',
    'US-HI', 'US-ID', 'US-IL', 'US-IN', 'US-IA', 'US-KS', 'US-KY', 'US-LA', 'US-ME', 'US-MD',
    'US-MA', 'US-MI', 'US-MN', 'US-MS', 'US-MO', 'US-MT', 'US-NE', 'US-NV', 'US-NH', 'US-NJ',
    'US-NM', 'US-NY', 'US-NC', 'US-ND', 'US-OH', 'US-OK', 'US-OR', 'US-PA', 'US-RI', 'US-SC',
    'US-SD', 'US-TN', 'US-TX', 'US-UT', 'US-VT', 'US-VA', 'US-WA', 'US-WV', 'US-WI', 'US-WY'
];

export enum Color {
    "New"   = "#727272",
    "Pending" = "#727272",
    "Under Review" = "#727272",
    "Approved" = "#047BBC",
    "Accepted"= "#047BBC",
    "Assignee Pending" = "#047BBC",
    "Assigned" = "#047BBC",
    "Completed" = "#047BBC",
    "Cancelled" = "#E0532F",
    "Declined" = "#E0532F",
    "FallBack" = "#E0532F" ,

    // spanish 
    "Nueva" = "#727272",
    "Pendiente"   = "#727272",
    "Bajo revisión" = "#727272",
    "Aprobada" = "#047BBC",
    "Aceptada" = "#047BBC",
    "Asignado Pendiente" = "#047BBC",
    "Asignada" = "#047BBC",
    "Completada" = "#047BBC",
    "Cancelada" = "#E0532F",
    "Rechazada" = "#E0532F",
}
// This means for all other it should use this color