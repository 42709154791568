<div ngbDropdown #dropdown="ngbDropdown" class="d-inline-block commonInputContainer w-100" (openChange)="openChange($event)">
    <label *ngIf="inputLabel" [for]="id" [ngClass]="smallInput ? 'f-11' : 'f-12'" class="form-label"><span class="label-text">{{inputLabel | translate}}</span><span *ngIf="isRequired" class="required-color">*</span></label>
    <div class="time_range_inputs" (click)="changeCurrentTimeToPreviousValue(timeRangePickerInput?.FirstTimpePicker) ; changeCurrentTimeToPreviousValue(timeRangePickerInput?.SecondTimpePicker)">
        <input appTimeFormatCheck autocomplete="off" readonly [disabled]="inputDisabled" [name]="name+'1'" [id]="id+'1'" appTimeFormatCheck class="form-control invisible" placeholder="HH:MM TT" type="text" ngbDropdownToggle [(ngModel)]="timeRangePickerValue1" [ngModel]="timeRangePickerValue1 | formatTimeInput" (ngModelChange)="changeCurrentTimeToPreviousValue(timeRangePickerInput?.FirstTimpePicker)" [required]="isRequired" (click)="changeCurrentTimeToPreviousValue(timeRangePickerInput?.FirstTimpePicker)" />
        <input appTimeFormatCheck autocomplete="off" readonly [disabled]="inputDisabled" [name]="name+'2'" [id]="id+'2'" appTimeFormatCheck class="form-control invisible" placeholder="HH:MM TT" type="text" ngbDropdownToggle [(ngModel)]="timeRangePickerValue2" [ngModel]="timeRangePickerValue2 | formatTimeInput" (ngModelChange)="changeCurrentTimeToPreviousValue(timeRangePickerInput?.SecondTimpePicker)" [required]="isRequired" (click)="changeCurrentTimeToPreviousValue(timeRangePickerInput?.SecondTimpePicker)" />
        <div class="d-flex align-items-center rangepicker_values"><span [ngClass]="timeRangePickerValue1?'color-primary-grey font-semi-bold f-12':'color-primary-darkgrey font-Regular f-10'">{{timeRangePickerValue1?(timeRangePickerValue1 | formatTimeInput):"HH:MM TT"}}</span ><span class="px-1" [ngClass]="timeRangePickerValue1?'color-primary-grey font-semi-bold f-12':'color-primary-darkgrey font-Regular f-10'">-</span> <span [ngClass]="timeRangePickerValue2?'color-primary-grey font-semi-bold f-12':'color-primary-darkgrey font-Regular f-10'">{{timeRangePickerValue2?( timeRangePickerValue2 | formatTimeInput):"HH:MM TT"}}</span> </div>
    </div>
    
    <!-- TIME SVG-->
    <svg (click)="!inputDisabled?dropdown?.toggle():null;changeCurrentTimeToPreviousValue(timeRangePickerInput?.FirstTimpePicker) ; changeCurrentTimeToPreviousValue(timeRangePickerInput?.SecondTimpePicker)" [ngClass]="{'icon-disabled':inputDisabled}" class="icon-position" xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 20.004 20">
      <path id="Union_2" data-name="Union 2" d="M-10163,17870a10.014,10.014,0,0,1,10-10,10.014,10.014,0,0,1,10,10,10.013,10.013,0,0,1-10,10A10.013,10.013,0,0,1-10163,17870Zm1.553,0a8.462,8.462,0,0,0,8.449,8.455,8.462,8.462,0,0,0,8.449-8.455,8.457,8.457,0,0,0-8.449-8.449A8.46,8.46,0,0,0-10161.449,17870Zm11.083,3.008-3.1-2.326a.77.77,0,0,1-.31-.619v-4.648a.773.773,0,0,1,.776-.771.773.773,0,0,1,.776.771v4.264l2.786,2.09a.77.77,0,0,1,.157,1.082.769.769,0,0,1-.623.314A.789.789,0,0,1-10150.366,17873.006Z" transform="translate(10163.002 -17860)" fill="#b9b9b9"/>
    </svg>
    <!-- TIME SVG-->
    <div ngbDropdownMenu aria-labelledby="dropdownBasic"  [ngClass]="{ 'show': timePickerVisible }">
        <div class="d-flex gap-2">
            <ngb-timepicker [id]="'timePicker1'" [name]="'timePicker1'" [(ngModel)]="ngbTimePickerValue1" (ngModelChange)="updateTimeForFirstPicker($event)" [meridian]="true" class="time-picker-custom time_range_picker"></ngb-timepicker>
            <ngb-timepicker [id]="'timePicker2'" [name]="'timePicker2'" [(ngModel)]="ngbTimePickerValue2" (ngModelChange)="updateTimeForSecondPicker($event)" [meridian]="true" class="time-picker-custom time_range_picker"></ngb-timepicker>
        </div>
    </div>
</div>