<div class="container-fluid p-4 newDatePicker">
    <div class="row">
        <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"></ngb-datepicker>

        <ng-template #t let-date let-focused="focused">
            <!-- Custom day template content -->
            <span
                class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                [class.range-start]="isRangeStart(date)"
                [class.range-end]="isRangeEnd(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
            >
                {{ date.day }}
            </span>
        </ng-template>
        <div class="mt-3 d-flex justify-content-end">
            <app-button [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'" [buttonClass]="'button-secondary buttonBig width-100px me-5'" [buttonType]="'button'" (buttonOutputEvent)="activeModal.close(false)"  [id]="'cancel_button'" [name]="'cancel_button'"></app-button>
            <app-button [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.APPLY'" [buttonClass]="'button-primary buttonBig width-100px'" (buttonOutputEvent)="sendCustomDates()" [buttonType]="'submit'" [id]="'apply_date'" [name]="'apply_date'"></app-button>
          </div>
      </div>
</div>