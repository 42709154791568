<ng-container [ngSwitch]="modalTitle">
  <div *ngSwitchCase="enumPopUpTitles.ConfirmChangesTitle">
        <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
          <span class="modalTitle mx-auto">{{modalTitle | translate}}</span>    
        </div>
        <div class="modal-body px-4 py-3 mx-2">
        <div class="text-center mb-3">
          <span class="text-center ">{{'COMMON.COMMONMODALPOPUP.ARE_YOU_SURE_YOU_WANT_TO_SAVE_THESE_CHANGES?' | translate}}</span>
        </div>
        </div>
        <div class="modal-footer px-3 pt-0 m-2 pb-4 mt-0 border-0 row">
          <div class="m-0 d-flex justify-content-evenly">
            <app-button [buttonType]="'button'" (click)="activeModal.close(false)" [buttonClass]="'button-secondary buttonBig w-98px'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'"></app-button>
            <app-button [buttonType]="'submit'" (click)="activeModal.close(true)" [buttonClass]="'button-primary buttonBig w-98px'"  [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CONFIRM'"></app-button>
          </div>
        </div>
  </div>

  <div *ngSwitchCase="enumPopUpTitles.MarkAsActiveTitle">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-2">
      <div class="text-center">
        <span class=" text-center ">{{'COMMON.COMMONMODALPOPUP.ARE_YOU_SURE_YOU_WANT_TO_MARK_THIS_FIELD_VALUE_AS_ACTIVE?' | translate}}</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-0 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 d-flex justify-content-between">
        <app-button [buttonType]="'button'" (click)="activeModal.close(true)" [buttonClass]="'button-secondary buttonBig w-98px'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'"></app-button>
        <app-button [buttonType]="'submit'" (click)="activeModal.close(false)" [buttonClass]="'button-primary buttonBig w-98px'"  [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CONFIRM'"></app-button>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="enumPopUpTitles.MarkAsInactiveTitle">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-2">
      <div class="text-center">
        <span class=" text-center ">{{'COMMON.COMMONMODALPOPUP.ARE_YOU_SURE_YOU_WANT_TO_MARK_THIS_FIELD_VALUE_AS_INACTIVE?' | translate}}</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-0 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 d-flex justify-content-between">
        <app-button [buttonType]="'button'" (click)="activeModal.close(true)" [buttonClass]="'button-secondary buttonBig w-98px'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'"></app-button>
        <app-button [buttonType]="'submit'" (click)="activeModal.close(false)" [buttonClass]="'button-primary buttonBig w-98px'"  [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CONFIRM'"></app-button>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="enumPopUpTitles.UnsavedChangesTitle" class="unsaved-changes">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-2">
      <div class="text-center">
        <span class=" text-center ">{{'COMMON.COMMONMODALPOPUP.YOU_HAVE_MADE_CHANGES_THAT_HAVE_NOT_BEEN_SAVED._ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THESE_CHANGES?' | translate}}</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-2 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 d-flex justify-content-evenly flex-column-reverse align-items-center gap-4 gap-lg-0 gap-md-0 gap-xl-0 gap-sm-0 flex-lg-row flex-xl-row flex-md-row flex-sm-row">
        <app-button [buttonType]="'button'" (click)="activeModal.close(true)" [buttonClass]="'button-info buttonBig w-130px'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.DISCARADCHANGES'"></app-button>
        <app-button [buttonType]="'submit'" (click)="activeModal.close()" [buttonClass]="'button-danger buttonBig w-98px'"  [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.STAYONPAGE'"></app-button>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="enumPopUpTitles.ConfirmTitle">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-2"  *ngIf="firstText">
      <div class="text-center">
        <span class=" text-center ">{{'COMMON.COMMONMODALPOPUP.ARE_YOU_SURE_YOU_WANT_TO_SAVE_THESE_CHANGES?' | translate}}</span>
      </div>      
    </div>
    <div class="modal-body px-4 py-3 mx-5" *ngIf="secondText">
      <div class="text-center"  >
        <span class=" text-center ">{{'COMMON.COMMONMODALPOPUP.ARE_YOU_SURE_YOU_WANT_TO_DELETE_BLOCKOUT_DATE?' | translate}}</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-2 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 d-flex justify-content-evenly">
        <app-button [buttonType]="'button'" (click)="activeModal.close()" [buttonClass]="'button-info buttonBig w-98px'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'"></app-button>
        <app-button [buttonType]="'submit'" (click)="activeModal.close(true)" [buttonClass]="'button-primary buttonBig w-98px'"  [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CONFIRM'"></app-button>
      </div>
    </div>
  </div>

  
  <div *ngSwitchCase="enumPopUpTitles.ConfirmDelete">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto">{{modalTitle |translate }}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-2">
      <div class="text-center">
        <span class=" text-center ">{{'COMMON.COMMONMODALPOPUP.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_USER?' | translate}}</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-2 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 d-flex justify-content-evenly">
        <app-button [buttonType]="'button'" (click)="activeModal.close()" [buttonClass]="'button-info buttonBig w-98px'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'"></app-button>
        <app-button [buttonType]="'submit'" (click)="activeModal.close(true)" [buttonClass]="'button-primary buttonBig w-98px'"  [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CONFIRM'"></app-button>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="enumPopUpTitles.MakeGroupActive">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-2">
      <div class="text-center">
        <span class=" text-center ">{{'COMMON.COMMONMODALPOPUP.ARE_YOU_SURE_YOU_WANT_TO_MAKE_THIS_GROUP_ACTIVE?'|translate}}</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-0 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 d-flex justify-content-between">
        <app-button [buttonType]="'button'" (click)="activeModal.close(false)" [buttonClass]="'button-secondary buttonBig w-98px'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'"></app-button>
        <app-button [buttonType]="'submit'" (click)="activeModal.close(true)" [buttonClass]="'button-primary buttonBig w-98px'"  [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CONFIRM'"></app-button>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="enumPopUpTitles.MakeGroupInactive">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-2">
      <div class="text-center">
        <span class=" text-center ">{{'COMMON.COMMONMODALPOPUP.ARE_YOU_SURE_YOU_WANT_TO_MAKE_THIS_GROUP_INACTIVE?'|translate}}</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-0 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 d-flex justify-content-between">
        <app-button [buttonType]="'button'" (click)="activeModal.close(false)" [buttonClass]="'button-secondary buttonBig w-98px'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'"></app-button>
        <app-button [buttonType]="'submit'" (click)="activeModal.close(true)" [buttonClass]="'button-primary buttonBig w-98px'"  [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CONFIRM'"></app-button>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="enumPopUpTitles.DeleteGroup" class="unsaved-changes">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-1">
      <div class="text-center">
        <span class=" text-center ">{{'COMMON.COMMONMODALPOPUP.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_GROUP?'| translate}}</span>
      </div>
      <div class="text-center mt-2 px-3 fst-italic lh-sm">
        <span class="text-center">{{'COMMON.COMMONMODALPOPUP.THIS_ACTION_IS_PERMANENT_AND_CONNOT_BE_UNDONE'|translate}}</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-2 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 d-flex justify-content-evenly flex-column-reverse align-items-center gap-4 gap-lg-0 gap-md-0 gap-xl-0 gap-sm-0 flex-lg-row flex-xl-row flex-md-row flex-sm-row">
        <app-button [buttonType]="'button'" (click)="activeModal.close()" [buttonClass]="'button-info buttonBig px-3'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'"></app-button>
        <app-button [buttonType]="'submit'" (click)="confirm()" [buttonClass]="'button-danger buttonBig px-3'"  [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CONFIRM'"></app-button>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="enumPopUpTitles.RemoveUserFromGrop" class="unsaved-changes">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-3 py-3">
      <div class="text-center">
        <span class=" text-center ">Are you sure you want to remove this user from this group?</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-2 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 d-flex justify-content-evenly flex-column-reverse align-items-center gap-4 gap-lg-0 gap-md-0 gap-xl-0 gap-sm-0 flex-lg-row flex-xl-row flex-md-row flex-sm-row">
        <app-button [buttonType]="'button'" (click)="activeModal.close(true)" [buttonClass]="'button-info buttonBig px-3'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CANCEL'"></app-button>
        <app-button [buttonType]="'submit'" (click)="activeModal.close()" [buttonClass]="'button-danger buttonBig px-3'"  [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CONFIRM'"></app-button>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="enumPopUpTitles.GroupCreated">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="mx-auto font-semi-bold color-primary">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-3 py-3">
      <div class="text-center">
        <span class="text-center">{{ 'COMMON.COMMONMODALPOPUP.A_NEW_GROUP_HAS_BEEN_CREATED' | translate }}</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-2 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 text-center">
        <span class="font-semi-bold cursor-pointer color-primary" (click)="confirm()">{{'COMMON.BUTTON.BUTTONLABEL.CLOSE'|translate}}</span>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="enumPopUpTitles.GroupDeleted">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="mx-auto font-semi-bold color-red">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-3 py-3">
      <div class="text-center">
        <span class=" text-center ">A new group has been deleted.</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-2 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 text-center">
        <span class="font-semi-bold cursor-pointer color-red" (click)="activeModal.close()">Close</span>
      </div>
    </div>
  </div>
</ng-container>
