import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-summary',
  templateUrl: './page-summary.component.html',
  styleUrls: ['./page-summary.component.scss']
})
export class PageSummaryComponent {

  @Input() paginationModel;
  @Input() totalRecords : number;

  getStartRange(): number {
    if (this.paginationModel == null) {
        return 1;
    } else {
        return (this.paginationModel?.pageNumber - 1) * this.paginationModel?.pageSize + 1;
    }
  }
  
  getEndRange(): number {
    if (this.paginationModel == null) {
        return this.totalRecords;
    } else {
        const endRange = this.paginationModel?.pageNumber * this.paginationModel?.pageSize;
        return endRange > this.totalRecords ? this.totalRecords : endRange;
    }
  }
}