import { PermissionService } from './../../../shared/services/permission.service';
import { StorageService } from './../../../shared/services/storage.service';
import { FilterModel, RequestStatus, DateRange, GetrequestsModel, AppointmentAccompanyingClientStatus, AppointmentInternalStatus, AppointmentInternalStatusString, spanishRequestStatus, ViewType} from "./request-list.model";
import { Component, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { NgbDateParserFormatter, NgbModal, NgbModalOptions, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { CreateEditRequestComponent } from "./pop-ups/create-edit-request/create-edit-request.component";
import { RequestListService } from "./request-list.service";
import { RangeDatepickerComponent } from "src/app/shared/common-components/range-datepicker/range-datepicker.component";
import { AppointmentChangeScope, FormSettingTitle, GetLookupFormEnum, ModalTitle, Roles, SignalRGroups } from "src/app/shared/enum/common-enum";
import { Router } from "@angular/router";
import { Subscription, } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { FilterComponent } from "./pop-ups/filter/filter.component";
import { AssignInterpretersComponent } from "./pop-ups/assign-interpreters/assign-interpreters.component";
import { CommonService } from "src/app/shared/services/common.service";
import { RequestCategory, ScheduleOptions } from "./pop-ups/create-edit-request/create-edit-request.model";
import { AcceptRequestComponent } from './pop-ups/accept-request/accept-request.component';
import { DeclinedPopupComponent } from './pop-ups/declined-popup/declined-popup.component';
import { AssignModal } from './pop-ups/assign-interpreters/assign-interpreters.model';
import { FormSettingsService } from '../../AdminSettings/form-settings/form-settings.service';
import { LoadDropdownDataService } from 'src/app/shared/common-components/load-dropdown-data/load-dropdown-data.service';
import { DropdownSections } from 'src/app/shared/common-components/load-dropdown-data/load-dropdown-data.model';
import { Pagination } from 'src/app/shared/models/pagination-base-model.model';
import { gender_NoPreference } from 'src/app/shared/models/common.model';
import { DatePipe } from '@angular/common';
import { DeclineRequestComponent } from '../appointments-scheduling-logs/pop-ups/decline-request/decline-request.component';
import { RealtimeService } from 'src/app/shared/services/realtime.service';
import { SelectedLanguage } from '../../reports/reports.model';
import { CancelRequestConfirmationComponent } from './pop-ups/cancel-request-confirmation/cancel-request-confirmation.component';
import { LinguisticCloseRequestComponent } from './pop-ups/linguistic-close-request/linguistic-close-request.component';
import { RequestClosedModalComponent } from './pop-ups/request-closed-modal/request-closed-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { SELECTED_LANGUAGE, USER_ACCOUNT_DETAILS } from '../../../shared/enum/common-enum';
import { tooltip } from 'leaflet';

@Component({
    selector: "app-request-list",
    templateUrl: "./request-list.component.html",
    styleUrls: ["./request-list.component.scss"],
    providers:[DatePipe]
})
export class RequestListComponent extends CommonService {

    appointmentInternalStatusString = AppointmentInternalStatusString; // For string Comparison
    requestStatus = RequestStatus;
    spanishRequestStatus = spanishRequestStatus;
    appointmentAccompanyingClientStatus = AppointmentAccompanyingClientStatus; // for other usersenum
    appointmentInternalStatus = AppointmentInternalStatus;  // for admin & superadmin enum
    requestStatusList = [];
    filterModel = new FilterModel();
    dateRange = new DateRange();
    getrequestsModel = new GetrequestsModel();
    requestList: any = [];
    totalRecords: number = 0;
    modalTitle = ModalTitle;
    @ViewChild(RangeDatepickerComponent)
    rangeDatepickerComponent: RangeDatepickerComponent;
    requestCategory = RequestCategory;
    assignModal = new AssignModal();
    roles = Roles;
    scheduleOptions = ScheduleOptions;
    shownItems = 2;
    // NotAvailable = "NA";
    appointmentNumber: any;
    userDetails: any;
    saleBillSubscription2: Subscription;
    isOpenFilterSidebar: boolean = false;
    selectedlanguage =  new SelectedLanguage();
    languagesListData: any;
    getLookupFormEnum = GetLookupFormEnum;
    formSettingTitle = FormSettingTitle;
    linguisticMatchCall: string;
    interpretationRequest: string;
    subscription: Subscription;
    offsetUtc;
    genderDropdownList = [];
    private permissionAfterResponseSubscription : Subscription;
    @ViewChildren(NgbTooltip) tooltips: QueryList<NgbTooltip>;
    dropdownIds = [
        { Dsi: DropdownSections.RequestersOfOrganization },
        { Dsi: DropdownSections.Organizations },
        { Dsi: DropdownSections.Genders },
        { Dsi: DropdownSections.Services },
        { Dsi: DropdownSections.Languages },
        { Dsi: DropdownSections.AppointmentInterpreters },
        { Dsi: DropdownSections.statuses }
    ];
    selectedLanguage: any;
    createdDate: any;
    appointmentsDataUpdateSubscription : Subscription;
    signalRConnectedSubscription :Subscription;
    constructor(
        private modalService: NgbModal,
        private ngbDateParserFormatter: NgbDateParserFormatter,
        public requestListService: RequestListService,
        public router: Router,
        public toaster: ToastrService,
        public storageService: StorageService,
        public formSettingsService: FormSettingsService,
        public permissionService: PermissionService,
        public loadDropdownDataService: LoadDropdownDataService,
        public datePipe : DatePipe,
        public realtimeService : RealtimeService,
        public translate : TranslateService
    ) {
        super();
    }


    onScrollHideTooltip() { this?.onScroll(this?.tooltips); }

    ngOnInit() {
        this.permissionAfterResponseSubscription = this.permissionService.permissionAfterResponse.subscribe(res => {
            if (res) {
                this.permissionService.permissionViewModel = res;
            }
        });

        this.saleBillSubscription2 =
            this.requestListService.loadRequests.subscribe((res) => {
                if (res) {
                    this.getAppontmentList();
                }
            });
        this.initRequestList();
        this.getAppontmentList(true);
        this.selectedLanguage = this.storageService.retrieve(SELECTED_LANGUAGE)
        this.userDetails = this.storageService.retrieve(USER_ACCOUNT_DETAILS);
        this.offsetUtc = this.userDetails?.timezone?.match(/\(UTC([+-]\d{2}:\d{2})\)/);
        this.setLanguage();
        this.subscribeToRealTimeUpdates();
        this.signalRConnected();
    }


    signalRConnected(){
           this.signalRConnectedSubscription = this.realtimeService?.signalRConnected.subscribe((res)=>{
               if(res) this.realtimeService.invokeServerFunction(SignalRGroups.AppointmentPage , this.appointmentIds() , this.getrequestsModel?.pagination?.pageNumber == 1 ? true : false  )
           }) 
       }

    subscribeToRealTimeUpdates(): void {
        this.appointmentsDataUpdateSubscription = this.realtimeService?.liveDataUpdates$.subscribe(liveData => {
            if (liveData) {
                switch (liveData.appointmentChangeScope) {
                    case AppointmentChangeScope.AppointmentCreated:
                        if(this.getrequestsModel.pagination.pageNumber == 1 || (!this.getrequestsModel?.pagination)) {
                           if(this.getrequestsModel.pagination.pageNumber == 1  && this.requestList?.length > 24 ) this.requestList?.pop();
                            this.requestList?.unshift(liveData?.response);
                            this.totalRecords++;
                        }
                        break;

                    case AppointmentChangeScope.AppointmentUpdated:
                        let indexAppointmentUpdated = this.requestList.findIndex(item => item.id === liveData?.response?.id);
                        if (indexAppointmentUpdated !== -1){
                        const isExpandable = this.requestList[indexAppointmentUpdated]?.isExpandable;
                        this.requestList[indexAppointmentUpdated] = liveData?.response;
                        this.requestList[indexAppointmentUpdated].isExpandable = isExpandable;
                        }
                        break;

                    case AppointmentChangeScope.AppointmentStatusUpdated:
                        let indexAppointmentStatusUpdated = this.requestList.findIndex(item => item.id === liveData?.response?.id);
                        if (indexAppointmentStatusUpdated !== -1)
                            this.requestList[indexAppointmentStatusUpdated].appointmentStatus = liveData?.response?.appointmentStatus;
                        break;

                    case AppointmentChangeScope.AutoAssignedPendingRequestReAssignEnabled:
                        let indexRequestAssignmentEnableDisable = this.requestList?.findIndex(item => item?.id == liveData?.response?.id);
                        if (indexRequestAssignmentEnableDisable !== -1) this.requestList[indexRequestAssignmentEnableDisable].isReassigningDisabled = liveData?.response?.isReassigningDisabled ?? false;;
                        break;

                    case AppointmentChangeScope.AppointmentRemovedFromInterreter:
                        let index = this.requestList?.findIndex(item => item?.id == liveData?.response?.id);
                        if (index !== -1) {
                            this.requestList?.splice(index , 1);
                            this.totalRecords--;
                        }
                        break;

                    default:
                        console.log('Unknown AppointmentChangeScope');
                        break;
                }
                this.processRequestList();
            }
            
        });
    }

    setLanguage() {
        this.linguisticMatchCall = this?.translate?.instant('APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.LINGUISTICMATCHCALL')
        this.interpretationRequest = this?.translate?.instant('APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.INTERPRETATIONREQUEST')
      }

    fillDropdownData(dropDownData) {
        this.loadDropdownDataService.commonGetDropdownModel.requestersOfOrganization = dropDownData?.requestersOfOrganization ?? [];
        this.loadDropdownDataService.commonGetDropdownModel.organizations = dropDownData?.organizations ?? [];
        this.loadDropdownDataService.commonGetDropdownModel.genders = dropDownData?.genders?? [];
        this.loadDropdownDataService.commonGetDropdownModel.services = dropDownData?.services ?? [];
        this.loadDropdownDataService.commonGetDropdownModel.languages = dropDownData?.languages ?? [];
        this.loadDropdownDataService.commonGetDropdownModel.appointmentInterpreters = dropDownData?.appointmentInterpreters ?? [];
        this.loadDropdownDataService.commonGetDropdownModel.appointmentInterpreters = dropDownData?.appointmentInterpreters ?? [];
        this.loadDropdownDataService.commonGetDropdownModel.statuses = dropDownData?.statuses ?? [];
        if (this.loadDropdownDataService?.commonGetDropdownModel?.genders?.some(item => item?.id !== 0)) this.genderDropdownList = [...(this.loadDropdownDataService?.commonGetDropdownModel?.genders?? []) , gender_NoPreference];
    }

    openCreateEditRequestPopUp(Data, formType: string,event , isCloseRequest=false) {
        event?.stopPropagation();
        if( Data?.appointmentCategoryId== this.requestCategory.LinguisticMatchCall && (this?.userDetails?.organizations[0]?.role?.value == this?.roles.Interpreter  || ((this?.userDetails?.organizations[0]?.role?.value == this?.roles.SuperAdmin || this?.userDetails?.organizations[0]?.role?.value == this?.roles.Admin)) && isCloseRequest)  ){
            this.openCloseModal(Data);
            return;
        }

        const datacopy = { ...Data } 
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
            windowClass: "createEditRequestForm",
        };
        const modalRef = this.modalService.open(
            CreateEditRequestComponent,
            modalOption
        );
        modalRef.componentInstance.sendAppointmentNo.subscribe((data) => {
            this.appointmentNumber = data;
        });
        modalRef.componentInstance.ModalTitleFromReqList =
            this.modalTitle.AppointmentForm;
        if (formType == "Edit Req") {
            modalRef.componentInstance.ModalTitleFromReqList =
                this.modalTitle.EditAppointmentForm;
            modalRef.componentInstance.editAppointmentListData = datacopy;
            modalRef.componentInstance.isCloseRequest = isCloseRequest;
        }
        modalRef.result.then((res) => {
            if (res?.isSuccess && res?.isCompletedAppointment ) {
                // this.addInvoiceInQbo(datacopy?.id , true)
                // this.getAppontmentList();
                this?.requestCompleted();
            } else if (res?.isSuccess) {
                // this.getAppontmentList();
            }
        });
    }

    addInvoiceInQbo(appointmentId , fromCompleteRequest){
        this.requestListService.addInvoiceInQBO(appointmentId).subscribe({
            next:(res)=>{
                if(res?.isSucceeded){
                    this.toaster.success(res?.message);
                    if(!fromCompleteRequest) this.getAppontmentList();
                } else{
                    
                    this.toaster.error(res?.message ? res?.message : res?.errors[0]);
                }
               if(fromCompleteRequest) this.getAppontmentList();
            } ,error:(err)=>{

            }
        })
    }

    private formatEnumKey(key: string): string {
        return key.replace(/([a-z])([A-Z])/g, "$1 $2");
    }

    initRequestList(): void {
        this.getrequestsModel = new GetrequestsModel();
        this.getrequestsModel.pagination.pageNumber = 1;
        this.getrequestsModel.pagination.pageSize = 25;
    }

    toggle(data, event) {
        event?.stopPropagation();
        event?.preventDefault();
        data.isExpandable = !data?.isExpandable;
    }

    reset(event) {
        event?.stopPropagation();
        this.filterModel = new FilterModel();
        this.selectedlanguage = undefined;
        const requestsModel  = {...this.getrequestsModel};
        this.getrequestsModel = {...this.filterModel};
        this.getrequestsModel.appointmentInternalStatusId = requestsModel?.appointmentInternalStatusId;
        this.getrequestsModel.pagination = requestsModel?.pagination;
        this.getrequestsModel.searchText = requestsModel?.searchText;
        this.getrequestsModel.sort = requestsModel?.sort;
        this.rangeDatepickerComponent?.onClear();
        this.getAppontmentList()
    }

    applyFilter() {
        const appliedfilter = {...this.getrequestsModel , ...this.filterModel}
        this.getrequestsModel = appliedfilter;
        if(this.getrequestsModel?.pagination) this.getrequestsModel.pagination.pageNumber = 1;
        this.getAppontmentList()
    }

    getSelectedDates(event) {
        this.filterModel.dateRange.startDate = this.ngbDateParserFormatter.format(event.startDate);
        this.filterModel.dateRange.endDate = this.ngbDateParserFormatter.format(event.endDate);

        this.dateRange.dateOrDateRange.startDate =
            this.ngbDateParserFormatter.format(event.startDate);
        this.dateRange.dateOrDateRange.endDate =
            this.ngbDateParserFormatter.format(event.endDate);
    }

    filterUndefinedProperties(obj: any): any {
        const filteredObj: any = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && (obj[key] !== undefined)) {
                if ((obj[key] !== null)) {
                    filteredObj[key] = obj[key];
                }
            }
        }
        return filteredObj;
    }

    callInitialApiCallCount: number = 0;

    async getAppontmentList(isInit = false) {
        const getrequestsModel = JSON.parse(JSON.stringify(this.filterUndefinedProperties(this.getrequestsModel)));
        if(getrequestsModel.interpreterGenderRequestId == 0){
            getrequestsModel.isGenderNotPreferred = true;
            getrequestsModel.interpreterGenderRequestId = null;
        } else{
            getrequestsModel.isGenderNotPreferred = null;
            // getrequestsModel.interpreterGenderRequestId = null;
        }
        getrequestsModel.viewTypeId = ViewType.AppointmentView;
       
        if (this.callInitialApiCallCount == 0) {
            this.subscription = await (await this.requestListService
                .getAppointmentData(getrequestsModel))
                .subscribe((res) => {
                    this.requestList = res.response;
                    this.totalRecords = res.totalRecords;
                    if(isInit) this.requestList?.map((x) => (x.isExpandable = false));
                    this.processRequestList();
                });
        }
        this.callInitialApiCallCount = this.callInitialApiCallCount + 1;
    }

    appointmentIds(){
       const ids = this.requestList?.map((x)=> x?.id);
       return  ids
    }
  
    processRequestList() {
        this.callInitialApiCallCount = 0;
        this.createdDate = this.requestList?.map(item => item.date)
        this.requestList?.map((x) => (x.itemLength = 2));
        if(this.userDetails?.organizations[0]?.role?.value==this.roles.Interpreter){
            this.requestList?.map((item)=>item.checkingRequestIsDuringOrAfter = this.checkIsEnableOrDisable(item ,this.offsetUtc))
        }
      }
      
    onPageChanged(value) {
        this.getrequestsModel.pagination.pageNumber = value;
        // this.getrequestsModel.pagination.pageSize = 10;
        this.getAppontmentList();
    }

    selectPageSize(value){
        if(value == 'All'){
            this.getrequestsModel.pagination = null;
        } else{
            if(this.getrequestsModel.pagination == null){
                const pagination =  new Pagination();
                this.getrequestsModel.pagination = pagination;
                this.getrequestsModel.pagination.pageNumber = 1;
            }
            this.getrequestsModel.pagination.pageSize = value;
            const pagesToDisplay = this.getPageNumbers(this.totalRecords,this.getrequestsModel?.pagination?.pageSize?? this.totalRecords ,5)
            if(!(pagesToDisplay?.includes(this.getrequestsModel?.pagination.pageNumber ??1) )) {
              let lastValue = pagesToDisplay?.at(-1);
              this.getrequestsModel.pagination.pageNumber = lastValue??1;
            }
        }
        this.getAppontmentList();
    }

    navigateToLog(appointmentDetails: any, navigateFrom: any = 'requestListComponent', event) {
        event?.stopPropagation();
        if( appointmentDetails?.appointmentStatus == this?.appointmentInternalStatusString?.New || appointmentDetails?.appointmentStatus == this?.appointmentInternalStatusString?.EsNew ) this.requestListService.isNewStatus = true;
        this.router.navigateByUrl(`appointmentsAndScheduling/logs/${appointmentDetails.id}`);
    }

    openFilterForDevice() {
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
            windowClass: "filterbuttondevice",
        };
        const modalRef = this.modalService.open(FilterComponent, modalOption);
        modalRef.componentInstance.filterModel = { ...this.filterModel };
        modalRef.componentInstance.selectedlanguage = structuredClone(this.selectedlanguage);
        modalRef.result.then((res) => {
            if (res) {
                this.filterModel = res;
                this.selectedlanguage = structuredClone(modalRef?.componentInstance?.selectedlanguage);
                this.applyFilter()
            }
        });
    }

    //#region open Assign Interpreter PopUP

    openAssignInterpretersPopUp(data , event) {
        event?.stopPropagation();
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
            windowClass: "assignInterpretersForm",
        };
        const modalRef = this.modalService.open(AssignInterpretersComponent, modalOption);
        modalRef.componentInstance.getRequestData = data;
        modalRef.componentInstance.reqAssigned.subscribe((res => {
            if (res == true) {
                this.getAppontmentList();
            }
        }))
    }
    //#endregion

    //#region open Accept Request PopUP
    openAcceptRequestPopUp(Data ,event) {
        event?.stopPropagation();
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
            windowClass: "acceptpopupwidth",
        }
        const modalRef = this.modalService.open(AcceptRequestComponent, modalOption);
        modalRef.componentInstance.acceptedData = Data;
        modalRef.result.then((res) => {
            if (res == true) {
                this.getAppontmentList();
            }
        });
    }
    //#endregion

    //#region for cancel request modal
    openCancelModal(data){
    const modalOption: NgbModalOptions = {
        centered: true,
        backdrop: "static",
        keyboard: false,
        size: "sm",
        windowClass: "cancelrequestmodal"};
        const modalRef = this.modalService.open(CancelRequestConfirmationComponent,modalOption);
        modalRef.componentInstance.acceptedData = data;
        modalRef.result.then((res)=>{
        if (res == true) this?.getdeclined(data?.id);
        });
    }
    //#endregion

    // call declined api service
    callInitialDeclineApiCallCount: number = 0;
    getdeclined(id , event?) {
        if (event) event?.stopPropagation();
        if (this.callInitialDeclineApiCallCount == 0) {
            if (this.userDetails?.organizations[0].role.value == Roles.Interpreter) {
                this.requestListService.declineAppointmentByInterpreter({ appointmentId: id })
                    .subscribe((res) => {
                        if (res.isSucceeded) {
                            this.getAppontmentList();
                            this.openDeclinedPopup();
                            // this.toaster.success(res.message);
                            this.callInitialDeclineApiCallCount = 0;
                        }
                        else {
                            if (res.message != undefined) {
                                this.toaster.error(res.message);
                            }
                            if (res?.errors?.length > 0) {
                                this.toaster.error(res.errors[0]);
                            }
                            this.callInitialDeclineApiCallCount = 0;
                        }
                    });
            }
            else {
                this.requestListService.getDeclined(id).subscribe((res) => {
                    if (res.isSucceeded) {
                        this.getAppontmentList();
                        this.openDeclinedPopup();
                        this.callInitialDeclineApiCallCount = 0;
                    }
                    else {
                        if (res.message != undefined) {
                            this.toaster.error(res.message);
                        }
                        if (res?.errors?.length > 0) {
                            this.toaster.error(res.errors[0]);
                        }
                        this.callInitialDeclineApiCallCount = 0;
                    }
                });
            }

        }
        this.callInitialDeclineApiCallCount = this.callInitialDeclineApiCallCount + 1;

    }

    // open declined popup
    openDeclinedPopup() {
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
            windowClass: "confirmedpopup",
        }
        const modalRef = this.modalService.open(DeclinedPopupComponent, modalOption);
    }

    searchList(value) {
        this.getrequestsModel.searchText = value;
        this.getAppontmentList();
    }

    openFilterSidebar() {
        this.isOpenFilterSidebar = true;
    }

    addNewLanguages(event) {
        this.filterModel.languageId = event?.languageVariant != undefined
            ? event?.languageVariant?.id
            : event?.language?.languageVariant[0].id,
            this.selectedlanguage = event;
        // this.getAppontmentList();
    }


    ngOnDestroy() {
        this.appointmentsDataUpdateSubscription?.unsubscribe();
        this.subscription?.unsubscribe();
        this.permissionAfterResponseSubscription?.unsubscribe();
        this.signalRConnectedSubscription?.unsubscribe();
    }


    convertLocalToTargetTimeZone(targetTimezoneOffset ,hours?) {
        const date = new Date();
        const utcDateTime = date.toISOString();  // Convert local date-time to UTC
       return this.convertTimes(utcDateTime , targetTimezoneOffset , hours);
      }

      convertTimes(utcDateTime , targetTimezoneOffset ,hours) : number {
        if(utcDateTime || targetTimezoneOffset ){
            const convertedTime = this.datePipe.transform(utcDateTime, 'yyyy-MM-ddTHH:mm:ss', targetTimezoneOffset);  // Adjust UTC date-time to the target timezone offset
           
            const dt =  this.convertLocalToUTC(convertedTime);
            const dtTimeInMillisecond = this.dateTimeStringToTotalMilliseconds(dt , hours);
            return dtTimeInMillisecond;
        } else {
            return 0
        }
      }

      convertLocalToUTC(localDateTimeString: string): string | null {
        return this.datePipe.transform(localDateTimeString, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
      }

      dateTimeStringToTotalMilliseconds(dateTimeString: string , hours?): number {
        const dt = dateTimeString+'Z'
        const dateTime = new Date(dt);
        if(hours) dateTime.setHours(hours);
        return Date.parse(dt);
        // return dateTime.getTime();
    }

    convertLocalToUTCdateTime(localDateTimeString: string, localTimezone: string): string | null {
        const utcDateTimeString = this.datePipe.transform(localDateTimeString, 'yyyy-MM-ddTHH:mm:ss', 'UTC', localTimezone); // Transform the local date-time to UTC using DatePipe
        return utcDateTimeString;
      }

    checkAppointmentTime(data){
        if(this.userDetails?.organizations[0]?.role?.value==this.roles?.Interpreter){
            data.checkingRequestIsDuringOrAfter = this.checkIsEnableOrDisable(data,this.offsetUtc);
        }
    }

    checkAfterAppointment(data){
      const isAfterAppointment =  this.checkIsEnableOrDisable(data,this.offsetUtc, this.permissionService.permissionViewModel?.Appointments);
      return data?.appointmentCategoryId == this.requestCategory.LinguisticMatchCall ?  !isAfterAppointment?.isStartTimeExceed :  isAfterAppointment?.isAfterAppointment;
    }
      //#region declined request confirmation poup 

  declineRequestConfirmation(id , event) {
    event?.stopPropagation();
    const modalOption: NgbModalOptions = {
          centered: true,
          backdrop: "static",
          keyboard: false,
          size: "sm",
          windowClass: "unsaved-changes"};
          const modalRef = this.modalService.open(DeclineRequestComponent,modalOption);
          modalRef.result.then((res)=>{
        if (res) { 
          this.getdeclined(id)
        }else{
        }
      })
      }
  
    //#endregion declined request confirmation poup 

    //#region linguistic close request 
    openCloseModal(data){
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "sm",
            windowClass: "cancelrequestmodal"};
            const modalRef = this.modalService.open(LinguisticCloseRequestComponent ,modalOption);
            modalRef.componentInstance.acceptedData = data;
            modalRef.result.then((res)=>{
            if (res == true){
                const completeAppoinmentModel = {appointmentId : data?.id,interpreterNote : data?.interpreterNote}
                this?.requestListService?.completeAppointmentByInterpreter(completeAppoinmentModel).subscribe((res)=>{
                    if(res?.isSucceeded){
                        this?.requestCompleted();
                        this.getAppontmentList();
                        this?.toaster.success(res?.message);
                    }else{
                        this?.toaster.error(res?.message);
                    }
                },(error)=> {
                    this?.toaster.error(error);
                })
            }});
        }
    //#endregion linguistic close request 

    requestCompleted() {
            const modalOption: NgbModalOptions = {
                centered: true,
                backdrop: "static",
                keyboard: false,
                size: "sm",
                windowClass: "reqSubmitted",
            };
            this.modalService.open(RequestClosedModalComponent, modalOption);
        }
}