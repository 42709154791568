import { LanguageServiceService } from './../components/header/elements/languages/language-service.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {
  selectedLanguage: any;
  constructor(public languageServiceService:LanguageServiceService){
  }
  transform(utcTimestamp: string): string {
    this.selectedLanguage = this?.languageServiceService?.selectedLanguage?.getValue();
    const utcTime = new Date().getTime() + new Date().getTimezoneOffset() * 60000;

    const diffInSeconds = Math.floor((utcTime - new Date(utcTimestamp).getTime()) / 1000);

    if (diffInSeconds < 60) {
      if(this.selectedLanguage?.code == 'es'){
        return `${diffInSeconds} ${diffInSeconds !== 1 ? 'segundos atrás' : 'Hace segunda'}`;
      }
      return `${diffInSeconds} second${diffInSeconds !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      if(this.selectedLanguage?.code == 'es'){
        return `${minutes} ${minutes !== 1 ? 'minutos atrás' : 'hace un minuto'}`;
      }
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      if(this.selectedLanguage?.code == 'es'){
        return `${hours} ${hours !== 1 ? 'horas atras' : 'hace una hora'}`;
      }
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      if(this.selectedLanguage?.code == 'es'){
        return `${days} ${days !== 1 ? 'días atrás' : 'hace un dia'}`;
      }
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
  }
}

