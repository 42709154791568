import { Directive, ElementRef, HostListener, EventEmitter, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTimeFormatCheck]',
})
export class TimeFormatCheckDirective {

  constructor(private ngControl:NgControl) { }

  @HostListener('input', ['$event.target'])
  onInput(input: HTMLInputElement) {
    let value = input.value.trim();
    if(value.length > 5){
      value = value.substring(0,value.length-1);
      input.value = value;
      this.ngControl.control.setValue(input.value);
      return;
    }
    // Ensure that the value matches the expected format (HH:mm)
    const timeRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d)$/;
    if (!timeRegex.test(value)) {
      input.value = this.formatValue(value);
    }
  }

  private formatValue(value: string): string {
    // Format the value to match the expected time format
    value = value.replace(/\D/g, '').slice(0, 4);

    if(value != ''){
      // Parse hours and ensure they are between 0 and 23
      let hoursString = value.slice(0, 2);
      let hours = parseInt(value.slice(0, 2), 10);
      hours = Math.min(hours, 23); // Cap hours at 23
      hours = Math.max(hours, 0); // Ensure hours are not negative
      if (isNaN(hours)) {
          hours = 0;
      }
  
      // Ensure hours are formatted without leading zero
      if(hoursString != '00'){
        value = hours.toString() + value.slice(2);
      }

  
      // Parse minutes and ensure they are between 0 and 59
      let minutes = parseInt(value.slice(2), 10);
      minutes = Math.min(minutes, 59);
      minutes = Math.max(minutes, 0); // Ensure minutes are not negative
      if (isNaN(minutes)) {
          minutes = 0;
      }

      if(value.length == 2 && !value.includes(':')){
        value = value.slice(0, 2) + ':';
      }
      else if(value.length >= 3 && !value.includes(':')){
              // Ensure minutes are formatted with leading zero if less than 10
      value = value.slice(0, 2) + ':' + (minutes > 5  ? '0' + minutes : minutes);
      }
  
      // Ensure minutes are formatted with leading zero if less than 10
      // value = value.slice(0, 2) + ':' + (minutes < 10 ? '0' + minutes : minutes);
    }
    this.ngControl.control.setValue(value);
    return value;
  }

}
