import { LoadDropdownDataService } from './../../../../../shared/common-components/load-dropdown-data/load-dropdown-data.service';
import {
    NgbActiveModal,
    NgbDateParserFormatter,
    NgbModal,
    NgbModalOptions,
    NgbTimeStruct,
} from "@ng-bootstrap/ng-bootstrap";
import {
    AppointmentAcceptableLanguage,
    AppointmentPreferredLanguage,
    CommunicationType,
    CommunicationTypes,
    CompleteAppoinmentModel,
    CreateRequestModel,
    InternalNotes,
    InterpreterNotes,
    MatchCallLanguage,
    NoteType,
    NotesType,
    RequestCategory,
    ReqursterNotes,
    ScheduleOptions,
    ShowFormView,
    checkbox,
} from "./create-edit-request.model";
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { RequestSubmittedOrUnableToProceedComponent } from "../request-submitted-or-unable-to-proceed/request-submitted-or-unable-to-proceed.component";
import {
    CheckBoxType,
    FormSettingTitle,
    GetLookupFormEnum,
    ModalTitle,
    Roles,
} from "src/app/shared/enum/common-enum";
import { ResponseModel } from "src/app/shared/models/response-model.model";
import { FormSettingsService } from "src/app/components/AdminSettings/form-settings/form-settings.service";
import { ToastrService } from "ngx-toastr";
import { RequestListService } from "../../request-list.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { CommonService } from "src/app/shared/services/common.service";
import { parse, addHours, format } from 'date-fns';
import { DatePipe } from '@angular/common';
import { AppoinmentUpdatedPopupComponent } from '../appoinment-updated-popup/appoinment-updated-popup.component';
import { gender_NoPreference } from 'src/app/shared/models/common.model';
@Component({
    selector: "app-create-edit-request",
    templateUrl: "./create-edit-request.component.html",
    styleUrls: ["./create-edit-request.component.scss"],
    providers:[DatePipe]
})
export class CreateEditRequestComponent extends CommonService {
    public currentFormattedTime: any;
    @Input() editAppointmentListData: any;
    @Input() ModalTitleFromReqList: string;

    @Input() afterAndDuringAppointmentFlag:any;

    @Output() sendAppointmentNo = new EventEmitter();
    requestCategory = RequestCategory;
    scheduleOptions = ScheduleOptions;
    communicationType = CommunicationType;
    languagesListData: any = [];
    formSettingTitle = FormSettingTitle;
    getLookupFormEnum = GetLookupFormEnum;
    modalTitle = ModalTitle;
    showFormView = ShowFormView;
    startTime: any;
    endTime: any;
    editNotesData: any;
    userDetails: any;
    roles = Roles;
    noteType = NoteType;
    isError = false;
    isInputError1 = false;
    isInputError2 = false;
    currentData = new Date();

    preferredFromVariantList = [];
    preferredToVariantList = [];
    acceptableFromVariantList = [];
    acceptableToVariantList = [];
    languageList = [];
    notesArray: any;
    appointmentNumber: any;
    preferedfromValue: any;
    preferedToValue: any;
    acceptableFromValue: any;
    acceptableToValue: any;
    ScheduleOptionsValue: any;
    genderDropdownList = []

    checkbox1: boolean = false;
    checkbox2: boolean = false;
    checkbox3: boolean = false;
    checkbox = new checkbox();

    check_box_type = CheckBoxType;
    currentlyChecked: CheckBoxType;

    sharedSelectedOptionId: number | null = null;
    sharedSelectedOptionId2: number | null = null;
    isDisableWhileClick = false;

    createRequestModel = new CreateRequestModel();
    appointmentAcceptableLanguage = new AppointmentAcceptableLanguage();
    appointmentPreferredLanguage = new AppointmentPreferredLanguage();
    completeAppoinmentModel = new CompleteAppoinmentModel();
    matchCallLanguageModel = new MatchCallLanguage();
    notesType = new NotesType();
    visibleForm: number = 0;

    internalNotes = new InternalNotes();
    reqursterNotes = new ReqursterNotes();
    interpreterNotes = new InterpreterNotes(); 
    communicationTypes = CommunicationTypes ;

    @ViewChild("CreateRequestForm1") CreateRequestForm1!: NgForm;
    @ViewChild("CreateRequestForm2") CreateRequestForm2!: NgForm;
    @ViewChild("CreateRequestForm3") CreateRequestForm3!: NgForm;
    @ViewChild("CreateRequestForm4") CreateRequestForm4!: NgForm;

    idemPotencyKey :string
    communicationTypeName: string | null = null;
    isCheckReasonForCancellation = [];

    checkReasonForCancellation(id){
     return this.isCheckReasonForCancellation?.includes(id);
    }

    reasonList: string[] = ["Incorrect Language Variant",
        "Services Not Needed",
        "Used Spanish Interpreter Only"]
    public formattedCurrentTime: string;
    ngbTimePickerValue: NgbTimeStruct;
    ActualEndTime: any;
    reasonForCancellation: any;

    isForm1Valid: boolean = false; // Assuming Form1 was previously valid
    isForm2Valid: boolean = false; // Assuming Form2 is initially not valid
    isForm3Valid: boolean = false; // Assuming Form3 is initially not valid

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        public formSettingsService: FormSettingsService,
        public toaster: ToastrService,
        public requestListService: RequestListService,
        public storageservice: StorageService,
        private ngbDateParserFormatter: NgbDateParserFormatter,
        private cdr: ChangeDetectorRef,
        public loadDropdownDataService: LoadDropdownDataService,
        public datePipe : DatePipe
    ) {
        super();
    }


    ngOnInit() {
        this.userDetails = this.storageservice.retrieve("userAccountDetails");
        if (this.loadDropdownDataService?.commonGetDropdownModel?.genders?.some(item => item?.id !== 0)) this.genderDropdownList = [...(this.loadDropdownDataService.commonGetDropdownModel.genders?? []) , gender_NoPreference];
        if (this.ActualEndTime) {
            // Set ngbTimePickerValue based on ActualEndTime if provided
            const endTime = new Date(this.ActualEndTime);
            this.ngbTimePickerValue = {
              hour: endTime.getHours(),
              minute: endTime.getMinutes(),
              second: 0
            };
          } else {
            // Set to current time if ActualEndTime is not provided
            this.setCurrentTime();
          }
          this.formatCurrentTime();

        
        this.ModalTitleFromReqList
        this.loadLangageAndVariantsData();
        if (this.userDetails?.organizations[0]?.role?.value == this.roles?.Requester) {
            this.createRequestModel.userId = this.userDetails?.id;
        }
        this.assignEditData();

    }
    formatCurrentTime(): void {
      const now = new Date(); // Get current date and time
      const utcDate = now?.toISOString(); // Converts to ISO 8601 format in UTC
      const offsetMatch = this.userDetails?.timezone?.match(/\(UTC([+-]\d{2}:\d{2})\)/);
      const dt =  this.convertUTCToLocalFormat(utcDate , offsetMatch ? offsetMatch[1]:undefined);
      const [, year, month, date, hours, minutes, seconds] = dt?.localDate?.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/);
        this.currentFormattedTime = `${hours}:${minutes}`;
      }

    ngAfterViewInit() {
        this.sharedSelectedOptionId = null;
        this.sharedSelectedOptionId2 = null;
        this.idemPotencyKey = this.generateGUID()
    }

    convertAndAddHours(): string {
        if (this.createRequestModel?.timeDuration?.startTime) {
            const originalDate = parse(this.createRequestModel?.timeDuration?.startTime, 'HH:mm', new Date());
            const newDate = addHours(originalDate, 2);
            const result = format(newDate, 'HH:mm');
            return result;
        }
    }

    onHideForm1() {
        if (this.createRequestModel.appointmentCategoryId == undefined) return;
        this.isForm1Valid = this.CreateRequestForm1?.valid ?? false;
        // this.parseTime(this.createRequestModel?.timeDuration?.startTime);
        return this.isForm1Valid;
    }

    onHideForm2() {
        if (this.createRequestModel.appointmentCategoryId == undefined) return;
        this.isForm2Valid = this.CreateRequestForm2?.valid ?? false;
        return this.isForm2Valid;
    }

    onHideForm3(isValid) {
        if (this.createRequestModel.appointmentCategoryId == undefined) return;
        this.isForm3Valid = this.CreateRequestForm3?.valid ?? false;
        if (this.isForm3Valid && isValid) {
            this.submitAppointment();
        }
    }

    addLanguageVariant() {
        if (this.languageVariants.length > 4) return;
        const newLanguageVariant =
        {
            id: 0,
            variant: "",
            description: "",
            entryState: 1
        };
        this.languageVariants.push(newLanguageVariant);
    }

    shouldDisableAddVariant(): boolean {
        // const hasEmptyVariant = this.languageVariants.some(
        //     (item) => !item.variant.trim() || !item.description.trim()
        // );
        return false;
    }

    SendAppointmentNumber(appointmentNumber: number) {
        this.sendAppointmentNo.emit(appointmentNumber);
    }

    notes() {
        if (this.editAppointmentListData) {
            let internal: any = this.editAppointmentListData?.notes.filter(x => x.noteTypeId == this.noteType.InternalNote)
            this.internalNotes.id = internal[0]?.id

            let requester: any = this.editAppointmentListData?.notes.filter(x => x.noteTypeId == this.noteType.RequesterNote)
            this.reqursterNotes.id = requester[0]?.id
        }

        this.createRequestModel.notes = [
            {
                id: this.reqursterNotes?.id ?? 0,
                noteTypeId: 3,
                body: this.reqursterNotes?.reqursterDesc,
                entryState: this.reqursterNotes?.id == undefined ? this.reqursterNotes.entryState = 1 : this.reqursterNotes.entryState = 2
            },
            {
                id: this.internalNotes?.id ?? 0,
                noteTypeId: 1,
                body: this.internalNotes?.internalDesc,
                entryState: this.internalNotes?.id == undefined ? this.internalNotes.entryState = 1 : this.internalNotes.entryState = 2
            }
        ];
        console.log([this.createRequestModel.notes]);
    }

    submitAppointment() {
        if (this.createRequestModel.appointmentCategoryId === 1) {
            this.setupInterpretationLanguages();
        } else {
            this.setupMatchCallLanguages();
        }
        this.notes()
        this.convertTimeToFullFormatForDuration();
        this.insertOrUpdateAppointment();
    }

    setupInterpretationLanguages() {
        this.createRequestModel.appointmentLangTypeId = 1;
        this.createRequestModel.interpretationLanguages = [
            this.appointmentAcceptableLanguage,
            this.appointmentPreferredLanguage,
        ];
        this.appointmentAcceptableLanguage.id = this.modalTitle.EditAppointmentForm ? this.editAppointmentListData?.interpretationLanguages[0]?.id : null;
        this.appointmentPreferredLanguage.id = this.modalTitle.EditAppointmentForm ? this.editAppointmentListData?.interpretationLanguages[1]?.id : null;
    }

    setupMatchCallLanguages() {
        this.createRequestModel.appointmentLangTypeId = 2;
        this.createRequestModel.matchCallLanguages.langVariants =
            this.languageVariants;
    }

    convertTimeToFullFormatForDuration() {
        this.createRequestModel.timeDuration.startTime =
            this.convertTimeToFullFormat(
                this.createRequestModel?.timeDuration?.startTime
            );
        if (this.requestCategory.LinguisticMatchCall == this.createRequestModel.appointmentCategoryId) {
            this.createRequestModel.timeDuration.endTime = null;

        } else {
            this.createRequestModel.timeDuration.endTime =
                this.convertTimeToFullFormat(
                    this.createRequestModel?.timeDuration?.endTime
                );
        }

    }

    extendEndTimeBy30Minutes(startTime) {
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        const endMinutes = startMinutes + 119;
        const endHours = startHours + Math.floor(endMinutes / 60);
        const updatedMinutes = endMinutes % 60;
        const updatedEndTime = `${String(endHours).padStart(2, '0')}:${String(updatedMinutes).padStart(2, '0')}:00`;
        return updatedEndTime;
    }

    insertOrUpdateAppointment() {
        if (this.ModalTitleFromReqList === this.modalTitle.AppointmentForm) {
            this.insertAppointment();
        } else {
            this.updateAppointment();
        }
    }

    insertAppointment() {
        if(this.isDisableWhileClick) return;
        this.isDisableWhileClick = true;
        const createRequestModel = JSON.parse(JSON.stringify(this.createRequestModel));
        createRequestModel?.interpreterGenderRequestId == 0 ?  createRequestModel.interpreterGenderRequestId = null : null;

        if( createRequestModel?.interpretationLanguages?.length && (!createRequestModel?.interpretationLanguages[0]?.fromLangVariantId || !createRequestModel?.interpretationLanguages[0]?.toLangVariantId)){
            createRequestModel?.interpretationLanguages?.shift();
        }
        this.requestListService
            .InsertAppointment(createRequestModel , this.idemPotencyKey)
            .subscribe({
                next:(res: ResponseModel) => {
                    this.isDisableWhileClick = false;
                    if (res.isSucceeded) {
                        this.appointmentNumber = res.response.appointmentNumber;
                        this.SendAppointmentNumber(this.appointmentNumber);
                        this.toaster.success(res.message);
                        this.activeModal.close(true);
                        this.requestSubmitted();
                    } else {
                        if (res.message != undefined) this.toaster?.error(res?.message);
                        if (res?.errors?.length > 0) this.toaster?.error(res?.errors[0]);
                        this.idemPotencyKey = this.generateGUID();
                    }
                } ,error:(err)=>{
                    console.log(err);
                    this.isDisableWhileClick = false;
                    this.idemPotencyKey = this.generateGUID();
                }
            });
    }

    updateAppointment() {
        if(this.isDisableWhileClick) return
        this.isDisableWhileClick = true;
        const createUpdateRequestModel = JSON.parse(JSON.stringify(this.createRequestModel));
        createUpdateRequestModel?.interpreterGenderRequestId == 0 ?  createUpdateRequestModel.interpreterGenderRequestId = null : null;
        if( createUpdateRequestModel?.interpretationLanguages?.length && (!createUpdateRequestModel?.interpretationLanguages[0]?.fromLangVariantId || !createUpdateRequestModel?.interpretationLanguages[0]?.toLangVariantId)){
            createUpdateRequestModel?.interpretationLanguages?.shift();
        }
        this.requestListService
            .UpdateAppointment(createUpdateRequestModel)
            .subscribe({
                next:(res: ResponseModel) => {
                    this.isDisableWhileClick = false;
                    if (res.isSucceeded) {
                        this.toaster.success(res.message);
                        this.activeModal.close(true);
                        this.appointmentNumber = this.editAppointmentListData?.appointmentNumber;
                        this.requestSubmitted();
                    } else {
                        if (res.message != undefined) {
                            this.toaster.error(res.message);
                        }
                        if (res?.errors?.length > 0) {
                            this.toaster.error(res.errors[0]);
                        }
                    }
                },error:(err)=>{
                    console.log(err);
                    this.isDisableWhileClick = false;
                }
            });
    }

    
    updateTime(time: string) {
        this.completeAppoinmentModel.actualEndTime = this.formatTime(time);
      }
    
      formatTime(time: string): string {
        if (time.length === 5) { // If time is in the format HH:mm
          return `${time}:00`; // Add seconds
        }
        return time; // Return as-is if already in the correct format
      }
 
      updateCompleteAppointmentByInterpreter() {
        const createUpdateRequestID = this.createRequestModel?.id;
        this.completeAppoinmentModel.appointmentId = createUpdateRequestID;
        this.requestListService
          .completeAppointmentByInterpreter(this.completeAppoinmentModel)
          .subscribe(
            (res: ResponseModel) => {
              if (res.isSucceeded) {
                this.toaster.success(res.message);
                this.activeModal.close();
                this.interpreterUpdateAppoinment();
              } else {
                if (res.message) {
                  this.toaster.error(res.message);
                }
                if (res.errors?.length) {
                  this.toaster.error(res.errors[0]);
                }
              }
            },
            (error) => {
              this.toaster.error('An error occurred');
              console.error(error);
            }
          );
      }

    getSelectedDates(event: any) {
        this.createRequestModel.timeDuration.date =
          this.ngbDateParserFormatter?.format(event?.startDate);
          this.selectSheduledOption();
      }
    

      setCurrentTime() {
        const currentTime = new Date();
        this.ngbTimePickerValue = {
            hour: currentTime.getHours(),
            minute: currentTime.getMinutes(),
            second: 0,
        };
        return this.ngbTimePickerValue;
    }


    requestSubmitted() {
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "sm",
            windowClass: "reqSubmitted",
        };
        const modalRef = this.modalService.open(
            RequestSubmittedOrUnableToProceedComponent, modalOption
        );
        modalRef.componentInstance.appointmentNumber = this.appointmentNumber;

        if (this.appointmentNumber) {
            modalRef.componentInstance.modalTitle = this.modalTitle.RequestSubmitted;
        } else {
            modalRef.componentInstance.modalTitle = this.modalTitle.AppointmentUpdated;
        }
    }

    interpreterUpdateAppoinment(){
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "sm",
            windowClass: "reqSubmitted",
        };
        const modalRef = this.modalService.open(
            AppoinmentUpdatedPopupComponent, modalOption
        );
            modalRef.componentInstance.modalTitle = this.modalTitle.AppointmentUpdated;
        }

    

    clearProgress() {
        this.isForm1Valid = false;
        this.isForm2Valid = false;
        this.isForm3Valid = false;
    }

    isPreventAssignSheduledIdForEdit = false;

    assignEditData() {
        this.visibleForm = ShowFormView.FormStart;
        if (!this.editAppointmentListData) return;
        this.isPreventAssignSheduledIdForEdit = true;
        let timeDurationObj: any = {
            date: this.editAppointmentListData.date,
            startTime: this.editAppointmentListData.startTime,
            endTime: this.editAppointmentListData.endTime,
        };
        let locationObj: any = {
            nameOfLocation : this.editAppointmentListData?.nameOfLocation,
            addressLine1: this.editAppointmentListData?.addressLine1,
            addressLine2: this.editAppointmentListData?.addressLine2,
            landmark: this.editAppointmentListData?.landmark,
            city: this.editAppointmentListData?.city,
            zipCode: this.editAppointmentListData?.zipCode,
            stateId: this.editAppointmentListData?.state.id,
        };

        if (
            this.editAppointmentListData.appointmentCategoryId ==
            this.requestCategory.InterpretationRequest
        ) {
            this.appointmentPreferredLanguage.entryState = 2;
            this.appointmentAcceptableLanguage.entryState = 2;

            if(this.editAppointmentListData?.interpretationLanguages?.length == 1){
                let preferedLanguages: any = this.editAppointmentListData?.interpretationLanguages[0];
                
                this.preferedfromValue = preferedLanguages?.fromLang.id;
                this.appointmentPreferredLanguage.fromLangVariantId = preferedLanguages?.fromLangVariant?.id;
    
                this.preferedToValue = preferedLanguages?.toLang.id;
                this.appointmentPreferredLanguage.toLangVariantId = preferedLanguages?.toLangVariant?.id;
            } else {
            let preferedfrom: any = this.editAppointmentListData?.interpretationLanguages[1];
            this.preferedfromValue = preferedfrom?.fromLang.id;
            this.appointmentPreferredLanguage.fromLangVariantId = preferedfrom?.fromLangVariant.id;

            let preferedTo: any =
                this.editAppointmentListData?.interpretationLanguages[1];
            this.preferedToValue = preferedTo?.toLang.id;
            this.appointmentPreferredLanguage.toLangVariantId =
                preferedfrom?.toLangVariant.id;

            let acceptableFrom: any =
                this.editAppointmentListData?.interpretationLanguages[0];
            this.acceptableFromValue = acceptableFrom?.fromLang.id;
            this.appointmentAcceptableLanguage.fromLangVariantId =
                acceptableFrom?.fromLangVariant.id;

            let acceptableTo: any =
                this.editAppointmentListData?.interpretationLanguages[0];
            this.acceptableToValue = acceptableTo?.toLang.id;
            this.appointmentAcceptableLanguage.toLangVariantId =
                acceptableTo?.toLangVariant.id;
            }
    
        }

        this.createRequestModel.id = this.editAppointmentListData.id;
        this.createRequestModel.appointmentCategoryId =
            this.editAppointmentListData?.appointmentCategoryId;
        // this.createRequestModel.callInNumber =
        //     this.editAppointmentListData?.callInNumber;
        this.createRequestModel.phoneNumber =
            this.editAppointmentListData?.phoneNumber;
        this.createRequestModel.phoneCodeId =
            this.editAppointmentListData?.phoneCodeId;
        this.createRequestModel.scheduleOptionsId =
            this.editAppointmentListData?.scheduleOptionsId;
        this.createRequestModel.link = this.editAppointmentListData?.link;
        this.createRequestModel.accessCode =
            this.editAppointmentListData?.accessCode;
        // this.createRequestModel.nameOfLocation =
        //     this.editAppointmentListData?.nameOfLocation;
        this.createRequestModel.serviceId =
            this.editAppointmentListData?.service?.id;
        this.createRequestModel.interpreterGenderRequestId =
            this.editAppointmentListData?.interpreterGenderRequest?.id??0;
        this.createRequestModel.userId = this.editAppointmentListData?.user?.id;
        this.createRequestModel.communicationTypeId =
            this.editAppointmentListData?.communicationType?.id;

        let internalDesc: any = this.editAppointmentListData?.notes.filter(x => x?.noteTypeId == this.noteType.InternalNote)
        this.internalNotes.internalDesc = internalDesc[0]?.body;

        let requesterDesc: any = this.editAppointmentListData?.notes.filter(x => x?.noteTypeId == this.noteType.RequesterNote)
        this.reqursterNotes.reqursterDesc = requesterDesc[0]?.body;

        this.createRequestModel.timeDuration = timeDurationObj;
        this.createRequestModel.location = locationObj;

        let communicationTypeIdAsString =
            this.createRequestModel?.communicationTypeId?.toString();
        communicationTypeIdAsString =
            this.editAppointmentListData?.communicationType;

        let matchCallData: any = this.editAppointmentListData.matchCallLanguage;
        this.transformData(matchCallData);
    }

    transformData(arrayData: any[]) {
        if (arrayData.length > 0) {
            const transformedObject: any = {
                language: "",
                langVariants: [],
            };
            transformedObject.language = arrayData[0].language;
            arrayData.forEach((item) => {
                transformedObject.langVariants.push({
                    id: this.modalTitle.EditAppointmentForm ? item.id : null,
                    variant: item.variant,
                    entryState: this.modalTitle.EditAppointmentForm ? 2 : 1,
                    description: item.description,
                });
            });
            this.createRequestModel.matchCallLanguages = transformedObject;
            this.languageVariants = transformedObject.langVariants;
        }

    }

    private formatEnumKey(key: string): string {
        return key.replace(/([a-z])([A-Z])/g, "$1 $2");
    }

    languageVariants: any[] = [{ variant: "", description: "" }];

    reset() {
        if (this.visibleForm == this.showFormView.FormStart) {
            this.CreateRequestForm1.reset();
        } else if (this.visibleForm == this.showFormView.FormMiddle) {
            this.CreateRequestForm2.reset();
            this.createRequestModel.serviceId = null;
            this.preferedfromValue = '';
            this.preferedToValue = '';
            this.acceptableFromValue = '';
            this.acceptableToValue = '';
            this.appointmentAcceptableLanguage = new AppointmentAcceptableLanguage();
            this.appointmentPreferredLanguage = new AppointmentPreferredLanguage();
            this.languageVariants = [];
        } else if (this.visibleForm == this.showFormView.FormLast) {
            this.CreateRequestForm3.reset();
            this.createRequestModel.interpreterGenderRequestId = null;
        }
    }

    clearForm() {
        if (this.createRequestModel.appointmentCategoryId != undefined) {
            this.createRequestModel = new CreateRequestModel();
        } else {
            this.activeModal.close();
        }
    }

    showPreviousForm(formStatus) {
        // Update form progress variables to revert one step back
        if (this.isForm3Valid) {
            this.isForm3Valid = false;
        } else if (this.isForm2Valid) {
            this.isForm2Valid = false;
        } else if (this.isForm1Valid) {
            this.isForm1Valid = false;
            this.isPreventAssignSheduledIdForEdit=true;
        }
        // Update form category and visibleForm based on formStatus
        if ((formStatus == this.showFormView.FormMiddle) && (this.createRequestModel.appointmentCategoryId == this.requestCategory.InterpretationRequest)) {
            this.createRequestModel.appointmentCategoryId = this.requestCategory.InterpretationRequest;
            this.visibleForm = this.showFormView.FormStart;
        } else if ((formStatus == this.showFormView.FormMiddle) && (this.createRequestModel.appointmentCategoryId == this.requestCategory.LinguisticMatchCall)) {
            this.createRequestModel.appointmentCategoryId = this.requestCategory.LinguisticMatchCall;
            this.visibleForm = this.showFormView.FormStart;
        } else if ((formStatus == this.showFormView.FormLast) && (this.createRequestModel.appointmentCategoryId == this.requestCategory.LinguisticMatchCall)) {
            this.createRequestModel.appointmentCategoryId = this.requestCategory.LinguisticMatchCall;
            this.visibleForm = this.showFormView.FormMiddle;
        } else if ((formStatus == this.showFormView.FormLast) && (this.createRequestModel.appointmentCategoryId == this.requestCategory.InterpretationRequest)) {
            this.createRequestModel.appointmentCategoryId = this.requestCategory.InterpretationRequest;
            this.visibleForm = this.showFormView.FormMiddle;
        }
    }

    loadLangageAndVariantsData() {
        this.formSettingsService
            .getAllFormSettings(
                this.getLookupFormEnum.LanguageAndVariant,
                undefined
            )
            .subscribe((res: ResponseModel) => {
                if (res.isSucceeded === true) {
                    const filteredLookupTable = res.response.lookupTables.find(
                        item => item.value === this.formSettingTitle.languagesAndVariants
                    );

                    if (filteredLookupTable) {
                        const filteredLookupValues = filteredLookupTable.lookupValues.filter(
                            lookupValue => lookupValue.isActive === true
                        ).map(filteredLookupValue => {
                            const filteredLanguageVariant = filteredLookupValue.languageVariant.filter(
                                languageVariant => languageVariant.isActive === true
                            );
                            return {
                                ...filteredLookupValue,
                                languageVariant: filteredLanguageVariant
                            };
                        });

                        this.languagesListData = filteredLookupValues;
                    }

                    if (this.languagesListData) {
                        for (const languageItem of this.languagesListData) {
                            const languageData = {
                                id: languageItem.id,
                                value: languageItem.value,
                            };
                            this.languageList.push(languageData);
                        }
                    }
                }
                else {
                    if (res.message != undefined) {
                        this.toaster.error(res.message);
                    }
                    if (res?.errors?.length > 0) {
                        this.toaster.error(res.errors[0]);
                    }
                }
            });
    }

    getVariant(selectedOptionId, dropdownName) {
        const selectedLanguage = this.languagesListData.find(
            (language) => language.id === selectedOptionId
        );
        switch (dropdownName) {
            case "PreferredFrom":
                  case "AcceptableFrom":
                this.preferredFromVariantList =
                    selectedLanguage?.languageVariant?.map((item) => ({
                        id: item.id,
                        value: item.value,
                        description: item.description,
                    }));
                    this.acceptableFromVariantList = this.preferredFromVariantList;
                    if(this.acceptableFromValue == this.acceptableToValue || !selectedOptionId) this.acceptableToValue = null; 
                
                break;
            case "PreferredTo":
                this.preferredToVariantList =
                selectedLanguage?.languageVariant?.map((item) => ({
                    id: item.id,
                    value: item.value,
                    description: item.description,
                }));
                break;
            case "AcceptableTo":
                this.acceptableToVariantList =
                    selectedLanguage?.languageVariant?.map((item) => ({
                        id: item.id,
                        value: item.value,
                        description: item.description,
                    }));
                break;
        }

        if (dropdownName == "PreferredFrom" || dropdownName == "PreferredTo") {
            this.sharedSelectedOptionId = selectedOptionId;
        } else if (
            dropdownName == "AcceptableFrom" ||
            dropdownName == "AcceptableTo"
        ) {
            this.sharedSelectedOptionId2 = selectedOptionId;
        }
        this.cdr.detectChanges();
    }
    
     isClientNoShowDisabled  : boolean = false;
     isEndedEarlyDisabled  : boolean = false;
     isEndedLateDisabled : boolean = false;
    selectCheckBox(isCheck ,value) {
        // this.reasonForCancellation = value;
        if (value === CheckBoxType.ENDEDLATE) {
            this.isClientNoShowDisabled = true;
            this.isEndedEarlyDisabled = true;
          } else if (value === CheckBoxType.ENDEDEARLY) {
            this.isClientNoShowDisabled = true;
            this.isEndedLateDisabled = true;
          } else if(value === CheckBoxType.CLIENTNOSHOW){
            this.isEndedEarlyDisabled = true;
            this.isEndedLateDisabled = true;
          }

        if(isCheck){
            this.isCheckReasonForCancellation = [];
            this.isCheckReasonForCancellation?.push(value);
            this.currentlyChecked = value;
            // this.completeAppoinmentModel.appointmentCompletionStateTypeId =value
            if (value === CheckBoxType.ENDEDLATE) {
                this.isClientNoShowDisabled = true;
                this.isEndedEarlyDisabled = true;
              } else if (value === CheckBoxType.ENDEDEARLY) {
                this.isClientNoShowDisabled = true;
                this.isEndedLateDisabled = true;
              } else if(value === CheckBoxType.CLIENTNOSHOW){
                this.isEndedEarlyDisabled = true;
                this.isEndedLateDisabled = true;
              }
        } else {
            this.isCheckReasonForCancellation = [];
            this.isClientNoShowDisabled = false;
            this.isEndedEarlyDisabled = false;
            this.isEndedLateDisabled = false;
        }
        // this.completeAppoinmentModel.appointmentCompletionStateTypeId = this.isCheckReasonForCancellation?.length ? this.isCheckReasonForCancellation?.length[0] :null
    }

    checkCharacterCount() {
        this.isError = this.editNotesData?.length > 750;
    }

    //#region  local to utc date time
    convertLocalToTargetTimeZone(targetTimezoneOffset ,hours?) {
        const date = new Date();
        // date.setHours(24);
        const utcDateTime = date.toISOString();  // Convert local date-time to UTC
       return this.convertTimes(utcDateTime , targetTimezoneOffset , hours);
      }

      convertTimes(utcDateTime , targetTimezoneOffset ,hours) : number {
        if(utcDateTime || targetTimezoneOffset ){
            const convertedTime = this.datePipe.transform(utcDateTime, 'yyyy-MM-ddTHH:mm:ss', targetTimezoneOffset);  // Adjust UTC date-time to the target timezone offset
           
            const dt =  this.convertLocalToUTC(convertedTime);
            const dtTimeInMillisecond = this.dateTimeStringToTotalMilliseconds(dt , hours);
            return dtTimeInMillisecond;
        } else {
            return 0
        }
      }

      convertLocalToUTC(localDateTimeString: string): string | null {
        return this.datePipe.transform(localDateTimeString, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
      }

      dateTimeStringToTotalMilliseconds(dateTimeString: string , hours?): number {
        const dt = dateTimeString+'Z'
        const dateTime = new Date(dt);
        if(hours) dateTime.setHours(hours);
        return Date.parse(dt);
        // return dateTime.getTime();
    }

    selectSheduledOption(){
        if(this.createRequestModel?.timeDuration?.startTime && this.createRequestModel?.timeDuration?.date){
            const dateTime = `${this.createRequestModel?.timeDuration?.date}T${this.createRequestModel?.timeDuration?.startTime}`
            const selectedDateStartTime = !this.editAppointmentListData ?  `${dateTime}:00` : dateTime;
            const dt =  this.convertLocalToUTC(selectedDateStartTime);
            const  selectedDateStartTimeMillisecond = this.dateTimeStringToTotalMilliseconds(dt);
            const userDetails = this.storageservice.retrieve("userAccountDetails");
            const offsetMatch = userDetails?.timezone?.match(/\(UTC([+-]\d{2}:\d{2})\)/);
            const dateTimeMillisecond =  offsetMatch ? this.convertLocalToTargetTimeZone(offsetMatch[1]) :  this.convertLocalToTargetTimeZone('UTC');
            // const millisecondsIn24Hours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
            const millisecondsIn4Hours = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
             const added24HoursDateTimeMillisecond = dateTimeMillisecond + millisecondsIn4Hours;
             if(selectedDateStartTimeMillisecond < dateTimeMillisecond ){
                if(!this.isPreventAssignSheduledIdForEdit) this.createRequestModel.scheduleOptionsId = undefined;
             } else if (selectedDateStartTimeMillisecond < added24HoursDateTimeMillisecond){
                this.createRequestModel.scheduleOptionsId = 1
             } else {
                this.createRequestModel.scheduleOptionsId = 2
             }
            // this.createRequestModel.scheduleOptionsId = selectedDateStartTimeMillisecond < added24HoursDateTimeMillisecond ? 1 : 2 ;
        } else {
            this.createRequestModel.scheduleOptionsId = undefined;
        }
        this.isPreventAssignSheduledIdForEdit = false;
    }

}