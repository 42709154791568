import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeMMSSFormat'
})
export class TimeMMSSFormatPipe implements PipeTransform {

  transform(value: number): string {
    // If the value is greater than 300, reset it to avoid exceeding the time limit
    const minutes: number = Math.floor(value / 60);
    const seconds: number = value % 60;
    return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

}
