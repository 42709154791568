import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure:false

})
export class FilterPipe implements PipeTransform {
  transform(items: any[], property: string, filterValue: any): any[] {
    if (!items || !property || filterValue === undefined) {
      return items;
    }

    if (!Array.isArray(items)) {
      console.error('Invalid input for filter pipe. Expected an array.');
      return items;
    }

    if (typeof property !== 'string') {
      console.error('Invalid input for filter pipe. Property name must be a string.');
      return items;
    }

    return items.filter((item) => item[property] === filterValue);
  }
}
