import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    const timeParts = value.split('T')[1]?.split(':');
    if (!timeParts || timeParts.length < 2) {
      return '';
    }

    const formattedTime = timeParts[0] + ':' + timeParts[1];
    return formattedTime;
  }

  // transform(value: string): string {
  //   const date = new Date();
  //   const [hours, minutes] = value.split(':');
  //   date.setHours(Number(hours));
  //   date.setMinutes(Number(minutes));
  //   return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  // }

  //// old code for pipe without error handling
  // transform(value: string): string {
  //   const time = value.split('T')[1].split(':')[0] + ':' + value.split(':')[1];
  //   return time;
  // } 

}
