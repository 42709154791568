import { Pipe, PipeTransform } from '@angular/core';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
  name: 'formatTimeInput'
})
export class FormatTimeInputPipe implements PipeTransform {

  transform(timeString: string): string {
    if (!timeString) return '';

    const timeParts = timeString.split(' ');
    const time = timeParts[0];
    const period = timeParts[1];

    const timeSplit = time.split(':');
    const hour = parseInt(timeSplit[0]);
    const minute = parseInt(timeSplit[1]);

    const hour24 = (period === 'PM' && hour !== 12) ? hour + 12 : (period === 'AM' && hour === 12) ? 0 : hour;

    const ngbTime: NgbTimeStruct = { hour: hour24, minute: minute, second: 0 };

    return this.formatTime(ngbTime);
  }

  private formatTime(time: NgbTimeStruct): string {
    const hour = (time.hour % 12 || 12).toString().padStart(2, '0'); 
    const minute = time.minute < 10 ? '0' + time.minute : time.minute;
    const period = time.hour < 12 ? 'AM' : 'PM';

    return `${hour}:${minute} ${period}`;
  }

}
