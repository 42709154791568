<ng-template #content let-offcanvas>
    <div class="offcanvas-header mb-3 mb-md-2 d-flex justify-content-betweeen align-items-start">
      <h4 class="offcanvas-title">{{'HEADER.NOTIFICATIONS'|translate}}</h4>
      <svg (click)="offcanvas.dismiss('Cross click')" class="d-md-none d-block" xmlns="http://www.w3.org/2000/svg" width="14" height="27" viewBox="0 0 14 27">
        <text id="Screen_Title" data-name="Screen Title" transform="translate(0 22)" fill="#e0532f" font-size="20" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="0">X</tspan></text>
      </svg>
    </div>
    <ul class="nav nav-tabs border-tab nav-primary mb-0 pt-1 px-2 px-md-4" id="info-tab" role="tablist">
        <li class="nav-item px-1">
          <a href="javascript:void(0)" class="nav-link text-nowrap pt-0 px-1 f-12"  [ngClass]="{'active show': openTab == 1}"
          (click)="openTab = 1;clickOnNotificationTab.emit(null)"
            id="all">{{'HEADER.NOTIFICATIONS.ALL'|translate}}</a>
        </li>
        <li class="nav-item px-1">
          <a href="javascript:void(0)" class="nav-link text-nowrap pt-0 px-1 f-12" [ngClass]="{'active show': openTab == 2}"
          (click)="openTab = 2;clickOnNotificationTab.emit(false)"  
          id="unread">{{'HEADER.NOTIFICATIONS.UNREAD'|translate}}</a>
        </li>
        <li class="nav-item px-2">
          <a href="javascript:void(0)" class="nav-link text-nowrap pt-0 px-1 f-12" [ngClass]="{'active show': openTab == 3}"
          (click)="openTab = 3;clickOnNotificationTab.emit(true)"  
          id="read">{{'HEADER.NOTIFICATIONS.READ'|translate}}</a>
        </li>
        <li class="w-100 text-end three-dots pe-3">
            <span class="cursor-pointer" *ngIf="openTab == 3 && filteredNotificationsData?.length > 0" (click)="deleteNotification?.emit(null)">{{'HEADER.NOTIFICATIONS.CLEARALL' | translate}}</span>
            <span class="cursor-pointer" *ngIf="openTab !== 3 && checkAsmarkStatus()" (click)="markAsRead?.emit(null)">{{'HEADER.NOTIFICATIONS.MARKALLASREAD'|translate}}</span>
           <!-- {{openTab == 3 ?('HEADER.NOTIFICATIONS.CLEARALL' | translate ):('HEADER.NOTIFICATIONS.MARKALLASREAD'|translate)}} -->
        </li>
    </ul>
    <div class="offcanvas-body stableScroll px-1 pe-3 px-md-3 px-sm-3 position-relative d-flex flex-column">
        <div class="tab-content flex-fill" id="info-tabContent">
            <div class="tab-pane fade h-100 text-danger" [ngClass]="{'active show': openTab == 1}" id="all" role="tabpanel"
              aria-labelledby="all">
                <div class="notification-dropdown h-100 d-flex flex-column">
                    <ul class="d-flex flex-column gap-3 notifications-auto-scroller h-0 flex-fill overflow-auto" (scroll)="onScroll?.emit('.notifications-auto-scroller')">
                        <li class="" *ngFor="let item of notificationsData;let i = index" [ngClass]="{'read':item.isRead==true}">
                            <div>
                                <p class="d-flex position-relative"><span class="col-7">{{'HEADER.NOTIFICATION'|translate}}</span>
                                    <span *ngIf="item.isRead == false" class="col-5 three-dots text-end cursor-pointer" (click)="markAsRead?.emit(item)">{{'HEADER.NOTIFICATIONS.MARKASREAD'|translate}}</span> 
                                    <svg *ngIf="item.isRead == true" (click)="deleteNotification?.emit(item)" class="rounded-circle ms-4 cursor-pointer position-absolute end-0 bottom-0 y-2" style="background-color: #e0532f;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <g id="delete" transform="translate(102.085 102.03)">
                                        <circle id="Ellipse_11" data-name="Ellipse 11" cx="10.001" cy="10.001" r="10.001" transform="translate(-102.085 -82.03) rotate(-90)" fill="#e0532f"/>
                                        <path id="Path_34968" data-name="Path 34968" d="M155.357,122.129H153.97v-.739a.391.391,0,0,0-.391-.391h-2.788a.391.391,0,0,0-.391.391v.739h-1.387a1.05,1.05,0,0,0-1.049,1.049v.658a.391.391,0,0,0,.391.391h.168v5.833a1.489,1.489,0,0,0,1.488,1.488h4.349a1.489,1.489,0,0,0,1.488-1.488v-5.833h.168a.391.391,0,0,0,.391-.391v-.658a1.05,1.05,0,0,0-1.049-1.049Zm-4.175-.348h2.006v.348h-2.006Zm-2.436,1.4a.271.271,0,0,1,.268-.268h6.343a.271.271,0,0,1,.268.268v.268h-6.879Zm6.32,6.882a.707.707,0,0,1-.706.706h-4.349a.707.707,0,0,1-.706-.706v-5.833h5.762Zm-3.271-.389v-4.349a.391.391,0,0,1,.781,0v4.349a.391.391,0,1,1-.781,0Zm-1.636,0v-4.349a.391.391,0,0,1,.781,0v4.349a.391.391,0,0,1-.781,0Zm3.271,0v-4.349a.391.391,0,0,1,.781,0v4.349a.391.391,0,1,1-.781,0Z" transform="translate(-244.269 -218.304)" fill="#fff" fill-rule="evenodd"/>
                                        </g>
                                    </svg>
                                </p>
                                <p class="d-flex mb-0"><span class="col-7">{{item.body}}</span> <span class="col-5 text-end align-self-end">{{item.timeDuration}}</span></p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="tab-pane fade text-danger h-100" [ngClass]="{'active show': openTab == 2}" id="unread" role="tabpanel" aria-labelledby="unread">
                <div class="notification-dropdown h-100 d-flex flex-column">
                    <ul class="d-flex flex-column gap-3 notifications-auto-scroller-unread h-0 flex-fill overflow-auto" (scroll)="onScroll?.emit('.notifications-auto-scroller-unread')">
                        <li class="" *ngFor="let item of notificationsData | filter: 'isRead': false">
                            <div>    
                                <p class="d-flex"><span class="col-7">{{'HEADER.NOTIFICATION'|translate}}</span><span class="col-5 three-dots cursor-pointer text-end cursor-pointer" (click)="markAsRead?.emit(item)">{{'HEADER.NOTIFICATIONS.MARKASREAD'|translate}}</span></p>
                                <p class="d-flex mb-0"><span class="col-7">{{item.body}}</span> <span class="col-5 text-end align-self-end">{{item.timeDuration}}</span></p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="tab-pane fade h-100 text-danger" [ngClass]="{'active show': openTab == 3}" id="read" role="tabpanel" aria-labelledby="read">
                <div class="notification-dropdown h-100 d-flex flex-column">
                    <ul class="d-flex flex-column gap-3 notifications-auto-scroller-read h-0 flex-fill overflow-auto" (scroll)="onScroll?.emit('.notifications-auto-scroller-read')">
                        <li class="read"  *ngFor="let item of notificationsData | filter: 'isRead': true">
                            <div>  
                                <p class="d-flex position-relative"><span class="col-7">{{'HEADER.NOTIFICATION'|translate}}</span>
                                    <svg (click)="deleteNotification?.emit(item)" class="rounded-circle ms-4 cursor-pointer position-absolute end-0 bottom-0 top-2" style="background-color: #e0532f;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <g id="delete" transform="translate(102.085 102.03)">
                                        <circle id="Ellipse_11" data-name="Ellipse 11" cx="10.001" cy="10.001" r="10.001" transform="translate(-102.085 -82.03) rotate(-90)" fill="#e0532f"/>
                                        <path id="Path_34968" data-name="Path 34968" d="M155.357,122.129H153.97v-.739a.391.391,0,0,0-.391-.391h-2.788a.391.391,0,0,0-.391.391v.739h-1.387a1.05,1.05,0,0,0-1.049,1.049v.658a.391.391,0,0,0,.391.391h.168v5.833a1.489,1.489,0,0,0,1.488,1.488h4.349a1.489,1.489,0,0,0,1.488-1.488v-5.833h.168a.391.391,0,0,0,.391-.391v-.658a1.05,1.05,0,0,0-1.049-1.049Zm-4.175-.348h2.006v.348h-2.006Zm-2.436,1.4a.271.271,0,0,1,.268-.268h6.343a.271.271,0,0,1,.268.268v.268h-6.879Zm6.32,6.882a.707.707,0,0,1-.706.706h-4.349a.707.707,0,0,1-.706-.706v-5.833h5.762Zm-3.271-.389v-4.349a.391.391,0,0,1,.781,0v4.349a.391.391,0,1,1-.781,0Zm-1.636,0v-4.349a.391.391,0,0,1,.781,0v4.349a.391.391,0,0,1-.781,0Zm3.271,0v-4.349a.391.391,0,0,1,.781,0v4.349a.391.391,0,1,1-.781,0Z" transform="translate(-244.269 -218.304)" fill="#fff" fill-rule="evenodd"/>
                                        </g>
                                    </svg>
                                </p>
                                <p class="d-flex mb-0"><span class="col-7">{{item.body}}</span> <span class="col-5 text-end align-self-end">{{item.timeDuration}}</span></p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ng-container *ngIf="notificationLoading">
            <div class="d-flex justify-content-center align-items-center">
                <p class="text-dark mt-1 font-Medium f-12 mb-0" >{{'COMMON.LOADING.LABEL' | translate}}</p>
            </div>
        </ng-container>
        <div class="position-absolute top-50 start-50 translate-middle color-primary-grey" *ngIf="notificationsData?.length ==0">
            {{'HEADER.NOTIFICATION.NO_NOTIFICATIONS' | translate}}
        </div>
    </div>
  </ng-template>