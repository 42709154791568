import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTitle, Roles } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import { USER_ACCOUNT_DETAILS } from '../../../../../shared/enum/common-enum';

@Component({
  selector: 'app-request-submitted-or-unable-to-proceed',
  templateUrl: './request-submitted-or-unable-to-proceed.component.html',
  styleUrls: ['./request-submitted-or-unable-to-proceed.component.scss']
})
export class RequestSubmittedOrUnableToProceedComponent {
  comfirmChange: boolean;
  @Input() modalTitle;
  @Input() appointmentNumber;
  modalTitleEnum = ModalTitle;
  roles = Roles;
  userDetails: any;

  constructor(public activeModal: NgbActiveModal,
              public storageService:StorageService){}

ngOnInit(){
  this.userDetails = this.storageService.retrieve(USER_ACCOUNT_DETAILS);
}
  
}