import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appMinTimeValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MinTimeValidatorDirective,
      multi: true
    }
  ]
})
export class MinTimeValidatorDirective implements Validator {
  @Input('minTime') minTime: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && control.value < this.minTime) {
      return { minTime: true };
    }

    return null;
  }
}
