import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import * as feather from "feather-icons";
import { LayoutService } from "../../../services/layout.service";
import { NavService } from "../../../services/nav.service";
import { fadeInAnimation } from "../../../data/router-animation/router-animation";
import { StorageService } from "src/app/shared/services/storage.service";
import { Roles } from "src/app/shared/enum/common-enum";
import { Subject, filter, takeUntil } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
  animations: [fadeInAnimation],
})
export class ContentComponent implements OnInit, AfterViewInit,OnDestroy {
  isIncompleteProfile
  loginUserDetails: any;
  roles = Roles;
  private ngUnsubscribe = new Subject();

  constructor(private router: Router,private route: ActivatedRoute, public navServices: NavService, public layout: LayoutService,private cdRef: ChangeDetectorRef,public storageService:StorageService ,public ngbModal : NgbModal) {
    this.route.queryParams.subscribe((params) => {
      this.layout.config.settings.layout = params.layout ? params.layout : this.layout.config.settings.layout;
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd),takeUntil(this.ngUnsubscribe))
      .subscribe((event: NavigationEnd) => {
        if(this.ngbModal?.hasOpenModals()){
          this.ngbModal?.dismissAll('close All')
        }
      });
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges(); 
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : "";
  }

  get layoutClass() {
    return "compact-wrapper";
  }

  navigate(){
    this.router.navigate([`user-settings/userdetails/loginuser`]);

  }

  ngOnInit() {
    this.loginUserDetails =  this.storageService.retrieve("userAccountDetails");
    this.storageService.isProfileComplete =  this.storageService.retrieve(this.loginUserDetails?.id);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe?.complete();
  }
}