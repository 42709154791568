<!-- page-wrapper Start-->
<div [ngClass]="layout.config.settings.layout_type">
  <div class="page-wrapper" id="canvas-bookmark" [ngClass]="layoutClass">
    <div class="warning-header" *ngIf="loginUserDetails?.organizations[0]?.role?.value == this.roles.Interpreter && !storageService.isProfileComplete">
      <div class="d-flex justify-content-center align-items-center h-100">
        <h6 class="mb-0 title me-4">INCOMPLETE PROFILE:</h6>
        <span class="middle">You are not eligible to receive assignments until you have added your services and availability to your profile.</span>&nbsp;
        <a class="text-decoration-underline cursor-pointer end" (click)="navigate()">Go to Profile</a>
      </div>
    </div>
      <app-header [isIncompleteProfile]="loginUserDetails?.organizations[0]?.role?.value == this.roles.Interpreter && !storageService.isProfileComplete"></app-header>
      <!-- Page Body Start-->
      <div class="page-body-wrapper px-2 px-md-0">
        <div class="sidebar-wrapper" [ngClass]="{'showIncompleteProfileDiv':loginUserDetails?.organizations[0]?.role?.value == this.roles.Interpreter && !storageService.isProfileComplete,'hideIncompleteProfileDiv':!(loginUserDetails?.organizations[0]?.role?.value == this.roles.Interpreter && !storageService.isProfileComplete)}" [attr.sidebar-layout]="layout.config.settings.sidebar_type" [attr.icon]="layout.config.settings.icon" [class.close_icon]="navServices.collapseSidebar">
          <app-sidebar></app-sidebar>
        </div>
        <div class="page-body pb-3" [ngClass]="{'showIncompleteProfileDiv':loginUserDetails?.organizations[0]?.role?.value == this.roles.Interpreter && !storageService.isProfileComplete,'hideIncompleteProfileDiv':!(loginUserDetails?.organizations[0]?.role?.value == this.roles.Interpreter && !storageService.isProfileComplete)}">
          <main class="h-100" [@fadeInAnimation]="getRouterOutletState(o)">
            <router-outlet class="h-100" #o="outlet"></router-outlet>
          </main>
        </div>
        <!-- footer start-->
        <!-- <footer class="footer">
          <app-footer></app-footer>
        </footer> -->
        <!-- footer End-->
      </div>
      <!-- Page Body End-->
  </div>
</div>
<div class="bg-overlay1"></div>
<!-- page-wrapper End-->
