import { PaginationBaseModel } from "src/app/shared/models/pagination-base-model.model";

export class RequestList {
}

export enum RequestStatus{
    New = 1,
    Pending = 2,
    Accepted = 3,
    AssigneePending = 4,
    Assigned = 5,
    Completed = 6,
    Cancelled = 7,
    Declined = 8,
    NoShow = 9
}
export enum spanishRequestStatus{
    Nuevo = 1,
    Pendiente =2,
    Aceptado = 3,
    AsignadaPendiente = 4,
    Asignado = 5,
    Completado = 6,
    Cancelado = 7,
    Rechazado = 8,
    NoMostrar = 9
}

export enum AppointmentAccompanyingClientStatus
{
    Pending = 1,
    Accepted = 2,
    Completed = 3,
    Cancelled = 4,
    Declined = 5,
    NoShow = 6
}

export enum AppointmentInternalStatus
{
    New = 1,
    Pending = 2,
    Accepted = 3,
    AssigneePending = 4,
    Assigned = 5,
    Completed = 6,
    Cancelled = 7,
    Declined = 8,
    NoShow = 9
}

export enum AppointmentInternalStatusString
{
    New = "New",
    Pending = "Pending",
    UnderReview = "Under Review",
    Accepted = "Accepted",
    AssigneePending = "Assignee Pending",
    Assigned = "Assigned",
    Approved = "Approved",
    Completed = "Completed",
    Cancelled = "Cancelled",
    Declined = "Declined",
    NoShow = "No Show",
    SystemDeclined = "System Declined",
    EsNew = "Nueva",
    EsPending = "Pendiente",
    EsUnderReview = "Bajo revisión",
    EsAccepted = "Aceptadas",
    EsAssigneePending = "Asignada pendiente",
    EsAssigned = "Asignada",
    EsApproved = "Aprobada",
    EsCompleted = "Completada",
    EsCancelled = "Cancelada",
    EsDeclined = "Rechazada",
    EsNoShow = "No Mostrar",
    EsSystemDeclined = "Sistema Rechazado",

}

export class CommonFilterList{
    id : number;
    name : string;
  }

export class FilterModel{
    appointmentCategoryId?: number;
    appointmentInternalStatusId?: number;
    organizationId?: number;
    interpreterId?: number; // add new field
    interpreterGenderRequestId?: number;
    languageId?: number;
    locationId?: number;
    calendarTypeId?: number;
    date?: string;
    dateRange = new DateModel()
    startTimeRange= new TimeModel();
    endTimeRange = new TimeModel();
    isGenderNotPreferred : boolean;
}

export class DateRange {
    dateOrDateRange = new DateModel();
    userId: string
}

export class DateModel {
    startDate: string;
    endDate  : string;
}

export class TimeModel {
    startTime: string;
    endTime  : string;
}

export class GetrequestsModel extends PaginationBaseModel{
    id?: number;
    appointmentCategoryId?: number;
    appointmentInternalStatusId?: number;
    organizationId?: number;
    interpreterId?: number; // add new field
    interpreterGenderRequestId?: number;
    languageId?: number;
    locationId?: number;
    calendarTypeId?: number;
    date?: string;
    dateRange = new DateModel();
    startTimeRange:TimeModel;
    endTimeRange :TimeModel;
    isGenderNotPreferred : boolean;
    onlyOnDemandSystemDeclinedRequests? : boolean;
}