<div *ngIf="modalTitleEnum.RequestSubmitted == modalTitle">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto font-semi-bold" [ngClass]="modalTitleEnum.RequestSubmitted==modalTitle?'color-primary':'color-red'">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-2">
    <div class="text-center" *ngIf="modalTitleEnum.RequestSubmitted==modalTitle">
        <span class="color-primary-grey font-semi-bold text-center f-11">{{'REQUESTLIST.REQUESTSUBMITTED.POPUP.THANKYOU_TEXT' | translate}}</span>
        <div class="mt-2">
            <span class="font-semi-bold color-primary">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.APPOINTMENTNUMBER' | translate}}: {{appointmentNumber}}</span><br>
            <span class="font-Regular color-primary-grey f-10">{{'REQUESTLIST.REQUESTSUBMITTED.POPUP.SAVE_ALERT_TEXT' | translate}}</span>
        </div>
    </div>
    <div class="text-center mb-3" *ngIf="modalTitleEnum.UnableToProceed==modalTitle">
      <span class="color-primary-grey font-semi-bold text-center f-11">{{'REQUESTLIST.REQUESTSUBMITTED.POPUP.CONTACT_DETAIL_ALERT_TEXT' | translate}}</span>
    </div>
    </div>
    <div class="modal-footer px-3 pt-2 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 text-center">
        <span class="font-semi-bold cursor-pointer" [ngClass]="modalTitleEnum.RequestSubmitted==modalTitle?'color-primary':'color-red'" (click)="activeModal.close()">{{"COMMON.BUTTON.BUTTONLABEL.CLOSE" | translate}}</span>
      </div>
    </div>
</div>

  <!-- appointment updated  -->
  <div *ngIf="modalTitleEnum.AppointmentUpdated == modalTitle">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto font-semi-bold color-primary">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-2">
      <div class="text-center mb-3">
        <span *ngIf="userDetails.organizations[0].role.value==roles.Admin || userDetails.organizations[0].role.value==roles.TeamMember || userDetails.organizations[0].role.value==roles.SuperAdmin" class="color-primary-grey font-semi-bold text-center f-11">{{"REQUESTLIST.REQUESTSUBMITTED.POPUP.REQUESTSAVED_ALERT_TEXT" | translate}}</span>
        <span *ngIf="userDetails.organizations[0].role.value==roles.Requester" class="color-primary-grey font-semi-bold text-center f-11">{{'REQUESTLIST.REQUESTSUBMITTED.POPUP.UPDATEREQUESTSENT_ALERT_TEXT' | translate}}
          {{'REQUESTLIST.REQUESTSUBMITTED.POPUP.NOTIFICATION_ARRIVAL_ALERT_TEXT' | translate}}</span>
        <span *ngIf="userDetails.organizations[0].role.value==roles.Interpreter">{{'REQUESTLIST.REQUESTSUBMITTED.POPUP.SAVECHANGES_ALERT_TEXT' | translate}}</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-2 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 text-center">
        <span class="font-semi-bold cursor-pointer color-primary" (click)="activeModal.close()">{{"COMMON.BUTTON.BUTTONLABEL.CLOSE" | translate}}</span>
      </div>
    </div>
  </div>
