import { TranslationWidth } from '@angular/common';
import { AfterViewInit, Component, Injectable, OnInit, ViewChild, inject } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbDatepickerI18n, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { LanguageServiceService } from '../../components/header/elements/languages/language-service.service';
import { StorageService } from '../../services/storage.service';
import { CommonService } from '../../services/common.service';

const I18N_VALUES = {
	'en-US': {
	  weekdays: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
	  months: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	  ],
	},
	'es-ES': {
		weekdays: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
		months: [
		  'Enero',
		  'Febrero',
		  'Marzo',
		  'Abril',
		  'Mayo',
		  'Junio',
		  'Julio',
		  'Agosto',
		  'Septiembre',
		  'Octubre',
		  'Noviembre',
		  'Diciembre',
		],
	  },
	// other languages you would support
  };
  @Injectable()
  export class I18n {
	language = 'en-US';
  }
  @Injectable()
  export class CustomDatepickerI18n extends NgbDatepickerI18n {
	selectedLanguage: any;
	constructor(private _i18n: I18n,public languageServiceService:LanguageServiceService) {
	  super();
	  this.selectedLanguage = this.languageServiceService.selectedLanguage.getValue();
	}
	getWeekdayShortName(weekday: number): string {
	  if(this.selectedLanguage?.code == 'es'){
		this._i18n.language = 'es-ES';
	  }
	  return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
	}
	getMonthShortName(month: number): string {
	  if(this.selectedLanguage?.code == 'es'){
	  	this._i18n.language = 'es-ES';
	  }
	  return I18N_VALUES[this._i18n.language].months[month - 1];
	}
	getMonthFullName(month: number): string {
	  return this.getMonthShortName(month);
	}
	getDayAriaLabel(date: NgbDateStruct): string {
	  return `${date.day}-${date.month}-${date.year}`;
	}
	getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
		if(this.selectedLanguage?.code == 'es'){
			this._i18n.language = 'es-ES';
		}
		return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
	}
  }

@Component({
  selector: 'app-new-range-date-picker',
  templateUrl: './new-range-date-picker.component.html',
  styleUrls: ['./new-range-date-picker.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class NewRangeDatePickerComponent extends CommonService implements AfterViewInit {
	calendar = inject(NgbCalendar);
	hoveredDate: NgbDate | null = null;
	fromDate: NgbDate | null;
	toDate: NgbDate | null;
	@ViewChild("dp") dp: NgbInputDatepicker;
	isOnlySingleDateSelect = false;
	userDetails:any = {};
	previousDateDisable : boolean = false;
	constructor(public activeModal: NgbActiveModal,private storageService:StorageService){
	super();

	}

	setMinSelectableDate() {
		if(this?.previousDateDisable && this?.userDetails){
			const { year, month, date }: any = this.getCurrentDateTimeBaseOnTimezone(this?.userDetails);
			return { year: Number(year), month: Number(month), day: Number(date) };
		}else{
		 return	{year:2022 ,month:1 ,day:1}
		}
		
	
	}

	ngAfterViewInit(): void {
		if(this.fromDate){
			this.dp?.navigateTo(this.fromDate)
		  }else{
			const currentDate = new Date();
			this.dp?.navigateTo({
			  year: currentDate?.getFullYear(),
			  month: currentDate?.getMonth() + 1, // Months are zero-based, so add 1
			  day: currentDate?.getDate()
			})
		  }

		//   const selectYearElement = document.querySelector('[aria-label="Select year"]') as HTMLSelectElement;
		//   selectYearElement.size = 10;
	}

	onDateSelection(date: NgbDate) {
		if(this.isOnlySingleDateSelect){
			this.fromDate = date;
			this.toDate = date;
			return
		};

		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this?.toDate && (date?.equals(this.fromDate)|| date?.after(this.fromDate))) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

	sendCustomDates(){
		if(this.fromDate != undefined && this.toDate != undefined){
			this.activeModal.close({fromDate:this.fromDate,toDate:this.toDate})
		}
		else{
			this.activeModal.close(false);
		}
		
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	isRangeStart(date: NgbDate): boolean {
		// return this.fromDate && !this.toDate && date.equals(this.fromDate);
		return date.equals(this.fromDate);
	}

	isRangeEnd(date: NgbDate): boolean {
		// return this.toDate && date.equals(this.toDate);
		return date.equals(this.toDate);
	}
}