export class ResponseModel {
    isSucceeded: boolean;
    message: string;
    response: any;
    errors: string[];
    status: number;
    totalRecords: number = 0;
    navigation?
}

export enum NotificationStatus {
    read = 1,
    unread = 2,
}

