export interface UILanguage {
    id: number;
    name: string;
    locale: string;
    languageCode: string;
    regionCode: string;
    flagCode: string;
  }

  // Define the uiLanguages array within the class
  export class UILanguage {
    static uiLanguages = [
      { id: 1, name: 'English', locale: 'en-US', languageCode: 'en', regionCode: 'US', flagCode: 'us' },
      { id: 2, name: 'Español', locale: 'es-419', languageCode: 'es', regionCode: 'ES', flagCode: 'mx' }
    ];
  
    static getLanguages() {
      return this.uiLanguages;
    }
  }