<div class="commonInputContainer position-relative {{dynamic_class}}">
  <label *ngIf="dropDownLabel && dropDownLabel != 'Section'" class="form-label f-12 text-nowrap">{{dropDownLabel |
    translate}}<span *ngIf="isRequired" class="required-color">*  
    </span>  
    <span id="scheduledOption_tooltipId" *ngIf="isDiscription" class="tooltip_Schedull_Option ps-3">
        <svg  tooltipClass="scheduledOption_tooltip"
         [ngbTooltip]="('EDITAPPOINTMENT.SCHEDULE.OPTION.HOVERMESSAGE' | translate)" placement="bottom-start" class="cursor-pointer" id="info" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
          <g id="Group_1582" data-name="Group 1582">
            <g id="Group_1581" data-name="Group 1581">
              <path id="Path_1018" data-name="Path 1018" d="M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0ZM6,11.163A5.163,5.163,0,1,1,11.163,6,5.169,5.169,0,0,1,6,11.163Z" fill="#b9b9b9"/>
            </g>
          </g>
          <g id="Group_1584" data-name="Group 1584" transform="translate(5.426 5.126)">
            <g id="Group_1583" data-name="Group 1583">
              <path id="Path_1019" data-name="Path 1019" d="M230.634,213.4c-.336,0-.575.142-.575.351v2.844c0,.179.239.358.575.358s.582-.179.582-.358v-2.844C231.216,213.539,230.955,213.4,230.634,213.4Z" transform="translate(-230.059 -213.397)" fill="#b9b9b9"/>
            </g>
          </g>
          <g id="Group_1586" data-name="Group 1586" transform="translate(5.388 3.163)">
            <g id="Group_1585" data-name="Group 1585">
              <path id="Path_1020" data-name="Path 1020" d="M228.987,134.208a.538.538,0,1,0,.6.53A.578.578,0,0,0,228.987,134.208Z" transform="translate(-228.375 -134.208)" fill="#b9b9b9"/>
            </g>
          </g>
        </svg>
  </span>
    </label>
  <div class="select-box" [ngClass]="{'disabled': isDropdownDisabled, 'error-border': isCountryCodeAndNumerMessage}">
    <div ngbDropdown (openChange)="dropdownOpenClose($event)" #dropdown="ngbDropdown" class="select-wrapper d-flex h-100 w-100">
      <div class="btn btn-intent d-flex w-100 text-truncate justify-content-between align-items-center h-100 w-100 "
        #mySelect (click)="isDropdownDisabled?null:active = !active;typeInText = ''"
        [ngClass]="{'select-focused': currentSelection?.value,'disabled':isDropdownDisabled}" ngbDropdownToggle>
        <div class="d-flex align-items-center w-100 gap-1">
          <ng-container>
            <input [readonly]="true" autocomplete="disabled" [required]="isRequired" 
              [placeholder]="showPlaceholderText != undefined ?(showPlaceholderText | translate):''" [id]="id"
              [name]="id" class="px-0 text-truncate border-0 bg-transparent dropdown-input w-100 d-flex"
              [ngModel]="(isCountryCode ? setValue(currentSelection?.value) :   currentSelection?.value || currentSelectionDate)| translate"
              (ngModelChange)="currentSelection.value = $event || currentSelectionDate = $event" #content
              placement="bottom"
              [ngbTooltip]="(isTextOverflowing ? currentSelection?.value || currentSelectionDate : null) | translate"
              (mouseenter)="checkTextOverflow(currentSelection?.value || currentSelectionDate)">
          </ng-container>
          <!-- expand svg -->
          <!-- [(ngModel)]="currentSelection?.value|| currentSelectionDate" -->

            <div class="d-flex">
              <i id="ICON_-_drop-2" class="fa-solid fa-caret-down cursor-pointer f-16"  *ngIf="!isDropdownDisabled" 
              [ngClass]="{'selected': currentSelection?.value || currentSelectionDate, 'default': !currentSelection?.value,'fa-caret-up':dropdown.isOpen()}"
                  ></i>
            </div>
          <!-- expand svg -->

          <!-- reset svg -->
          <div>
            <svg *ngIf="(currentSelection?.value || currentSelectionDate) && !isDropdownDisabled"
              (click)="clearInput($event);" id="Icon:_reset" data-name="Icon: reset" xmlns="http://www.w3.org/2000/svg"
              width="15" height="15" style="margin-top: 6px;" viewBox="0 0 18 21">
              <g id="Group_41566" data-name="Group 41566">
                <path id="Path_35107" data-name="Path 35107"
                  d="M10,9H5A.945.945,0,0,1,4,8V3A.945.945,0,0,1,5,2,.945.945,0,0,1,6,3V7h4a.945.945,0,0,1,1,1A.945.945,0,0,1,10,9Z"
                  transform="translate(-4 -2)" [style.fill]="dynamic_class=='filterDropdown' ? '#fff' : '#727272'" />
              </g>
              <g id="Group_41567" data-name="Group 41567" transform="translate(0.231 0.95)">
                <path id="Path_35108" data-name="Path 35108"
                  d="M12.507,22A9.3,9.3,0,0,1,9.1,21.367a10.057,10.057,0,0,1-4.687-3.694,1.032,1.032,0,0,1,.32-1.478,1.053,1.053,0,0,1,1.491.317,7.438,7.438,0,0,0,3.622,2.85,7.873,7.873,0,0,0,4.687.211,7.428,7.428,0,0,0,3.835-2.639,7.972,7.972,0,0,0,1.6-4.327A7.31,7.31,0,0,0,18.58,8.174,8.015,8.015,0,0,0,14.851,5.43a7.135,7.135,0,0,0-4.687,0A8.015,8.015,0,0,0,6.435,8.174a1.053,1.053,0,0,1-1.491.317.951.951,0,0,1-.213-1.478A9.495,9.495,0,0,1,9.524,3.425a9.6,9.6,0,0,1,5.966,0,9.495,9.495,0,0,1,4.794,3.588,9.017,9.017,0,0,1,1.7,5.7,9.017,9.017,0,0,1-2.024,5.594,10.319,10.319,0,0,1-5.007,3.377,8.615,8.615,0,0,1-2.45.317Z"
                  transform="translate(-4.231 -2.95)"
                  [style.fill]="dynamic_class=='filterDropdown' ? '#fff' : '#727272'" />
              </g>
            </svg>
          </div>
          <!-- reset svg -->

          <!-- lock-icon svg -->
          <svg class="ms-1 lookIcon" *ngIf="isDropdownDisabled" id="lock_1_" data-name="lock (1)"
            xmlns="http://www.w3.org/2000/svg" width="8.985" height="11.98" viewBox="0 0 8.985 11.98">
            <g id="Group_41800" data-name="Group 41800">
              <path id="Path_35135" data-name="Path 35135"
                d="M11.36,4.493h-.749v-1a3.494,3.494,0,1,0-6.988,0v1H2.875a.249.249,0,0,0-.25.25v6.24a1,1,0,0,0,1,1h6.988a1,1,0,0,0,1-1V4.742A.249.249,0,0,0,11.36,4.493Zm-3.5,5.214a.25.25,0,0,1-.248.277h-1a.25.25,0,0,1-.248-.277L6.528,8.29a.987.987,0,0,1-.408-.8,1,1,0,1,1,2,0,.987.987,0,0,1-.408.8ZM9.114,4.493H5.121v-1a2,2,0,1,1,3.993,0Z"
                transform="translate(-2.625)" fill="#727272" />
            </g>
          </svg>
          <!-- lock-icon svg -->
        </div>

      </div>
      <div class="drop_Options" [ngClass]="dynamic_heightClass" ngbDropdownMenu>
        <input *ngIf="originalDropDownList?.length > 5" autocomplete="disabled" class="type_in_Input"
          name="type_in_Input" [ngModel]="typeInText" (ngModelChange)="typeInText = $event;filterDropdownList()">
        <div class="drop_Options_without_input" [ngStyle]="{'max-height': CalculateHeight()}">
          <div class="No-options-div pe-none text-center">
            <span class="" *ngIf="dropDownList?.length<1">No {{(dropDownLabel?(dropDownLabel |
              translate)+'s':(showPlaceholderText | translate)+'s')?.replace("Select", "")}} available.</span>
          </div>
          <ng-container *ngFor="let option of dropDownList">
            <div class="w-100 d-flex align-items-center drop_Options_wrapper gap-1"
              [id]="disabledOptions?'optionList'+option.id:option.id"
              [ngClass]="{'pe-none color-disabled':option.id==optionDisable,'pe-auto color-white': option.id == null}"
              (click)="setCurrentSelection(option);dropdown.close()" (mouseenter)="checkTextOverflow(option?.value)">
              <div class="w-100 text-truncate" #content
                [ngbTooltip]="(isTextOverflowing ? option?.value : null) | translate" [container]="''"
                [disableTooltip]="!isTextOverflowing">{{option?.value | translate}}</div>
              <svg *ngIf="option?.description" #content [container]="'body'"
                [ngbTooltip]="option?.description | translate"
                placement="bottom" id="info" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                viewBox="0 0 12 12">
                <g id="Group_1582" data-name="Group 1582">
                  <g id="Group_1581" data-name="Group 1581">
                    <path id="Path_1018" data-name="Path 1018"
                      d="M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0ZM6,11.163A5.163,5.163,0,1,1,11.163,6,5.169,5.169,0,0,1,6,11.163Z"
                      fill="#b9b9b9" />
                  </g>
                </g>
                <g id="Group_1584" data-name="Group 1584" transform="translate(5.426 5.126)">
                  <g id="Group_1583" data-name="Group 1583">
                    <path id="Path_1019" data-name="Path 1019"
                      d="M230.634,213.4c-.336,0-.575.142-.575.351v2.844c0,.179.239.358.575.358s.582-.179.582-.358v-2.844C231.216,213.539,230.955,213.4,230.634,213.4Z"
                      transform="translate(-230.059 -213.397)" fill="#b9b9b9" />
                  </g>
                </g>
                <g id="Group_1586" data-name="Group 1586" transform="translate(5.388 3.163)">
                  <g id="Group_1585" data-name="Group 1585">
                    <path id="Path_1020" data-name="Path 1020"
                      d="M228.987,134.208a.538.538,0,1,0,.6.53A.578.578,0,0,0,228.987,134.208Z"
                      transform="translate(-228.375 -134.208)" fill="#b9b9b9" />
                  </g>
                </g>
              </svg>
            </div>
          </ng-container>
        </div>
        <div class="w-100 d-flex align-items-center drop_Options_wrapper f-12 ps-2" *ngIf="customRange" 
          (click)="openCustomDateRangePicker()">
          {{'DASHBOARD.DATERANGE.OPTION.CUSTOM_RANGE' | translate}}

          <svg class="ps-1" id="right-arrow" xmlns="http://www.w3.org/2000/svg" width="12" height="12"
            viewBox="0 0 9 15">
            <g id="Group_42163" data-name="Group 42163">
              <path id="Path_35157" data-name="Path 35157"
                d="M110.234,6.915,103.416.24a.853.853,0,0,0-1.185,0l-.5.491a.81.81,0,0,0,0,1.16L107.454,7.5l-5.731,5.612a.81.81,0,0,0,0,1.161l.5.491a.853.853,0,0,0,1.185,0l6.824-6.681a.816.816,0,0,0,0-1.165Z"
                transform="translate(-101.478)" fill="#047bbc" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>