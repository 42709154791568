import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { USER_ACCOUNT_DETAILS } from '../enum/common-enum';

@Injectable({
    providedIn: 'root'
  })
  export class AdminGuard implements CanActivate {
    private storage :any;
    storageSetDone: boolean = false;
    constructor(public router: Router) { this.setStorage()}
  
    setStorage() {
        // const rememberMe = localStorage.getItem('rememberMe');
        const rememberMe = 'true';
        this.storage = (rememberMe && rememberMe == "true") ? localStorage: sessionStorage; 
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(!this.storageSetDone)this.setStorage();
      let user = this.storage.getItem(USER_ACCOUNT_DETAILS);
      if (!user || user === null) {
        this.router.navigate(['/auth/login']);
        return true
      }
      else if (user) {
        if (!Object.keys(user).length) {
          this.router.navigate(['/auth/login']);
          return true
        }
      }
      return true
    }
  }