<div class="input-search-wrapper position-relative" [ngStyle]="{'width': inCalendarView ? '100%' : '100%' }">
  <input type="text" autocomplete="disabled" [(ngModel)]="inputSearchValue" (ngModelChange)="inputSearchValueEmit.emit(inputSearchValue)" (keydown.enter)="inputSearchValueEmitOnClick.emit(inputSearchValue)" [class]="searchclass" placeholder="{{'COMMON.SEARCHINPUT.PLACEHOLDER' | translate}}"/>
  <svg (click)="inputSearchValue='';inputSearchValueEmitOnClick.emit(inputSearchValue)" *ngIf="inputSearchValue" class="cursor-pointer position-relative right-8px" id="cancel" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 10 10">
    <g id="Group_41546" data-name="Group 41546">
      <path id="Path_35106" data-name="Path 35106" d="M5.552,5,9.885.668A.391.391,0,0,0,9.333.115L5,4.448.667.115A.391.391,0,0,0,.114.668L4.447,5,.114,9.334a.391.391,0,0,0,.552.552L5,5.553,9.333,9.886a.391.391,0,0,0,.552-.552Z" transform="translate(0 -0.001)" fill="#727272"/>
    </g>
  </svg>

  <svg *ngIf="inputSearchValue" class="mx-2 position-relative right-8px" xmlns="http://www.w3.org/2000/svg" width="1" height="15" viewBox="0 0 1 15">
    <path id="Path_35121" data-name="Path 35121" d="M0,0V15" transform="translate(0.5)" fill="none" stroke="#e2e2e2" stroke-width="1"/>
  </svg>
  
  <svg (click)="inputSearchValueEmitOnClick.emit(inputSearchValue)" class="search-svg" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <g id="search-interface-symbol_1_" data-name="search-interface-symbol (1)" transform="translate(-221.006 -37)">
      <g id="_x34__4_" transform="translate(221.006 37)">
        <g id="Group_41545" data-name="Group 41545">
          <path id="Path_35105" data-name="Path 35105" d="M14.864,14.184l-3.873-3.811a6.125,6.125,0,0,0,1.637-4.163A6.262,6.262,0,0,0,6.317,0,6.261,6.261,0,0,0,.006,6.211a6.261,6.261,0,0,0,6.311,6.211,6.347,6.347,0,0,0,3.972-1.387l3.888,3.826a.491.491,0,0,0,.686,0A.473.473,0,0,0,14.864,14.184ZM6.317,11.466A5.3,5.3,0,0,1,.977,6.211,5.3,5.3,0,0,1,6.317.956a5.3,5.3,0,0,1,5.34,5.255A5.3,5.3,0,0,1,6.317,11.466Z" transform="translate(-0.006)" fill="#727272"/>
        </g>
      </g>
    </g>
  </svg>
</div>
