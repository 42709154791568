import { Injectable } from '@angular/core';
import { GetrequestsModel } from 'src/app/components/appointments-and-scheduling/request-list/request-list.model';
import { RequestListService } from 'src/app/components/appointments-and-scheduling/request-list/request-list.service';
import { ResponseModel } from '../../models/response-model.model';
import { DataService } from '../../services/data.service';
import { Observable, Subscription, tap } from 'rxjs';
import { Roles } from '../../enum/common-enum';
import { StorageService } from '../../services/storage.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RequestDeclinedPopUpComponent } from '../../common-components/popup/request-declined-pop-up/request-declined-pop-up.component';
import { RealtimeService } from '../../services/realtime.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  constructor(
    public requestListService : RequestListService , 
    public service : DataService ,
    public storageservice : StorageService) {
   }

  public onDemandSytemDeclineRequestList: [] =[];

   getAppointmentData(data:any):Observable<ResponseModel>{
    let url = "clo-api/cielo/appointment";
      return this.service.get(url,true,data).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

}
