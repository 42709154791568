import { DataService } from "src/app/shared/services/data.service";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { ResponseModel } from "src/app/shared/models/response-model.model";
import { GetDashboardData } from "./dashboard.model";

@Injectable({
    providedIn: "root",
})
export class DashboardService {
    constructor(public service: DataService) {}

    getDashboardData(data: GetDashboardData): Observable<ResponseModel> {
        let url = "clo-api/cielo/statistics/dashboard";
        return this.service.get(url, true,data).pipe<ResponseModel>(
            tap((response: any) => {
                return response;
            })
        );
    }
}