

<div class="commonContainer form-group m-t-5 custom-radio-ml ms-2">


    <div class="radio radio-primary">
   <input 
    type="radio" 
    [id]="id"
    [name]="name"
    [ngModel]="status"
    [value]="radioButtonValue"
    (ngModelChange)="check.emit(radioButtonValue)"
    [required]="isRequired"
    [disabled]="disabled"
    [ngClass]="{'cursor-notAllowed':disabled}"
    > 
    <!-- (change)="onItemChange($event.target.checked)" -->

    <label [for]="id" class="ps-1 color-primary-grey f-12 {{isLabelFontMedium ? 'font-Medium' : 'font-Regular'}}" [ngClass]="{'cursor-notAllowed':disabled}">{{radioButtonLabel | translate}}<span class="digits"></span></label>
</div>  
