<div ngbDropdown #dropdown="ngbDropdown" class="d-inline-block commonInputContainer w-100" (openChange)="openChange($event)">
    <div class="d-flex">
      <label *ngIf="inputLabel" [for]="id" [ngClass]="[smallInput ? 'f-11' : 'f-12', isTextTruncate ? 'w-80' : '']"
    class="form-label"><span class="label-text">{{inputLabel | translate}}</span><span *ngIf="isRequired" class="required-color">*</span></label>

    <!-- Info Svg -->
    <ng-container *ngIf="showInfoIcon">
      <svg [ngClass]="{'stroke-red': isInvalid }" [container]="'body'" [ngbTooltip]="infoIconToolTip | translate " class="position-absolute cursor-pointer infoIcon-custom-style" placement="right" id="info" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="1 -1 12 15">
        <g id="Group_1582" data-name="Group 1582">
          <g  id="Group_1581" data-name="Group 1581">
            <path id="Path_1018" data-name="Path 1018"
              d="M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0ZM6,11.163A5.163,5.163,0,1,1,11.163,6,5.169,5.169,0,0,1,6,11.163Z"
              fill="#b9b9b9" />
          </g>
        </g>
        <g  id="Group_1584" data-name="Group 1584" transform="translate(5.426 5.126)">
          <g  id="Group_1583" data-name="Group 1583">
            <path  id="Path_1019" data-name="Path 1019"
              d="M230.634,213.4c-.336,0-.575.142-.575.351v2.844c0,.179.239.358.575.358s.582-.179.582-.358v-2.844C231.216,213.539,230.955,213.4,230.634,213.4Z"
              transform="translate(-230.059 -213.397)" fill="#b9b9b9" />
          </g>
        </g>
        <g  id="Group_1586" data-name="Group 1586" transform="translate(5.388 3.163)">
          <g  id="Group_1585" data-name="Group 1585">
            <path id="Path_1020" data-name="Path 1020"
              d="M228.987,134.208a.538.538,0,1,0,.6.53A.578.578,0,0,0,228.987,134.208Z"
              transform="translate(-228.375 -134.208)" fill="#b9b9b9" />
          </g>
        </g>
      </svg>
    </ng-container>
    <!-- Info Svg end -->
    </div>
    <input [ngClass]="{ 'border-red': isInvalid }" appTimeFormatCheck autocomplete="off" readonly [disabled]="inputDisabled" [name]="name" [id]="id" appTimeFormatCheck class="form-control" placeholder="HH:MM TT" type="text" ngbDropdownToggle [(ngModel)]="timePickerValue" [ngModel]="timePickerValue | formatTimeInput" (ngModelChange)="changeCurrentTimeToPreviousValue()" [required]="isRequired" (click)="changeCurrentTimeToPreviousValue()" />
    <!-- TIME SVG-->
    <svg (click)="!inputDisabled?dropdown?.toggle():null" [ngClass]="{'icon-disabled': inputDisabled, 'icon-reset-visible': timePickerValue && resetIconVisible}"  class="icon-position" xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 20.004 20">
      <path id="Union_2" data-name="Union 2" d="M-10163,17870a10.014,10.014,0,0,1,10-10,10.014,10.014,0,0,1,10,10,10.013,10.013,0,0,1-10,10A10.013,10.013,0,0,1-10163,17870Zm1.553,0a8.462,8.462,0,0,0,8.449,8.455,8.462,8.462,0,0,0,8.449-8.455,8.457,8.457,0,0,0-8.449-8.449A8.46,8.46,0,0,0-10161.449,17870Zm11.083,3.008-3.1-2.326a.77.77,0,0,1-.31-.619v-4.648a.773.773,0,0,1,.776-.771.773.773,0,0,1,.776.771v4.264l2.786,2.09a.77.77,0,0,1,.157,1.082.769.769,0,0,1-.623.314A.789.789,0,0,1-10150.366,17873.006Z" transform="translate(10163.002 -17860)" fill="#b9b9b9"/>
    </svg>
    <ng-container *ngIf="resetIconVisible" >
      <app-common-icon [style.fill]="dynamic_class=='filterDropdown' ? '#fff' : '#727272'"  [name]="'resetIcon'" class="reset-icon-position" *ngIf="timePickerValue"  (click)="clearSelection($event);" ></app-common-icon>
    </ng-container>
    <!-- TIME SVG-->
    <div ngbDropdownMenu aria-labelledby="dropdownBasic" [ngClass]="{ 'show': timePickerVisible }">
      <ngb-timepicker [id]="'timePicker1'" [name]="'timePicker1'" 
      [(ngModel)]="ngbTimePickerValue" (ngModelChange)="updateTime($event)" [meridian]="true" class="time-picker-custom"></ngb-timepicker>
    </div>
    
</div>