<div class="commonInputContainer position-relative" [ngClass]="ngClass">
    <label *ngIf="inputLabel" [hidden]="formSettingInput" [for]="id" [ngClass]="smallInput ? 'f-11' : 'f-12'"
        class="form-label">{{inputLabel | translate}}<span *ngIf="isRequired" class="required-color">*</span></label>
    <div class="d-flex gap-2">
        <app-dropdown 
            (clearDropdownValueErrer)="clearDropdownValueErrer($event)"
            [ngClass]="{'contryCodeBorderFocus':form?.controls?.contact_number?.invalid && (form?.controls?.contact_number?.dirty
             || form?.controls?.contact_number?.touched),'focused': form?.controls?.contact_number?.invalid && (form?.controls?.contact_number?.dirty || form?.controls?.contact_number?.touched)}"
            [isCountryCode]="true" #dropdown *ngIf="isCountryCodeDropdown"
            (dropdownDataEmit)="contactNumberMaxLength = ($event)?.contactNumberMaxLength"
            (dropdownDataEmit)="contactNumberMinLength = ($event)?.contactNumberMinLength"
            [dropdownValue]="dropdownValue" (dropdownValueEmit)="dropdownValueEmit?.emit($event)" 
            [dropDownList]="loadDropdownDataService?.commonGetDropdownModel?.dialCodes" [isRequired]="isRequired"
            class="country-code" [id]="'countryCode'"
            [showPlaceholderText]="'USERMANAGEMENT.TABLIST.TABLABEL.USERLIST.ADDEDITUSERPOPUP.COUNTRYCODE.PLACEHOLDER'">
        </app-dropdown>
        <input *ngIf="!smallInput && !formSettingInput && inputType != 'time'"
            autocomplete="off" [type]="inputType"
            class="form-control bigInput" 
            [id]="id" [name]="name"
            [maxlength]="isCountryCodeDropdown?contactNumberMaxLength??'10' : maxlength" 
            [minlength]="isCountryCodeDropdown?contactNumberMinLength??'10' : minlength"
            [placeholder]="inputPlaceholder | translate" 
            [(ngModel)]="inputValue"
            (ngModelChange)="inputValueEmit.emit(inputValue)" 
            [required]="isRequired" 
            (focus)="getValue($event)"
            [ngClass]="{'error-border': form?.controls?.contact_number?.invalid && (form?.controls?.contact_number?.dirty || form?.controls?.contact_number?.touched)}"
            (keypress)="isCountryCodeDropdown ? disableKeys($event):null"
            [email]="inputType == 'Email' ? true :false" 
            [disabled]="inputDisabled && !formSettingInput"
            (paste)="isOnPaste?onPaste?.emit($event):''">
           <svg *ngIf="inputDisabled && !formSettingInput && inputType != 'time'" class="lock-position" id="lock_1_"
            data-name="lock (1)" xmlns="http://www.w3.org/2000/svg" width="8.985" height="11.98"
            viewBox="0 0 8.985 11.98">
            <g id="Group_41800" data-name="Group 41800">
                <path id="Path_35135" data-name="Path 35135"
                    d="M11.36,4.493h-.749v-1a3.494,3.494,0,1,0-6.988,0v1H2.875a.249.249,0,0,0-.25.25v6.24a1,1,0,0,0,1,1h6.988a1,1,0,0,0,1-1V4.742A.249.249,0,0,0,11.36,4.493Zm-3.5,5.214a.25.25,0,0,1-.248.277h-1a.25.25,0,0,1-.248-.277L6.528,8.29a.987.987,0,0,1-.408-.8,1,1,0,1,1,2,0,.987.987,0,0,1-.408.8ZM9.114,4.493H5.121v-1a2,2,0,1,1,3.993,0Z"
                    transform="translate(-2.625)" fill="#727272" />
            </g>
        </svg>
        <input *ngIf="smallInput && !formSettingInput " 
            autocomplete="disabled" [type]="inputType"
            class="form-control smallInput" 
            [id]="id" [name]="name" 
            [disabled]="inputDisabled && !formSettingInput"
            [placeholder]="inputPlaceholder | translate" 
            [(ngModel)]="inputValue"
            (ngModelChange)="inputValueEmit.emit(inputValue)" 
            [required]="isRequired" 
            [pattern]="pattern"
            [email]="inputType == 'email' ? true :false">


        <input *ngIf="inputType== 'time'" autocomplete="disabled" 
            [type]="inputType"
            class="form-control timePicker p-1 p-lg-2 p-xl-2 p-md-2 p-sm-2" 
            [id]="id" appMinTimeValidator [name]="name"
            [(ngModel)]="inputValue" 
            (ngModelChange)="inputValueEmit.emit(inputValue)" 
            [required]="isRequired"
            [minTime]="minTime" 
            [min]="minTime" 
            [readonly]="inputDisabled" 
            [disabled]="inputDisabled">

        <input *ngIf="formSettingInput" 
            type="text" class="form-control xtraSmallInput text-truncate"
            [title]="inputValue" 
            [disabled]="inputDisabled" 
            [(ngModel)]="inputValue"
            [placeholder]="inputDisabled ? '' : (inputPlaceholder | translate)" [type]="inputType"
            [(ngModel)]="inputValue" 
            (ngModelChange)="inputValueEmit.emit(inputValue)" 
            [id]="id" 
            [name]="name"
            autocomplete="disabled" 
            [pattern]="pattern" 
            [required]="isRequired"
            [email]="inputType == 'email' ? true :false">
    </div>
    <ng-container *ngIf="isCountryCodeDropdown">
        <span class="countrycodenumber" *ngIf=" ( form?.controls?.contact_number?.invalid && (form?.controls?.contact_number?.dirty || form?.controls?.contact_number?.touched))">{{'COMMON.INPUT.VALIDATION.MESSAGE_PLEASE_ENTER_AN_APPROPRIATE_COUNTRY_CODE_&_NUMBER' | translate}}</span>
    </ng-container>
    <button [id]="'hidden_' + id" class="position-absolute top-0 end-0 opacity-0" tabindex="-1"></button>
</div>