import { LoadDropdownDataService } from './../../../../common-components/load-dropdown-data/load-dropdown-data.service';
import { DashboardComponent } from './../../../../../components/dashboard/dashboard.component';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from './../../../../services/storage.service';
import { LanguageServiceService } from './language-service.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../../services/nav.service';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { Router } from '@angular/router';
import { DropdownSections } from 'src/app/shared/common-components/load-dropdown-data/load-dropdown-data.model';
import { NotificationComponent } from '../notification/notification.component';
import { SELECTED_LANGUAGE } from '../../../../enum/common-enum';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
  providers: [DashboardComponent]
})
export class LanguagesComponent implements OnInit {

  public language: boolean = false;
  public selectedLanguage: any = { };
  @Output() refreshHeaderLoadedDropdowns = new EventEmitter(true);
  @Input() notificationComRef :NotificationComponent;
  
  dropdownIds = [
    { Dsi: DropdownSections.UILanguages },
  ];

  constructor(public DashboardComponent: DashboardComponent, public navServices: NavService, private router: Router, private translate: TranslateService, private toaster: ToastrService, public languageServiceService: LanguageServiceService, public storageService: StorageService,public loadDropdownDataService:LoadDropdownDataService) {
  }
  // 


  fillDropdownData(dropDownData){
    this.loadDropdownDataService.commonGetDropdownModel.uiLanguages = dropDownData?.uiLanguages??[];
    
    const userSelectedLanguage = this.storageService.retrieve(SELECTED_LANGUAGE);
    let currentLang = this.loadDropdownDataService?.commonGetDropdownModel?.uiLanguages?.find(lang => lang.rfcCode === (userSelectedLanguage));

    if (currentLang === undefined) {
      currentLang = this.loadDropdownDataService?.commonGetDropdownModel?.uiLanguages.find(lang => lang.rfcCode === 'en-US');
    }

    this.translate?.use(currentLang?.code);
    this.selectedLanguage = currentLang;
    this.languageServiceService?.selectedLanguage.next(this.selectedLanguage);
    this.storageService.store(SELECTED_LANGUAGE, this.selectedLanguage?.rfcCode);
  }

  updateUiDataAccordingToLanguage(language) {
    this.languageServiceService.updateUiLanguage({
      "language": language
    }).subscribe({
      next: (res: ResponseModel) => {
        if (res.isSucceeded === true) {
          this.refreshHeaderLoadedDropdowns.emit()
          this.reloadComponent();
        } else {
          if (res.message != undefined) {
            this.toaster.error(res.message);
          }
          if (res?.errors?.length > 0) {
            this.toaster.error(res.errors[0]);
          }
        }
      },
      error: (err) => {
      },
    });
  }

  ngOnInit() {

  }

  changeLanguage(lang) {
    if (lang.rfcCode == this.storageService.retrieve(SELECTED_LANGUAGE)) {
      return;
    }
    this.storageService.removeItem(SELECTED_LANGUAGE);
    this.storageService.store(SELECTED_LANGUAGE, lang.rfcCode);
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
    this.updateUiDataAccordingToLanguage(this.selectedLanguage?.rfcCode);
    this.languageServiceService.selectedLanguage.next(this.selectedLanguage);
  }

  reloadComponent() {
    const currentUrl = this.router.url;
    // if (currentUrl != '/admin-setting/form-settings') {
      // this.router.navigateByUrl('refreshcomponentOnly', { skipLocationChange: true }).then(() => {
      //   this.router.navigate([currentUrl]);
      // });
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);

        this.notificationComRef?.initRequestList();
        this.notificationComRef?.getAllNotifications(null , false);
      });
    }

  // }

}