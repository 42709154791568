<div #dropdown = ngbDropdown ngbDropdown class="d-inline-block">
    <div type="button" class="position-relative" id="dropdownBasic1" class="font-semi-bold d-flex align-items-center"  ngbDropdownToggle> 
        <span class="lh-1">{{selectedItem?.name | translate}}</span>
        <svg class="{{dropdown?.isOpen() ? 'rotate-icon':''}} position-absolute end-0 me-2" id="ICON_-_drop" data-name="ICON - drop" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 14 8">
            <path id="ICON_-_drop-2" data-name="ICON - drop" d="M6.247.86a1,1,0,0,1,1.505,0l4.8,5.481A1,1,0,0,1,11.8,8H2.2a1,1,0,0,1-.753-1.659Z" fill="#727272"/>
        </svg>
    </div>
    <div class="" ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <div class="text-center font-semi-bold dropdown_option cursor-pointer" [ngClass]="{'selected':selectedItem?.name == item?.name}" (click)="select(item) ;dropdown?.close()" *ngFor="let item of pageSizes" >{{item?.name | translate}}</div>
    </div>
</div>