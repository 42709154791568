import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormatAMPM',
})
export class TimeFormatAMPMPipe implements PipeTransform {

  transform(militaryTime: string , isDateTime=false): string {
    if(!militaryTime) return "";
    if(isDateTime){
      const date = new Date(militaryTime.replace('Z', ''));
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toUpperCase();
    }
    const date = new Date(`2000-01-01T${militaryTime}`);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toUpperCase();
  }

}
