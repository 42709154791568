import { Pipe, PipeTransform } from '@angular/core';
import { format} from 'date-fns';


@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(!value) return;
    const date = new Date(value?.replace('Z', ''));
    return format(date , "MM/dd/yyyy")
  }

}
