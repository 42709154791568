<div class="custom_dropdown_container {{dynamic_class}}">
    <label *ngIf="dropDownLabel" [for]="id" class="form-label f-12">{{dropDownLabel | translate}}<span *ngIf="isRequired" class="required-color">*</span></label>
    <div class="p-0 position-relative">
        <div class="custom-dropdown"#multi [class.open]="dropdownOpen">
            <div class="selected-option d-flex align-items-center"  [ngClass]="{'select-focused': selectedOption?.value}" (click)="toggleDropdown()">
              <span *ngIf="placeholderName" class="f-12 font-Medium color-primary-grey">{{placeholderName}}</span>
              <i id="ICON_-_drop-2" class="fa-solid fa-caret-down cursor-pointer color-primary-grey f-16"
                [ngClass]="{'fa-caret-up':dropdownOpen}"></i>
            </div>
            <div *ngIf="dropdownOpen" class="dropdown-options pt-3 px-2 position-absolute" [ngStyle]="{ 'height': getDropdownHeight() }">
              <div class="pb-2" *ngFor="let item of bindedDropdownList">
                <app-checkbox [colorDarkGray]="true" [readonly]="item.isAlreadyInGroup" [dyanamicId]="item.id" [status]="item.isChecked" (check)="item.isChecked = $event;onChecked(item)" [lableName]="item.name" [labelClass]="'labelClass'" [smallCheckbox]="'smallCheckbox'"></app-checkbox>
              </div>
            </div>
          </div>
        <div *ngIf="addIcon" [ngClass]="{ 'clicked':isClicked}" class="add_button position-absolute top-0 d-flex align-items-center justify-content-center cursor-pointer" (click)="addSelectedItems()">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
            <path id="Path_35092" data-name="Path 35092" d="M150.772,141.488h-5v-5a1.25,1.25,0,1,0-2.5,0v5h-5a1.25,1.25,0,0,0,0,2.5h5v5a1.25,1.25,0,0,0,2.5,0v-5h5a1.25,1.25,0,1,0,0-2.5Z" transform="translate(-137.022 -135.238)" [attr.fill]="'#fff'" />
          </svg>    
        </div>
    </div>
    
  </div>