import { TimeDuration } from "../create-edit-request/create-edit-request.model";

export class AssignInterpreters {
}

export class GetInterpreterModel{
    isUnavailableInterpretersIncluded: boolean = false;
    genderId: number | null = null;
    langVariantId: number | null = null;
    timeDuration = new TimeDuration()
}
export class NewGetInterpreterModel{
    isAcceptableLanguage:boolean = false;
    isUnavailableInterpretersIncluded: boolean = false;
    appointmentId:number|null = null;
    languageVariantId:number;
}

export class SelectedInterpreterModel{
    languageId:number;
    interpreterId:number;
}

export enum AssignationProcessType
{
    Automatic = 1,
    Manual = 2
}

export class AssignModal {
    appointmentId: number|number = null;
    assignationProcessTypeId: number|number = null;
    isAcceptableLanguage:boolean = false;
    interpretersUserId: number[] | null=[];
  }