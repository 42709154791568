
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RequestAcceptedPopupComponent } from '../request-accepted-popup/request-accepted-popup.component';
import { DeclinedPopupComponent } from '../declined-popup/declined-popup.component';
import { CommunicationType, RequestCategory, ScheduleOptions } from '../create-edit-request/create-edit-request.model';
import { ModalTitle, Roles } from 'src/app/shared/enum/common-enum';
import { RequestListService } from '../../request-list.service';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-accept-request',
  templateUrl: './accept-request.component.html',
  styleUrls: ['./accept-request.component.scss']
})
export class AcceptRequestComponent implements OnInit{
  @Input() acceptedData;
  acceptedRequestlistData : any;
  requestCategory = RequestCategory;
  scheduleOptions = ScheduleOptions;
  communicationType = CommunicationType;
  modalTitle = ModalTitle;
  userDetails: any;
  roles = Roles;

    constructor(
      public modalService: NgbModal,
      public activeModal: NgbActiveModal,
      public requestListService: RequestListService,
      public toaster: ToastrService,
      public storageService: StorageService){
    }

    ngOnInit(){
       this.acceptedRequestlistData = this.acceptedData;
       this.userDetails = this.storageService.retrieve("userAccountDetails");
    }

    // get decline api
    callInitialDeclineApiCallCount:number = 0;
    getdeclined(id){
      if(this.callInitialDeclineApiCallCount == 0){
        if(this.storageService.retrieve('userAccountDetails')?.organizations[0].role.value == Roles.Interpreter){
          this.requestListService.declineAppointmentByInterpreter({appointmentId:id})
          .subscribe((res) => {
            if (res.isSucceeded) {
              this.openDeclinedPopup();
              this.activeModal.close(res.isSucceeded);
              // this.toaster.success(res.message);
              this.callInitialDeclineApiCallCount = 0;
            }
            else{
              if (res.message != undefined) {
                this.toaster.error(res.message);
            }
            if (res?.errors?.length > 0) {
                this.toaster.error(res.errors[0]);
            }
            this.callInitialDeclineApiCallCount = 0;
            }
          });
        }
        else{
          this.requestListService.getDeclined(id).subscribe((res) => {
            if (res.isSucceeded) {
              this.openDeclinedPopup();
              this.activeModal.close(res.isSucceeded);
              // this.toaster.success(res.message);
              this.callInitialDeclineApiCallCount = 0;
            }
            else{
              if (res.message != undefined) {
                this.toaster.error(res.message);
            }
            if (res?.errors?.length > 0) {
                this.toaster.error(res.errors[0]);
            }
            this.callInitialDeclineApiCallCount = 0;
            }
          });
        }

      }
      this.callInitialDeclineApiCallCount = this.callInitialDeclineApiCallCount + 1;
    }

    // get accepted api
    callInitialAcceptApiCallCount:number = 0;
    getAccepted(id){
      if(this.callInitialAcceptApiCallCount == 0){
        if(this.storageService.retrieve('userAccountDetails')?.organizations[0].role.value == Roles.Interpreter){
          this.requestListService.acceptAppointmentByInterpreter({appointmentId:id})
          .subscribe((res) => {
            if (res.isSucceeded) {
              this.openRequestAcceptpopup();
              this.activeModal.close(res.isSucceeded);
              // this.toaster.success(res.message);
              this.callInitialDeclineApiCallCount = 0;
            }
            else{
              if (res.message != undefined) {
                this.toaster.error(res.message);
            }
            if (res?.errors?.length > 0) {
                this.toaster.error(res.errors[0]);
            }
            this.callInitialDeclineApiCallCount = 0;
            }
          });
        }else{
          this.requestListService.getAccepted(id).subscribe((res) => {
            if (res.isSucceeded) {
              this.openRequestAcceptpopup();
              this.activeModal.close(res.isSucceeded);
              // this.toaster.success(res.message);
            }
            else{
              if (res.message != undefined) {
                this.toaster.error(res.message);
            }
            if (res?.errors?.length > 0) {
                this.toaster.error(res.errors[0]);
            }
            }
          });
        }
      }
      this.callInitialAcceptApiCallCount = this.callInitialAcceptApiCallCount + 1;
    }

    // open accepted popup
    openRequestAcceptpopup(){
      const modalOption : NgbModalOptions ={
        centered: true,
        backdrop: "static",
        keyboard: false,
        size: "md",
        windowClass: "confirmedpopup",
      }
      const modalRef = this.modalService.open(RequestAcceptedPopupComponent, modalOption);
      modalRef.componentInstance.appointmentCategoryData = this.acceptedRequestlistData;
      modalRef.result.then((res) => {
      });
    }

    // open declined popup
    openDeclinedPopup(){
      const modalOption : NgbModalOptions ={
        centered: true,
        backdrop: "static",
        keyboard: false,
        size: "md",
        windowClass: "confirmedpopup",
      }
      const modalRef = this.modalService.open(DeclinedPopupComponent, modalOption); 
    }

}