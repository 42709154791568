import { RelativeTimePipe } from './../../../../custom-pipes/relative-time.pipe';
import { ToastrService } from 'ngx-toastr';
import { Component, ElementRef,  OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationSidebarComponent } from '../../../notification-sidebar/notification-sidebar.component';
import { NotificationService } from './notification.service';
import { Ids, NotificationRequestModel, Notifications } from "./notification.model"
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { StorageService } from '../../../../services/storage.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [RelativeTimePipe]
})
export class NotificationComponent implements OnInit {
  public openNotification: boolean = false;
  public openClosedSidebarNotification: boolean = false;
  @ViewChild("notificationSidebarComponent") notificationSidebarComponent!: NotificationSidebarComponent;
  isTouchDevice : any;
  notificationList: Notifications[] = [];
  notificationIds = new Ids();

  notificationListRequestModel = new NotificationRequestModel();
  notificationBadgeCount = 0;

  constructor(private offcanvasService: NgbOffcanvas, public notificationService: NotificationService, public toaster: ToastrService,
    private relativeTimePipe: RelativeTimePipe , public storageService : StorageService, private elementRef: ElementRef) { }
  ngOnInit() {
    this.initRequestList();
    this.getAllNotifications(null , false);
    setInterval(() => {
      this.updateTimeForNotifications();
    }, 1000);
  }

  updateTimeForNotifications() {
    this.notificationList.map(a => a.timeDuration)
    this.notificationList.forEach(item => {
      item.timeDuration = this.relativeTimePipe.transform(item.triggeredDateTime);
    });
  }

  getAllNotifications(isRead = null , fromScroll=false ,fromMarkAsReadOrDelete=false) {
    this.notificationListRequestModel.isRead = isRead;
    this.notificationService.getAllNotifications(this.notificationListRequestModel)
      .subscribe({
        next : (res:any) =>{
          if(res?.isSucceeded){
            if(!fromScroll){
              this.notificationList = res?.response??[];
            } else if(fromMarkAsReadOrDelete){
              const filterNewlyNotifications = res?.response?.filter(itemB => !this.notificationList?.some(itemA => itemA?.id === itemB?.id));
              this.notificationList = this.notificationList.concat(filterNewlyNotifications);
            } else if(fromScroll){
              this.notificationList = this.notificationList.concat(res?.response);
            }
            this.notificationBadgeCount = res?.unreadNotificationsCount ??0;
          }
          else{
            this.notificationList = [];
          }
        },
        error : (err) =>{
          console.log(err);
        },complete : ()=>{
          if(this?.notificationSidebarComponent)this.notificationSidebarComponent.notificationLoading = false;
        }
      })
  }

//   @HostListener('document:click', ['$event'])
// public onClick(event: MouseEvent): void {
//   // Check if the click occurred outside the current element
//   const isOutsideClick = !this.elementRef.nativeElement.contains(event.target);
//   if (isOutsideClick) {
//     this.closeSidebarNotification();
//   }
// }

// private closeSidebarNotification(): void {
//   // Close the sidebar notification
//   this.openClosedSidebarNotification = false;
// }

public toggleNotificationMobile(): void {
  // Check if the device supports touch
  this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  if (this.isTouchDevice) {
    // Toggle the sidebar notification state
    this.openClosedSidebarNotification = !this.openClosedSidebarNotification;
  }
}

  openEnd(content: TemplateRef<any>) {
    this.openClosedSidebarNotification = true;
    setTimeout(() => {
      const offcanvasRef: NgbOffcanvasRef = this.offcanvasService.open(this.notificationSidebarComponent.content, { position: 'end' });
      offcanvasRef.dismissed.subscribe(() => {
        this.openClosedSidebarNotification = false;
        this.notificationListRequestModel = new NotificationRequestModel();
        this.getAllNotifications(null , false);
      });
    }, 100);

  }

  markAsread(item) {
    if(item?.id){
      item.isRead = true;
    }
    this.notificationService.markAsReadNotifications(item?.id)
      .subscribe((res: ResponseModel) => {
        if (res.isSucceeded) {
          if(!item) {
           this.notificationList?.map((x)=>x.isRead = true);
           this.notificationBadgeCount = 0;
          } else {
            this.notificationList = this.notificationList.filter((notification) => notification.id != item?.id);
            this.getAllNotifications(this.notificationListRequestModel?.isRead??null ,true , true);
          }
          
          this.toaster.success(res.message);
        } else {
          if (res.message != undefined) {
            this.toaster.error(res.message);
          }
          if (res?.errors?.length > 0) {
            this.toaster.error(res.errors[0]);
          }
        }
      });
  }


  deleteNotification(item){
      this.notificationService.deleteNotifications(item?.id)
      .subscribe((res: ResponseModel) => {
          if (res.isSucceeded) {
            if(!item) {
              this.notificationList = [];
            } else {
              this.notificationList = this.notificationList.filter((notification) => notification?.id != item?.id);
              this.getAllNotifications(this.notificationListRequestModel?.isRead??null ,true , true);
            }
            this.toaster.success(res.message);
          } else {
            if (res.message != undefined) {
              this.toaster.error(res.message);
          }
          if (res?.errors?.length > 0) {
              this.toaster.error(res.errors[0]);
          }
          }
      });
    }

  checkAsmarkStatus(): boolean {
    return this.notificationList?.some(x => x.isRead !== true);
  }

  ///// scroll wise pagination

  initRequestList(): void {
    this.notificationListRequestModel = new NotificationRequestModel();
    // this.notificationListRequestModel.pagination.pageNumber = 1;
    // this.notificationListRequestModel.pagination.pageSize = 25;
}

getNotificationOnTabClick(event){
  this.notificationListRequestModel= new NotificationRequestModel();
  this.getAllNotifications(event ,false);
}

  onScroll(notificationDivClassName) {
    if (this.notificationList?.length >=this.notificationListRequestModel?.pagination?.pageNumber * this.notificationListRequestModel?.pagination?.pageSize) {
      const container = document.querySelector(notificationDivClassName);
      if (container) {
        const maxScroll = container.scrollHeight - container.clientHeight;
        const currentScroll = container.scrollTop;
        if (maxScroll <= currentScroll + 15) {
          this.notificationListRequestModel.pagination.pageNumber += 1;
          this.notificationSidebarComponent.notificationLoading = true;
          this.getAllNotifications(this.notificationListRequestModel?.isRead??null ,true)
        }
      }
    }
  } 

}