import { PaginationBaseModel } from './../../../../../shared/models/pagination-base-model.model';
export class UserList {
    id?:string;
    isActive:boolean = true;
    isOnPriority?:boolean = true;
    phoneCodeId:number;
    isSmsAllowed?:boolean;
    firstName:string;
    lastName:string;
    email:string;
    contactNumber:string;
    genderId:number|string;
    location:string;
    timeZoneId:number|string;
    organizations?:Organization[];
    roleId?: string | number;
    interpreterLocationTypeId:number|string;
}


export class Organization{
    organizationId:number|string;
    roleId:number|string;
    groupId?:number|string;
    entryState:number = 0;
}
export class GetUserDataModel extends PaginationBaseModel{
    id?:string;
    IsOnlyInactiveUsers?: boolean;
    IsOnlyPriorInterpreterUsers?: boolean;
    roleCategoryTypeId?:number;
    roleId?: string;
    organizationId?: string|number;
    groupId?: string|number;
}

export class DropDown{
    roles:DropDownModel[];
    timezones:DropDownModel[];
    genders:DropDownModel[];
    organizations:DropDownModel[];
    groups:DropDownModel[];
}

export class DropDownModel{
    id:number|string;
    name:string;
    description?:string;
    userId?:string;
    organizationCategory?:number| string | null;
    roleCategory?:number| string | null;
    organizationId?:number | null;
}