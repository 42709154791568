import { DataService } from './../../../../services/data.service';
import { ResponseModel } from './../../../../models/response-model.model';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Ids } from './notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public service: DataService) { }


  getAllNotifications(): Observable<ResponseModel> {
    var url = "clo-api/gateway/notifications";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  markAsReadNotifications(data: Ids): Observable<ResponseModel>{
    var url = "clo-api/cielo/notification/mark-as-read";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  deleteNotifications(data: Ids): Observable<ResponseModel>{
    var url = "clo-api/cielo/notification/batch-delete";
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

}
