export class PaginationBaseModel {
  searchText?: string|null;
  pagination? =  new Pagination();
  sort? =  new Sort();
}

export class Pagination{
  pageNumber?: number = 0;
  pageSize?: number = 0;
}

export class Sort{
  sortColumn?: string|null;
  sortDirection?: number =0;
}

export class NgxDatatableSSRConfig {
    rows              : any[] = [];
    totalRecords      : number;
    pageSize          : number = 8;
    searchText        : string|null = null;
    currentPageNumber : number = 1;
    tableOffset       : number = 0;
  
    limitOptions = [
      {
        key: '5',
        value: 5
      },
      {
        key: '10',
        value: 10
      },
      {
        key: '15',
        value: 15
      },
      {
        key: '20',
        value: 20
      },
      {
        key: '30',
        value: 30
      },
      {
        key: '50',
        value: 50
      },
      {
        key: 'All',
        value: 1
      },
    ];
}
