import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-decline-request',
  templateUrl: './decline-request.component.html',
  styleUrls: ['./decline-request.component.scss']
})
export class DeclineRequestComponent {
  constructor(public activeModal: NgbActiveModal){}
  ngOnInit(): void {}

}
