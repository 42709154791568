import { Injectable, Renderer2 } from '@angular/core';
import { NgxDatatableSSRConfig } from '../models/pagination-base-model.model';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { AppointmentInternalStatusString } from 'src/app/components/appointments-and-scheduling/request-list/request-list.model';
import { Roles } from '../enum/common-enum';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public toaster: ToastrService;
  public ngxDatatableSSRConfig:NgxDatatableSSRConfig;
  public emptyMessage:string ="No Rows To Display";
  roles = Roles;
  constructor(public renderer?:Renderer2) { }

    // onkeydown method focus next input start
    onKeydown(event: KeyboardEvent, nextElement:string,previousElement?:string) {
      if (event.key === 'Enter') {
        event.preventDefault();
        let element = document.getElementById(nextElement) as HTMLInputElement;
        // const element = this.renderer?.selectRootElement(nextInput);
        element?.focus();
        if (element instanceof HTMLButtonElement) {
          element.click();
        }
      }
      else if(event.key === 'Tab'){
      }
    }
   // onkeydown method focus next input end

  //#region Pagination

  public onPageChange(pageNum: number) {
    this.ngxDatatableSSRConfig.currentPageNumber = pageNum;
    this.ngxDatatableSSRConfig.tableOffset = pageNum - 1;
  }

  public onPageSizeChanged(pageSize: number) {
    // this.ngxDatatableSSRConfig.getAll = pageSize == 1;
    this.ngxDatatableSSRConfig.pageSize = pageSize;
    this.onPageChange(1);
  }

  public onSort(pageNum: number) {
    this.onPageChange(1);
  }

  public updateTableData(response: any,rows) {
    this.ngxDatatableSSRConfig.rows = rows;
    this.ngxDatatableSSRConfig.totalRecords = response?.totalRecords;
  }

  //#endregion

  //#region time and date Format converter

  convertTimeStringToNgbTimeStruct(timeString: string): NgbTimeStruct {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':').map(Number);

    // Create an NgbTimeStruct object
    const timeStruct: NgbTimeStruct = {
        hour: hours,
        minute: minutes,
        second: 0 // Set seconds to 0, as NgbTimeStruct includes seconds
    };

    return timeStruct;
}

setMinTime(previousTime,increaseHours:number){
    const currentTime = new Date();
    var minTime: NgbTimeStruct = { hour: currentTime.getHours(), minute: currentTime.getMinutes(), second: currentTime.getSeconds() };
    if(previousTime != undefined && previousTime != ''){
        minTime = this.convertTimeStringToNgbTimeStruct(previousTime);
    }
    else{
        minTime = minTime;
    }
    
    // If increaseHours is 0, set the minimum time to the current time
    // Add increaseHours to the current time to set the minimum time
    const newHour = minTime.hour + increaseHours;
    // Ensure that the new hour does not exceed 23
    minTime.hour = newHour > 23 ? 23 : newHour;
    minTime.minute = currentTime.getMinutes();
    minTime.second = currentTime.getSeconds();
    return minTime;
}

  convertTimeFormat(time: string, format: string): string {
    if(time != undefined){
      const datePipe = new DatePipe('en-US');
      const currentDate = new Date();
      const [hours, minutes] = time.split(':');
    
      currentDate.setHours(Number(hours));
      currentDate.setMinutes(Number(minutes));
    
      const convertedTime = datePipe.transform(currentDate, format);
      return convertedTime;
    }
    else{
      return null;
    }
  }

//  only Time Conversion like 12:30:00 #start  
convertTimeToFullFormat(time:string) {
  const [hours, minutes] = time.split(':');
  return `${hours}:${minutes}:00`;
}
//  only Time Conversion like 12:30:00 #end  

  convertDateFormatToDateTime(formattedDate: string, timeFormat: string): string {
    if(formattedDate != null){
      const datePipe = new DatePipe('en-US');
      const currentDate = new Date(formattedDate);
    
      // Format the time using the DatePipe
      const formattedTime = datePipe.transform(currentDate, timeFormat);
      return formattedTime;
    }
    else{
      return null;
    }
  }

  isDateFormatCorrect(dateString: string): boolean {
    const expectedFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?$/;
    return expectedFormatRegex.test(dateString);
  }

  convertTimeTo24HourFormat(time12h: string , timeFormat: string ): string {
    const datePipe = new DatePipe('en-US');
    const parsedTime = new Date(`2000-01-01 ${time12h} ${time12h.includes('AM') ? 'AM' : 'PM'}`);
    return datePipe.transform(parsedTime,timeFormat) || '';
  }

  transformInShort(timeString: string): string {
    const timeParts = timeString.split(':');
    const formattedTime = `${timeParts[0]}:${timeParts[1]}`;
    return formattedTime;
  }

  onlyHoursandMin(time:string,timeFormat: string){
    const datePipe = new DatePipe('en-US');
    const date = new Date(`2000-01-01T${time}`);
    return datePipe.transform(date, timeFormat);
  }

  formatDateToYYYYMMDD(dateString: any): string {
    const datePipe = new DatePipe('en-US');
    const date = new Date(dateString);
    return datePipe.transform(date, 'yyyy-MM-dd');
  }
  
  //#endregion

  // change key Name region start
  changeKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }
  //end


  //guid id gernerator
 generateGUID(): string {
    const pattern: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return pattern.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public markFormAsTouchedAndDirty(form: NgForm) {
    Object.keys(form?.controls).forEach(field => {
      const control = form?.controls[field];
      control?.markAsTouched({ onlySelf: true });
      control?.markAsDirty({ onlySelf: true });
    });
  }


checkIsEnableOrDisable(data ,offsetUtc,appointmentPermission? ){
  const checkingRequestIsDuringOrAfter = {
      isEditIconDisableForInterpreter:undefined,
      isDuringAppointment: undefined,
      isAfterAppointment : undefined,
      isStartTimeExceed  : undefined
  }

  if((data?.appointmentStatus == AppointmentInternalStatusString.Accepted || data?.appointmentStatus == AppointmentInternalStatusString.EsAccepted ) || (appointmentPermission?.CreateAppointmentRequest || appointmentPermission?.UpdateRequest || appointmentPermission?.RescheduleRequest  )){
  const appointmentDateStartTime = `${data?.startDateTime}`;
  const utcAppoitmentStartDateTime = offsetUtc ? this.convertLocalToUTCFormat(appointmentDateStartTime ,offsetUtc[1]) : this.convertLocalToUTCFormat(appointmentDateStartTime);
  if(data?.endDateTime){
    const appointmentDateEndTime = `${data?.endDateTime}`;
    var utcAppoitmentEndDateTime = offsetUtc ? this.convertLocalToUTCFormat(appointmentDateEndTime , offsetUtc[1]) : this.convertLocalToUTCFormat(appointmentDateEndTime);
  }
  const date = new Date();
  const utcDateTime = date.toISOString();
  const currentUtcInmilliseconds = Date.parse(utcDateTime);
  if(utcAppoitmentStartDateTime?.utcMillis > currentUtcInmilliseconds){
      checkingRequestIsDuringOrAfter.isEditIconDisableForInterpreter = true;
      checkingRequestIsDuringOrAfter.isStartTimeExceed = true;
  } else {
      checkingRequestIsDuringOrAfter.isEditIconDisableForInterpreter = false;
      checkingRequestIsDuringOrAfter.isStartTimeExceed = false;
      checkingRequestIsDuringOrAfter.isDuringAppointment = currentUtcInmilliseconds >= utcAppoitmentStartDateTime?.utcMillis && currentUtcInmilliseconds <= utcAppoitmentEndDateTime?.utcMillis;
      checkingRequestIsDuringOrAfter.isAfterAppointment = currentUtcInmilliseconds > utcAppoitmentEndDateTime?.utcMillis;
  };
} else {
  checkingRequestIsDuringOrAfter.isEditIconDisableForInterpreter = true;
  checkingRequestIsDuringOrAfter.isStartTimeExceed = true;
}

return checkingRequestIsDuringOrAfter;
}


convertLocalToUTCFormat(dateStr, utcOffset = "+00:00") {
  const [year, month, day, hour, minute, second] = dateStr.split(/[-T:Z]/);  // Parse the input date string (assumed to be in the given UTC offset)
  const date = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
  const [sign, hours, minutes] = utcOffset.match(/([+-]?)(\d{2}):(\d{2})/).slice(1); // Parse the UTC offset string (+hh:mm or -hh:mm)
  const offsetMinutes = (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '-' ? -1 : 1);
 
  const utcTime = new Date(date.getTime() - offsetMinutes * 60 * 1000);  // Adjust to UTC
  return {  // Return UTC time and milliseconds
    utcDate: utcTime?.toISOString(),
    utcMillis: utcTime?.getTime(),
  };
}

convertUTCToLocalFormat(utcDateStr, utcOffset = "+00:00") {
  const [year, month, day, hour, minute, second] = utcDateStr.split(/[-T:Z.]/);
  const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, second));  // Parse the UTC date string

  const [sign, hours, minutes] = utcOffset.match(/([+-]?)(\d{2}):(\d{2})/).slice(1);
  const offsetMinutes = (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '-' ? -1 : 1);   // Parse the UTC offset string (+hh:mm or -hh:mm)
  
  const localTime = new Date(utcDate.getTime() + offsetMinutes * 60 * 1000); // Adjust UTC time to local time
  return {  // Return local time and milliseconds
      localDate: localTime?.toISOString(),
      localMillis: localTime?.getTime(),
  };
}


//#region get current time base on timezone
getCurrentDateTimeBaseOnTimezone(userDetails):{dateTime ,year , month , date ,time}|null{
  const now = new Date(); // Get current date and time
  const utcDate = now?.toISOString(); // Converts to ISO 8601 format in UTC
  const offsetMatch = userDetails?.timezone?.match(/\(UTC([+-]\d{2}:\d{2})\)/);
  const dt =  this.convertUTCToLocalFormat(utcDate , offsetMatch ? offsetMatch[1]:undefined);
  const [dateTime ,year,month ,date,time ]:any = dt?.localDate?.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})/);
  return dt ? { dateTime: dateTime, year: year, month: month, date: date, time: time } : null;
}

//#endregion get current time base on timezone

  //#region  get dynamically sr number start
  calculateRowIndex(pageNumber, rowIndex, pageSize) {
    return (pageNumber - 1) * pageSize + rowIndex;
  }
  //#region  get dynamically sr number end

  getPageNumbers(totalItems, pageSize, maxSize) {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate total pages
    const pagesToDisplay = Math.min(totalPages, maxSize); // Limit by maxSize
    // Generate an array of page numbers
    return Array.from({ length: pagesToDisplay }, (_, i) => i + 1);
}

//#region for disabling Tooltip on Scroll:
    onScroll(tooltips) { 
      tooltips?.forEach(tooltip => tooltip?.close());
    }
//#endregion for disabling Tooltip on Scroll End:

}