import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CreateEditRequestComponent } from 'src/app/components/appointments-and-scheduling/request-list/pop-ups/create-edit-request/create-edit-request.component';
import { RequestListService } from 'src/app/components/appointments-and-scheduling/request-list/request-list.service';
import { ModalTitle } from 'src/app/shared/enum/common-enum';
import { LoadDropdownDataService } from '../../load-dropdown-data/load-dropdown-data.service';

@Component({
  selector: 'app-request-declined-pop-up',
  templateUrl: './request-declined-pop-up.component.html',
  styleUrls: ['./request-declined-pop-up.component.scss']
})
export class RequestDeclinedPopUpComponent implements OnInit {

  appointmentRequest;
  modalTitle = ModalTitle;
  
  constructor(public activeModal : NgbActiveModal , public requestListService : RequestListService ,public modalService : NgbModal,public loadDropdownDataService:LoadDropdownDataService){
  }

  ngOnInit(): void {
  }

  close(){
    this.activeModal.close();
    this.requestListService.onDemandIgnore(this.appointmentRequest?.id).subscribe({
      next:(res)=>{
      },error:()=>{

      }
    })
  }

  openCreateEditRequestPopUp() {
    this.close();
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "md",
      windowClass: "addEditUserList",
    };
    const modalRef = this.modalService.open( CreateEditRequestComponent,modalOption);
    modalRef.componentInstance.ModalTitleFromReqList = this.modalTitle.AppointmentForm;
    const request = structuredClone(this.appointmentRequest);
    request.date = undefined;
    request.startTime = undefined;
    request.endTime = undefined;
    request.scheduleOptionsId = undefined
    modalRef.componentInstance.editAppointmentListData = request;
    modalRef.result.then((res) => {
      if (res) {
        this.requestListService.triggerLoadReq();
      }
    });
  }

  getStateName(id){
    return this.loadDropdownDataService?.commonGetDropdownModel.states.find((x)=> x?.id == id)?.value;
  }

}
