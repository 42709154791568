import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-linguistic-close-request',
  templateUrl: './linguistic-close-request.component.html',
  styleUrls: ['./linguistic-close-request.component.scss']
})
export class LinguisticCloseRequestComponent {
  constructor(public activeModal: NgbActiveModal){}
  ngOnInit(): void {}

}
