import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// // for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// // for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// // for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { NgIdleModule } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { IdleDetectionService } from './shared/services/idle-detection.service';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule } from "ngx-ui-loader";
import { ExcludeHttpUrlsForLoader } from './shared/enum/common-enum';
initializeApp(environment.firebase);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OverlayModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NgbTooltipModule,
    NgIdleModule.forRoot(),
    ToastrModule.forRoot({
      // timeOut: 5000,
      disableTimeOut:true,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton:true,
      autoDismiss:false
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    
//     // for HttpClient use:
    // LoadingBarHttpClientModule,
//     // for Router use:
    // LoadingBarRouterModule,
//     // for Core use:
    // LoadingBarModule,

     // for Core use:
    NgxUiLoaderModule.forRoot({
      fgsColor :'#047BBC',
      pbColor  :'#047BBC',
      fgsType  : "three-bounce",
      fgsSize  : 80,
    }),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      exclude : [ExcludeHttpUrlsForLoader.PushNotification , ExcludeHttpUrlsForLoader.Dropdowns ,ExcludeHttpUrlsForLoader.LookUpDropdown]
    }),
  ],
  providers: [Keepalive, IdleDetectionService, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
