<div>
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle color-red font-semi-bold f-13 mx-auto">{{"REQUEST_DECLINED_POPUP_HEADING" | translate}}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-2">
        <div class="text-center">
            <div class="color-primary-grey font-semi-bold text-center f-11">{{'REQUEST_DECLINED_POPUP_DESC1' | translate}}<br>{{'REQUEST_DECLINED_POPUP_DESC2' | translate}}</div>
        </div>
        <div class="color-primary-grey f-11 mt-3 font-semi-bold me-4">
            <div class="d-flex justify-content-between">
                <span>{{appointmentRequest?.appointmentNumber}}&nbsp; {{'Interpretation Request'}}</span>
                <span class="f-9 color-greylight">{{'Created on ' + (appointmentRequest?.createdDate | date: 'MMM d, y') }}</span>
            </div>
            <span>{{ appointmentRequest?.interpretationLanguages[0]?.fromLang?.value + (appointmentRequest?.interpretationLanguages[0]?.fromLang?.value ? '-':'') + (appointmentRequest?.interpretationLanguages[0]?.fromLangVariant?.value)+  (appointmentRequest?.interpretationLanguages[0]?.toLang?.value?' to ':('COMMON.NOTAVAILABLE' | translate)) + (appointmentRequest?.interpretationLanguages[0]?.toLang?.value) + (appointmentRequest?.interpretationLanguages[0]?.fromLang?.value ? '-':'') + appointmentRequest?.interpretationLanguages[0]?.toLangVariant?.value}}</span>
            <div class="d-flex justify-content-between">
                <span class="font-Regular">{{getStateName(appointmentRequest?.state?.id)}}</span>
                <span class="font-Regular">{{appointmentRequest?.startDateTime | dateFormat}}</span>
                <span class="font-Regular">{{(appointmentRequest?.startDateTime | timeFormatAMPM :true) + '-'+ (appointmentRequest?.endDateTime | timeFormatAMPM :true) }}</span>
            </div>
        </div>
    
    </div>
    <div class="modal-footer px-3 pt-2 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 d-flex justify-content-center gap-4">
        <app-button [buttonType]="'button'" (buttonOutputEvent)="close()" [buttonClass]="'button-danger-outline buttonBig w-75px'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.CLOSE'"></app-button>
        <app-button [buttonType]="'submit'" (buttonOutputEvent)="openCreateEditRequestPopUp()" [buttonClass]="'button-secondary buttonBig w-165px'"  [buttonLabel]="'REQUEST_DECLINED_POPUP_BUTTON.LABEL'"></app-button>
      </div>
    </div>
  </div>