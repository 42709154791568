import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  @Input() inputSearchValue:string;
  @Output() inputSearchValueEmit:EventEmitter<string> = new EventEmitter();
  @Output() inputSearchValueEmitOnClick:EventEmitter<string> = new EventEmitter();
  @Input() inCalendarView:boolean;
  @Input() searchclass : string;
  // @Output() inputSearchButtonEmit:EventEmitter<string> = new EventEmitter();

  constructor(){
    this.disableAutocomplete();
  }

  disableAutocomplete() {
		const formFields = document.getElementsByTagName('input');
		for (let i = 0; i < formFields.length; i++) {
		  formFields[i].setAttribute('autocomplete', 'off');
		}
	}
}
