import { StorageService } from "src/app/shared/services/storage.service";
import {
    Component,
    PLATFORM_ID,
    Inject,
    ViewChild,
    ElementRef,
} from "@angular/core";
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from "@ngx-loading-bar/core";
import { map, delay, withLatestFrom } from "rxjs/operators";
// import { TranslateService } from '@ngx-translate/core';
import { environment } from "../environments/environment";
import { getMessaging, getToken } from "firebase/messaging";
import { LoginService } from "./auth/login/login.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { IdleDetectionService } from "./shared/services/idle-detection.service";
import { TranslateService } from "@ngx-translate/core";
import { UILanguage } from "src/app/shared/components/header/elements/languages/languages.model";
import { RealtimeService } from "./shared/services/realtime.service";
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {

    @ViewChild("message") elementRef: ElementRef;
    // For Progressbar
    loaders = this.loader.progress$.pipe(
        delay(1000),
        withLatestFrom(this.loader.progress$),
        map((v) => v[1])
    );

    title = "af-notification";
    message: any = null;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private loader: LoadingBarService,
        public storageService: StorageService,
        private translate: TranslateService,
        public loginService:LoginService,
        public router: Router,
        public toaster:ToastrService,
        private realtimeService: RealtimeService,
        private idleDetectionService: IdleDetectionService,
        
    ) {         
        const userSelectedLanguage = this.storageService.retrieve("selectedLanguage");
        const languageCode = UILanguage.getLanguages()?.find(a => a.locale === userSelectedLanguage)?.languageCode || 'en';
        this.translate.setDefaultLang(languageCode);
        window.addEventListener('beforeunload', () => {
            this.realtimeService?.disposeCieloHubConnection();
            // this.realtimeService?.disposeIdentityConnection();
        });
    }

    async ngOnInit() {
        if(location.pathname ==  "/") this.router.navigateByUrl('auth/login');
        const accessToken = this.storageService?.retrieve('authorizationDataIdToken');
        if(accessToken  && location.pathname != '/auth/login') 
        {
            await  this.realtimeService?.createHubConnectionForCielo();
            // await  this.realtimeService?.createHubConnectionForIdentity();
        }
        setTimeout(() => {
            this.requestPermission();
        }, 3000);
    }

    requestPermission() {
        return new Promise((resolve) => {
            if (!("Notification" in window)) {
                console.log("Notifications are not supported in this browser.");
                resolve("denied");
            } else {
                Notification.requestPermission().then((permission) => {
                    if (permission == "granted") {
                        const messaging = getMessaging();
                        // Register the service worker before calling getToken
                        navigator.serviceWorker
                            .register("/firebase-messaging-sw.js")
                            .then((registration) => {
                                getToken(messaging, {
                                    vapidKey: environment.firebase.vapidKey,
                                    serviceWorkerRegistration: registration,
                                })
                                    .then((currentToken) => {
                                        if (currentToken) {
                                            this.storageService.currentDeviceToken.next(currentToken);
                                            this.storageService.store(
                                                "notificationDeviceToken",
                                                currentToken
                                            );
                                                        
                                        } else {
                                            console.log(
                                                "No registration token available. Request permission to generate one."
                                            );
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(
                                            "An error occurred while retrieving token. ",
                                            err
                                        );
                                    });
                            })
                            .catch((err) => {
                                console.error(
                                    "Error registering the service worker: ",
                                    err
                                );
                            });
                    }
                    // else if (permission === 'denied') {
                    //     alert('Notification permission required,Allow Notification from site settings');
                    //     // Do something when permission is denied.
                    // } else {
                    //     alert('Notification permission required,Allow Notification from site settings');
                    //     // Do something when permission is dismissed or not available.
                    // }
                });
            }
        });
    }
}