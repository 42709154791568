import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-common-icon',
  templateUrl: './app-common-icon.component.html',
  styleUrls: ['./app-common-icon.component.scss']
})
export class AppCommonIconComponent {
  @Input()
  public name?    : string;
  public svgIcon  : any;
  @Input() width  : number;
  @Input() height : number ;
  @Input() backgroundColor : string ='';
  @Input() currentColor: string = ''; 
  @Input() currentColorHover: string = ''; 
  constructor(
    private sanitizer: DomSanitizer,
    public renderer: Renderer2,
    public el : ElementRef,
    ) { }

    onMouseEnter() {
      if (this.currentColorHover) { 
        this.changeSvgColor(this.currentColorHover);
      }
    }
    
    onMouseLeave() {
      if (this.currentColorHover) { 
        this.changeSvgColor(this.currentColor);
      }
    }
    
    private changeSvgColor(color: string) {
      const spanElement = this?.el?.nativeElement?.querySelector('span');
      if (spanElement) {
        const svgElement = spanElement?.querySelector('svg');
        if (svgElement) {
          this.renderer.setStyle(svgElement, 'fill', color);
        }
      }
    }

  public ngOnChanges(): void {
    if (!this.name) {
      this.svgIcon = '';
      return;
    }
    fetch(`assets/images/svg-icon/${this.name}.svg`)
      .then(response => response.text()).then(value => {
        this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(
          value.replace(
            '<svg',
            `<svg style="width: ${this.width}px; height:${this.height}px; fill: ${this.currentColor}; transition: fill 0.2s ease-in-out; background: ${this.backgroundColor}"`
          )
        );
      })
      .catch(error => console.error('Error loading SVG:', error));
  }
  

}
