<!-- (mouseleave)="mouseLeavefromSelect($event)" -->
<div class="custom_dropdown_container {{dynamic_class}}"appClickOutside (clickOutside)="onOutsideClick($event)">
  <div class="custom-dropdown" [class.open]="dropdownOpen" [ngClass]="{'pe-none opacity-50': isDisabled}" >
    <div class="selected-option d-flex align-items-center" [ngClass]="{'select-focused': selectedOption?.value}"
      (click)="!isDisabled?toggleDropdown():''">

      <div class="d-flex align-items-center w-100 gap-1">
        <ng-container>
          <input [readonly]="true" [required]="isRequired" autocomplete="disabled"
            [placeholder]="placeHolderText != undefined ? placeHolderText:'REPORTS.REPORTING.FILTERPOPUP.LANGUAGE.SELECT' | translate"
            [id]="id" [name]="id" class="px-0 text-truncate cursor-pointer border-0 bg-transparent dropdown-input w-100 d-flex"
            [(ngModel)]="selectedOption!= undefined? (selectedOption?.value != selectedOption?.languageVariant[0]?.value)? (isSpanishLanguage() ? selectedOption?.spanishValue :  selectedOption?.value) +' - '+ (isSpanishLanguage() ? variantselectedOption?.spanishValue : variantselectedOption?.value) : (isSpanishLanguage() ? selectedOption?.spanishValue :  selectedOption?.value): selectedOption"
            #content placement="bottom"
            [ngbTooltip]="(isTextOverflowing ? (selectedOption?.value != selectedOption?.languageVariant[0]?.value)? (isSpanishLanguage() ? selectedOption?.spanishValue : selectedOption?.value) +' - '+ (isSpanishLanguage() ? variantselectedOption?.spanishValue : variantselectedOption?.value) : (isSpanishLanguage() ? selectedOption?.spanishValue : selectedOption?.value) : null) | translate"
            (mouseenter)="checkTextOverflow((selectedOption?.value != selectedOption?.languageVariant[0]?.value)? (isSpanishLanguage() ? selectedOption?.spanishValue : selectedOption?.value) +' - '+ (isSpanishLanguage() ? variantselectedOption?.spanishValue :  variantselectedOption?.value) : (isSpanishLanguage() ? selectedOption?.spanishValue : selectedOption?.value))">
        </ng-container>

        <div class="d-flex">
          <i id="ICON_-_drop-2" class="fa-solid fa-caret-down cursor-pointer f-16"  
          [ngClass]="{'selected': selectedOption?.value, 'default': !selectedOption?.value,'fa-caret-up':dropdownOpen}"
              ></i>
        </div>
        <!-- reset svg -->
       <div>
        <app-common-icon [style.fill]="dynamic_class=='filterDropdown' || dynamic_class === 'calendarView' ? '#fff' : '#727272'" [name]="'resetIcon'" class="reset_icon" *ngIf="selectedOption" (click)="clearSelection($event);" ></app-common-icon>
       </div>
        <!-- reset svg -->
      </div>

    </div>
    <div *ngIf="dropdownOpen" class="dropdown-options pe-0 position-absolute"
      [ngStyle]="{ 'height': getDropdownHeight() }">
      <ul Class="first_list px-1 f-12 ">
        <li
          class="text-truncate"
          [ngClass]="{'disabled':(group?.value == group?.languageVariant[0]?.value)?hasDisabled(group, undefined):null}"
          *ngFor="let group of languageDropDownDataArray;let i = index;" (click)="group.isOpen = !group.isOpen"
          (click)="group?.value == group?.languageVariant[0]?.value ?selectOption(group,undefined):null">
          <span class="group-label color-primary-grey">{{ selectedLanguage === 'en-US' ? group.value : group.spanishValue }}</span>
          <ul *ngIf="group.isOpen && group?.value != group?.languageVariant[0]?.value" class="ps-4 f-12 inside_list">
            <li class="text-truncate" [ngClass]="{'disabled':hasDisabled(group, option)}" *ngFor="let option of group?.languageVariant"
              (click)="selectOption(group,option)">
              <span class="pe-2">-</span>{{ selectedLanguage === applicationLanguages.English ? option.value : option.spanishValue }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="addLanguageIcon"
    class="add_button position-absolute top-0 d-flex align-items-center justify-content-center cursor-pointer"
    (click)="addLangugage(selectedOption,variantselectedOption)">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <path id="Path_35092" data-name="Path 35092"
        d="M150.772,141.488h-5v-5a1.25,1.25,0,1,0-2.5,0v5h-5a1.25,1.25,0,0,0,0,2.5h5v5a1.25,1.25,0,0,0,2.5,0v-5h5a1.25,1.25,0,1,0,0-2.5Z"
        transform="translate(-137.022 -135.238)" fill="#fff" />
    </svg>
  </div>
</div>