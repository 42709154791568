import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-custom-small-dropdown',
  templateUrl: './custom-small-dropdown.component.html',
  styleUrls: ['./custom-small-dropdown.component.scss']
})
export class CustomSmallDropdownComponent {

  selectedItem =  { name:'25' , value : 25};
  @Output() selectValue = new EventEmitter();

  pageSizes = [
    { name:'25' , value : 25,},
    { name:'50' , value : 50,},
    { name:'75' , value : 75},
    { name:'100', value : 100,},
    { name:"COMMON.PAGESIZE.ALL" , value : 'All'}
  ]

  select(item){
    if(this.selectedItem.value == item?.value) return;
    this.selectedItem = item;
    this.selectValue?.emit(item?.value);
  }
}
