import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
})
export class RadiobuttonComponent {
  @Input() id?:string;
  @Input() name?:string;
  @Input() radioButtonValue:any;
  @Input() radioButtonLabel:string;
  @Input() status: any;
  @Input() isRequired: boolean;
  @Input() isLabelFontMedium: boolean = false;
  @Input() disabled: boolean = false;
  @Output() check:EventEmitter<boolean>=new EventEmitter() ;


}
