import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  isIncompleteProfile = new BehaviorSubject(false);
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    // { path: "/single-page", title: "Single", type: "link", bookmark: false },
    { path: "/dashboard", title: "SIDEBAR.MENUITEMS.MENUITEM.DASHBOARD", type: "link", bookmark: false },
    { 
      title: "SIDEBAR.MENUITEMS.MENUITEM.APPOINTMENT&SCHEDULING", 
      type: "sub",
      active:false, 
      bookmark: false,
      children: [
        { path: "/appointmentsAndScheduling/request-list", title: "SIDEBAR.MENUITEMS.MENUITEM.APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST", type: "link" },
        { path: "/appointmentsAndScheduling/calendar", title: "SIDEBAR.MENUITEMS.MENUITEM.APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR", type: "link" },
        // { path: "/appointmentsAndScheduling/logs", title: "SIDEBAR.MENUITEMS.MENUITEM.APPOINTMENT&SCHEDULING.SUBMENUITEM.LOGS", type: "link" },
      ],  
    },
    { path: "/reports", title: "SIDEBAR.MENUITEMS.MENUITEM.REPORTS", type: "link", bookmark: false },
    {
      title: "SIDEBAR.MENUITEMS.MENUITEM.ADMINSETTINGS",
      type: "sub",
      active: false,
      children: [
        { path: "/admin-setting/user-management", title: "SIDEBAR.MENUITEMS.MENUITEM.ADMINSETTINGS.SUBMENUITEM.USERMANAGEMENT", type: "link" },
        { path: "/admin-setting/form-settings", title: "SIDEBAR.MENUITEMS.MENUITEM.ADMINSETTINGS.SUBMENUITEM.FORMSETTINGS", type: "link" },
        { path: "/admin-setting/audit-log", title: "SIDEBAR.MENUITEMS.MENUITEM.AUDITLOG", type: "link" },
      ],
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(JSON.parse(JSON.stringify(this.MENUITEMS)));
}
