import { DataService } from './../../shared/services/data.service';
import { Injectable } from '@angular/core';
import { ResponseModel } from '../../shared/models/response-model.model';
import { Observable, tap } from 'rxjs';
import { SelectOrganizationModel } from './select-organization.model';

@Injectable({
  providedIn: 'root'
})
export class SelectOrganizationService {

  constructor(public service:DataService) { }

  newSelectedOrganization(data: SelectOrganizationModel): Observable<ResponseModel> {
    let url = "clo-api/identity/auth/switch-role";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}
