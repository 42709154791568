<div class="unsaved-changes">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.LOGS.POPUP.DECLINEREQUEST_CONFIRMATION.TITLE' | translate}}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-1">
      <div class="text-center">
        <span class=" text-center ">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.LOGS.POPUP.DECLINEREQUEST_CONFIRMATION.DESCRIPTION1'| translate}}</span>
      </div>
      <div class="text-center">
        <span class=" text-center ">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.LOGS.POPUP.DECLINEREQUEST_CONFIRMATION.DESCRIPTION2'| translate}}</span>
      </div>
      <div class="text-center mt-2 px-3 lh-sm">
        <span class="text-center">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.LOGS.POPUP.DECLINEREQUEST_CONFIRMATION.DESCRIPTION3'| translate}}</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-2 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 d-flex justify-content-center">
        <div class="d-flex gap-4">
            <app-button class="me-1" [buttonType]="'button'" (buttonOutputEvent)="activeModal.close(false)" [buttonClass]="'button-secondary buttonBig w-70px'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.GOBACK'"></app-button>
            <app-button class="ms-1" [buttonType]="'button'" (buttonOutputEvent)="activeModal.close(true)" [buttonClass]="'button-danger-outline buttonBig w-70px'" [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.DECLINE'"></app-button>
        </div>
      </div>
    </div>
  </div>