import { ResponseModel } from './../../models/response-model.model';
import { DataService } from './../../services/data.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { CommonGetDropdownModel } from './load-dropdown-data.model';

@Injectable({
  providedIn: 'root'
})
export class LoadDropdownDataService {

  commonGetDropdownModel = new CommonGetDropdownModel();
  constructor( public service : DataService) { }

  DropDownGetData(data: number[]): Observable<ResponseModel> {
    let url = "clo-api/gateway/dropdown"
    return this.service.get(url,true,data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}