import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-interpreter-code-of-ethics',
  templateUrl: './confirm-interpreter-code-of-ethics.component.html',
  styleUrls: ['./confirm-interpreter-code-of-ethics.component.scss']
})
export class ConfirmInterpreterCodeOfEthicsComponent {
  @Input() appointmentCategoryData;
  constructor(
    public modalService : NgbModal,
    public activeModal: NgbActiveModal,
  ){}
  
  confirm(){
    this.activeModal.close({ isSuccess: true });
  }
}
