export class UserManagement {
}

export enum RoleCategoryType
{
    Internal = 1,
    Client = 2
}
export enum RoleCategoryTypeTitle
{
    Internal = "Internal",
    Client = "Client"
}

export enum TabView{
    CLIENTUSERLIST = 1,
    USERROLE = 2,
    ROLEPERMISSION = 3,
    INTERNALUSERLIST = 4
}
    
export enum OpenPopUpFrom{
    INTERNALUSERLIST = 1,
    CLIENTUSERLIST = 2,
    USERSETTINGS = 3
}

export enum InterpreterLocationType{
    USBased =1,
    International = 2
}

export enum PostDeleteUserActions{
    LeaveRequestsActive = 1,
    CancelAllRequests   = 2
}

// export enum PostDeleteUserActionsForInterpreter {
//     LeaveAssignmentsActive = 1,
//     CancelAllAssignments   = 2
// }