import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormatToDay'
})
export class DateFormatToDayPipe implements PipeTransform {
  transform(dateString: string): string {
    const date = new Date(dateString);
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
  }
}
