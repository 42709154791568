import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTitle, Roles } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import { USER_ACCOUNT_DETAILS } from '../../../../../shared/enum/common-enum';

@Component({
  selector: 'app-appoinment-updated-popup',
  templateUrl: './appoinment-updated-popup.component.html',
  styleUrls: ['./appoinment-updated-popup.component.scss']
})
export class AppoinmentUpdatedPopupComponent {
  @Input() modalTitle;
  modalTitleEnum = ModalTitle;
  roles = Roles;
  userDetails: any;
  constructor(public activeModal: NgbActiveModal,
              public storageService:StorageService){}

ngOnInit(){
this.userDetails = this.storageService.retrieve(USER_ACCOUNT_DETAILS);
}
}