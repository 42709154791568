<div ngbDropdown #dropdown="ngbDropdown" class="d-inline-block commonInputContainer w-100" (openChange)="openChange($event)">
    <label *ngIf="inputLabel" [for]="id" [ngClass]="smallInput ? 'f-11' : 'f-12'" class="form-label"><span class="label-text">{{inputLabel | translate}}</span><span *ngIf="isRequired" class="required-color">*</span></label>
    <input appTimeFormatCheck autocomplete="off" readonly [disabled]="inputDisabled" [name]="name" [id]="id" appTimeFormatCheck class="form-control" placeholder="HH:MM TT" type="text" ngbDropdownToggle [(ngModel)]="timePickerValue" [ngModel]="timePickerValue | formatTimeInput" (ngModelChange)="changeCurrentTimeToPreviousValue()" [required]="isRequired" (click)="changeCurrentTimeToPreviousValue()" />
    <!-- TIME SVG-->
    <svg (click)="!inputDisabled?dropdown?.toggle():null" [ngClass]="{'icon-disabled':inputDisabled}" class="icon-position" xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 20.004 20">
      <path id="Union_2" data-name="Union 2" d="M-10163,17870a10.014,10.014,0,0,1,10-10,10.014,10.014,0,0,1,10,10,10.013,10.013,0,0,1-10,10A10.013,10.013,0,0,1-10163,17870Zm1.553,0a8.462,8.462,0,0,0,8.449,8.455,8.462,8.462,0,0,0,8.449-8.455,8.457,8.457,0,0,0-8.449-8.449A8.46,8.46,0,0,0-10161.449,17870Zm11.083,3.008-3.1-2.326a.77.77,0,0,1-.31-.619v-4.648a.773.773,0,0,1,.776-.771.773.773,0,0,1,.776.771v4.264l2.786,2.09a.77.77,0,0,1,.157,1.082.769.769,0,0,1-.623.314A.789.789,0,0,1-10150.366,17873.006Z" transform="translate(10163.002 -17860)" fill="#b9b9b9"/>
    </svg>
    <!-- TIME SVG-->
    <div ngbDropdownMenu aria-labelledby="dropdownBasic" [ngClass]="{ 'show': timePickerVisible }">
      <ngb-timepicker [id]="'timePicker1'" [name]="'timePicker1'" 
      [(ngModel)]="ngbTimePickerValue" (ngModelChange)="updateTime($event)" [meridian]="true" class="time-picker-custom"></ngb-timepicker>
    </div>
</div>