import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { LoginService } from 'src/app/auth/login/login.service';
import { StorageService } from './storage.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from '../models/response-model.model';
import { REFRESH_TOKEN } from '../enum/common-enum';

@Injectable({
  providedIn: 'root'
})
export class IdleDetectionService {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  private lastActiveTime: number;
  private sleepCheckInterval = 60000; // Check every minute
  private sleepThreshold = 14 * 60 * 1000; // 14 minutes in milliseconds
  private checkInterval: any;

  constructor(private idle: Idle,public toaster:ToastrService ,private keepalive: Keepalive, private router: Router, private ngZone: NgZone ,public loginService:LoginService,public storageservice: StorageService,) {
    this.lastActiveTime = Date.now();

    // sets an idle timeout of 14 minutes.
    idle.setIdle(14 * 60);
    // sets a timeout period of 1 second.
    idle.setTimeout(1);
    // sets the default interrupts (e.g., clicks, scrolls, touches to reset idle timer)
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.resetIdle();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logout();
    });

    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 14 minutes
    keepalive.interval(14 * 60);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.startSleepDetection();
    this.resetIdle();

    // Add event listeners for visibility change, focus, and blur
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('focus', this.handleWindowFocus);
    window.addEventListener('blur', this.handleWindowBlur);
  }

  resetIdle() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  startSleepDetection() {
    this.ngZone.runOutsideAngular(() => {
      this.checkInterval = setInterval(() => {
        const currentTime = Date.now();
        if (currentTime - this.lastActiveTime > this.sleepThreshold) {
          this.ngZone.run(() => {
            this.logout();
          });
        }
      }, this.sleepCheckInterval);
    });
  }

  handleVisibilityChange = () => {
    const currentTime = Date.now();
    if (document.visibilityState === 'visible' && currentTime - this.lastActiveTime > this.sleepThreshold) {
      this.logout();
    } else if (document.visibilityState === 'visible') {
      this.resetIdle();
    }
    this.lastActiveTime = currentTime;
  }

  handleWindowFocus = () => {
    const currentTime = Date.now();
    if (currentTime - this.lastActiveTime > this.sleepThreshold) {
      this.logout();
    } else {
      this.resetIdle();
    }
    this.lastActiveTime = currentTime;
  }

  handleWindowBlur = () => {
    this.lastActiveTime = Date.now();
  }


  logout() {
    if(this.router.url == '/auth/login') return 
    console.log('User has been idle for 14 minutes, the PC went to sleep, or the screen was locked. Logging out...');
    const refreshToken =  this.storageservice.retrieve(REFRESH_TOKEN);
    this.loginService.SignOut({refreshToken}).subscribe((res:ResponseModel)=>{
      if(res.isSucceeded){
        this.router.navigateByUrl('auth/login');
      }
      else{
        if (res.message != undefined) {
          this.toaster.error(res.message);
      }
      if (res?.errors?.length > 0) {
          this.toaster.error(res.errors[0]);
      }
      }
    });
  }

}
