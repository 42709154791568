import { PermissionService } from 'src/app/shared/services/permission.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Component, OnInit, Inject, Input, ViewChild, OnDestroy } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NavService } from "../../services/nav.service";
import { LayoutService } from "../../services/layout.service";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Router } from "@angular/router";
import { RequestListService } from "src/app/components/appointments-and-scheduling/request-list/request-list.service";
import {HeaderService} from "./header.service"
import { RequestListComponent } from "src/app/components/appointments-and-scheduling/request-list/request-list.component";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { CreateEditRequestComponent } from "src/app/components/appointments-and-scheduling/request-list/pop-ups/create-edit-request/create-edit-request.component";
import { ModalTitle, Roles } from "../../enum/common-enum";
import { LoadDropdownDataService } from '../../common-components/load-dropdown-data/load-dropdown-data.service';
import { DropdownSections } from '../../common-components/load-dropdown-data/load-dropdown-data.model';
import { FormSettingsService } from 'src/app/components/AdminSettings/form-settings/form-settings.service';
import { LoadDropdownDataComponent } from '../../common-components/load-dropdown-data/load-dropdown-data.component';
import { Subscription } from 'rxjs';
import { UserManagementService } from 'src/app/components/AdminSettings/user-management/user-management.service';
import { LoginService } from 'src/app/auth/login/login.service';
import { ToastrService } from 'ngx-toastr';
import { RequestDeclinedPopUpComponent } from '../../common-components/popup/request-declined-pop-up/request-declined-pop-up.component';
import { RealtimeService } from '../../services/realtime.service';

SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit , OnDestroy {
  public elem: any;

  @Input() isIncompleteProfile:boolean = false;
  @ViewChild(RequestListComponent) requestListComponent: RequestListComponent;
  @ViewChild(LoadDropdownDataComponent) loadDropdownDataComponent: LoadDropdownDataComponent;
  modalTitle = ModalTitle;
  loginUserDetails: any;
  
  reassignDropdownDataSubscription: Subscription;
  ondemandRequestDecliendSubscription : Subscription;
  public onDemandSytemDeclineRequestList: [] =[];
  userRoles = Roles;
  dropdownIds = [
    { Dsi: DropdownSections.RequestCategories },
    { Dsi: DropdownSections.ScheduleOptions },
    { Dsi: DropdownSections.CommunicationTypes },
    { Dsi: DropdownSections.YesNo },
    { Dsi: DropdownSections.States },
    { Dsi: DropdownSections.Timezones },
    { Dsi: DropdownSections.ReasonsForCancellation },
    { Dsi: DropdownSections.ReasonsForEndedEarly },
    { Dsi: DropdownSections.DialCodes },
    { Dsi: DropdownSections.AuditLogSections },
    { Dsi: DropdownSections.RequestersOfOrganization},
    { Dsi: DropdownSections.Services },
    { Dsi: DropdownSections.Genders },

  ];
  updateMyInfoSubscription :Subscription

  constructor(public layout: LayoutService, public realtimeService : RealtimeService,  public toaster:ToastrService, public loginService:LoginService,public storageservice: StorageService, public userManagementService:UserManagementService,public navServices: NavService, @Inject(DOCUMENT) private document: any,private router: Router,public requestListService : RequestListService,
  public formSettingsService : FormSettingsService ,public headerService:HeaderService, private modalService: NgbModal,public storageService:StorageService,public permissionService:PermissionService,public loadDropdownDataService:LoadDropdownDataService) {
    this.realtimeOpenDeclinedPopup();
    this.getUserDetails();
    if(this.loginUserDetails?.organizations[0]?.role?.value == this.userRoles?.Requester)  this.getOndemandSystemDeclinedRequests();
    }

  ngOnInit() {
    this.elem = document.documentElement;
    this.reassignDropdownDataSubscription = this.formSettingsService.reassignDropdownData?.subscribe((res)=>{
      if(res){
        this.loadDropdownDataComponent?.ngOnChanges();
      }
    });

    this.updateMyInfoSubscription = this.userManagementService.updateMyAccountInfo.subscribe((res)=>{
      if(true){
        this.getUserDetails();
      }
    });
  }

  getUserDetails(){
    this.loginUserDetails =  this.storageService.retrieve("userAccountDetails");
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    if ((this.layout.config.settings.layout_version = "dark-only")) {
      document.body.classList.toggle("dark-only");
    }
    document.body.remove;
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  fillDropdownData(dropDownData){
    this.loadDropdownDataService.commonGetDropdownModel.requestCategories = dropDownData?.requestCategories??[];
    this.loadDropdownDataService.commonGetDropdownModel.scheduleOptions = dropDownData?.scheduleOptions??[];
    this.loadDropdownDataService.commonGetDropdownModel.communicationTypes = dropDownData?.communicationTypes??[];
    this.loadDropdownDataService.commonGetDropdownModel.onDemand = dropDownData?.onDemand??[];
    this.loadDropdownDataService.commonGetDropdownModel.states = dropDownData?.states??[];
    this.loadDropdownDataService.commonGetDropdownModel.timezones = dropDownData?.timezones??[];
    this.loadDropdownDataService.commonGetDropdownModel.reasonsForCancellation = dropDownData?.reasonsForCancellation??[];
    this.loadDropdownDataService.commonGetDropdownModel.reasonsForEndedEarly = dropDownData?.reasonsForEndedEarly??[];
    this.loadDropdownDataService.commonGetDropdownModel.auditLogSections = dropDownData?.auditLogSections??[];
    this.loadDropdownDataService.commonGetDropdownModel.dialCodes = dropDownData?.dialCodes??[];
    this.loadDropdownDataService.commonGetDropdownModel.requestersOfOrganization = dropDownData?.requestersOfOrganization??[];
    this.loadDropdownDataService.commonGetDropdownModel.services = dropDownData?.services??[];
    this.loadDropdownDataService.commonGetDropdownModel.genders = [...(dropDownData?.genders?? [])];
  }

  openCreateEditRequestPopUp() {
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "md",
      windowClass: "addEditUserList",
    };
    const modalRef = this.modalService.open(
      CreateEditRequestComponent,
      modalOption
    );
    modalRef.componentInstance.ModalTitleFromReqList = this.modalTitle.AppointmentForm;
    modalRef.result.then((res) => {
      if (res) {
        this.requestListService.triggerLoadReq();
      }
    });
  }



  //#region  ------- requestor popup  system declined --------
  realtimeOpenDeclinedPopup(){
    this.ondemandRequestDecliendSubscription = this.realtimeService?.ondemandRequestDecliend$.subscribe((appointmentRequest)=>{
      if(appointmentRequest){
        this.requestDeclinedPopUp(appointmentRequest);
      }
    })
  }


  getOndemandSystemDeclinedRequests(){
    const obj = {
      onlyOnDemandSystemDeclinedRequests : true
    }
   
    this.headerService?.getAppointmentData(obj).subscribe({
      next:(res)=>{
        const userDetails = this.storageservice.retrieve("userAccountDetails");
        this.onDemandSytemDeclineRequestList = res?.response?.filter((x)=> !x?.matchCallLanguage?.length);
        if(userDetails?.organizations[0]?.role?.value == this.userRoles?.Requester) {
          this.onDemandSytemDeclineRequestList?.forEach((item ,index)=>{
            this.requestDeclinedPopUp(item)
          })
      }
      },error:(err)=>{
        console.log(err)
      }
    })
  }

  requestDeclinedPopUp(appointmentRequest) {
    const modalOption: NgbModalOptions = {
          centered: true,
          backdrop: this.modalService.hasOpenModals() ? false : true,
          keyboard: false,
          size: "sm",
          windowClass: "request_decline_pop_up"};
            const modalRef = this.modalService.open(RequestDeclinedPopUpComponent,modalOption);
            modalRef.componentInstance.appointmentRequest = appointmentRequest;
            modalRef.result.then((res)=>{
              if (res) {
              }
            })
        }

        //#endregion

  ngOnDestroy(): void {
    this.reassignDropdownDataSubscription?.unsubscribe();
    this.updateMyInfoSubscription?.unsubscribe();
    this.ondemandRequestDecliendSubscription?.unsubscribe();
  }
}