import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { DataService } from 'src/app/shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageServiceService {

  public selectedLanguage = new BehaviorSubject(undefined);

  constructor(public service: DataService) { }
  
  updateUiLanguage(data): Observable<ResponseModel> {
    let url = "clo-api/identity/usersettings/ui-language";
    return this.service.putWithId(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}
