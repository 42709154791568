import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormatAMPM'
})
export class TimeFormatAMPMPipe implements PipeTransform {

  transform(militaryTime: string): string {
    const date = new Date(`2000-01-01T${militaryTime}`);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toUpperCase();
  }

}
