import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent {
  @Input() textArealabel:string='';
  @Input() textAreaValue:string='';
  @Output() textAreaValueEmit:EventEmitter<string> = new EventEmitter();

  constructor(){
    this.disableAutocomplete();
  }

  disableAutocomplete() {
		const formFields = document.getElementsByTagName('textarea');
		for (let i = 0; i < formFields.length; i++) {
		  formFields[i].setAttribute('autocomplete', 'off');
		}
	}

  

}
