
 <!-- appointment updated  -->
  <div *ngIf="modalTitleEnum.AppointmentUpdated == modalTitle">
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen popUpheader">
      <span class="modalTitle mx-auto font-semi-bold color-primary">{{modalTitle | translate}}</span>    
    </div>
    <div class="modal-body px-4 py-3 mx-2">
      <div class="text-center mb-3">
        <span *ngIf="userDetails.organizations[0].role.value==roles.Admin || userDetails.organizations[0].role.value==roles.TeamMember" class="color-primary-grey font-semi-bold text-center f-11">
            {{"APPOINMENT.UPDATED.POPUP.ADMIN_&_TEAM_MEMBER" | translate}}</span>
        <span *ngIf="userDetails.organizations[0].role.value==roles.Requester" class="color-primary-grey font-semi-bold text-center f-11">
          {{'APPOINMENT.UPDATED.POPUP.REQUESTERT' | translate}}</span>
        <span *ngIf="userDetails.organizations[0].role.value==roles.Interpreter">
          {{'APPOINMENT.UPDATED.POPUP.INTERPRETER' | translate}}</span>
      </div>
    </div>
    <div class="modal-footer px-3 pt-2 m-2 pb-4 mt-0 border-0 row">
      <div class="m-0 text-center">
        <span class="font-semi-bold cursor-pointer color-primary" (click)="activeModal.close()">{{"COMMON.BUTTON.BUTTONLABEL.CLOSE" | translate}}</span>
      </div>
    </div>
  </div>