import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Roles } from 'src/app/shared/enum/common-enum';
import { USER_ACCOUNT_DETAILS } from '../../../../../shared/enum/common-enum';

@Component({
  selector: 'app-request-accepted-popup',
  templateUrl: './request-accepted-popup.component.html',
  styleUrls: ['./request-accepted-popup.component.scss']
})
export class RequestAcceptedPopupComponent {
  userDetails: any;
  roles = Roles;
  constructor(
    public activeModal: NgbActiveModal,
    public storageservice: StorageService,
  ){
    
  }

  ngOnInit() {
    this.userDetails = this.storageservice.retrieve(USER_ACCOUNT_DETAILS);
  }

}