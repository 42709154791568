import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-time-picker",
    templateUrl: "./time-picker.component.html",
    styleUrls: ["./time-picker.component.scss"],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TimePickerComponent {
    timePickerVisible: boolean;
    @Input() timePickerValue: string;
    @Input() id: string;
    @Input() name: string;
    @Input() inputLabel: string;
    @Input() isRequired: boolean;
    @Input() minTime: NgbTimeStruct;
    @Input() inputDisabled: boolean;
    ngbTimePickerValue: NgbTimeStruct;
    @Output() timePickerValueEmit: EventEmitter<string> = new EventEmitter();

    constructor(private elementRef: ElementRef) {
        this.disableAutocomplete();
        this.setCurrentTime();
    }

    disableAutocomplete() {
		const formFields = document.getElementsByTagName('input');
		for (let i = 0; i < formFields.length; i++) {
		  formFields[i].setAttribute('autocomplete', 'off');
		}
	}

    setCurrentTime() {
        const currentTime = new Date();
        this.ngbTimePickerValue = {
            hour: currentTime.getHours(),
            minute: currentTime.getMinutes(),
            second: 0,
        };
        return this.ngbTimePickerValue;
    }

    ngOnInit() {

        // this.onHoursInputKeyPress = this.onHoursInputKeyPress.bind(this);
        // this.onMinutesInputKeyPress = this.onMinutesInputKeyPress.bind(this);

        const hoursInput = this.elementRef.nativeElement.querySelector('[aria-label="Hours"]');
        const minutesInput = this.elementRef.nativeElement.querySelector('[aria-label="Minutes"]');
    
        if (hoursInput && minutesInput) {
        //   hoursInput.addEventListener('keypress', this.onHoursInputKeyPress.bind(this));
        //   minutesInput.addEventListener('keypress', this.onMinutesInputKeyPress.bind(this));
          minutesInput.addEventListener('keydown', this.keydown.bind(this));
          hoursInput.addEventListener('keydown', this.keydown.bind(this));
        }
    }

    
    updateTime(selectedTime: NgbTimeStruct) {
        // // Format the selected time as HH:MM
        // // Check if selected time is earlier than the minimum time
        // if (this.isTimeEarlier(selectedTime, this.minTime)) {
        //     // Set the selected time to the minimum time
        //     this.ngbTimePickerValue = this.minTime;
        // } else {
        //     // Set the selected time
        //     this.ngbTimePickerValue = selectedTime;
        // }
        if(!selectedTime) return;
        this.ngbTimePickerValue = selectedTime;
        const formattedTime = this.formatTime(this.ngbTimePickerValue);
        // Update the input field value
        this.timePickerValue = formattedTime;
        this.timePickerValueEmit.emit(this.timePickerValue);
    }

    formatTime(time: NgbTimeStruct): string {
        // Format the time as HH:MM
        const hour = time.hour.toString().padStart(2, "0");
        const minute = time.minute.toString().padStart(2, "0");
        return `${hour}:${minute}`;
    }

    // Method to compare two NgbTimeStruct objects
    isTimeEarlier(time1: NgbTimeStruct, time2: NgbTimeStruct): boolean {
        if (time1?.hour < time2.hour) {
            return true;
        } else if (time1?.hour === time2.hour && time1?.minute < time2.minute) {
            return true;
        } else {
            return false;
        }
    }

    ngOnChanges() {
        if (this.timePickerValue) {
            this.timePickerValueEmit.emit(this.timePickerValue);
            this.timePickerValue = this.removeSecondsFromTimeString(
                this.timePickerValue
            );
        }
    }

    removeSecondsFromTimeString(timeString: string): string {
        // Split the time string by ":"
        if (timeString?.length > 5) {
            const timeParts = timeString.split(":");

            // Remove the last element (seconds)
            timeParts.pop();

            // Join the remaining parts back into a string
            const formattedTime = timeParts.join(":");

            return formattedTime;
        } else {
            return timeString;
        }
    }

    changeCurrentTimeToPreviousValue() {
        if (this.timePickerValue && this.timePickerValue != "") {
            this.ngbTimePickerValue = this.parseTimeStringToNgbTimeStruct(
                this.timePickerValue
            );
        } else {
            this.ngbTimePickerValue = this.setCurrentTime();
        }
    }

    parseTimeStringToNgbTimeStruct(timeString: string): NgbTimeStruct {
        const [hours, minutes] = timeString.split(":").map(Number);
        const time: NgbTimeStruct = { hour: hours, minute: minutes, second: 0 };
        return time;
    }

    handleKeyDown(event: KeyboardEvent) {
        if (event.key != "Tab" && event.key != "Enter") {
            event.preventDefault();
        }
    }

    // Listen to dropdown open and close events to enable/disable input field
    // @HostListener("document:click", ["$event"])
    // clickout(event: any) {
    //     if (!event.target.closest(".ngb-dropdown")) {
    //         this.timePickerVisible = false;
    //     }
    // }

    // toggleTimePicker() {
    //     this.timePickerVisible = !this.timePickerVisible;
    // }


   onHoursInputKeyPress(event: KeyboardEvent): void {
    this.restrictInput(event, 12);
  }

   onMinutesInputKeyPress(event: KeyboardEvent): void {
    this.restrictInput(event, 59);
  }

   restrictInput(event: KeyboardEvent, maxValue: number): void {
    const input = event.key;
    const currentValue = (event.target as HTMLInputElement).value + input;
    const newValue = parseInt(currentValue);

    if (isNaN(newValue) || newValue > maxValue) {
      event.preventDefault();
    }
  }

  keydown(event){
    const currentElement = (event.target as HTMLInputElement);
    if(event.code =='Enter'){
        event?.preventDefault()
        this.updateTime(this.ngbTimePickerValue)
        if(currentElement?.ariaLabel == 'Hours'){
            const minutesInput = this.elementRef?.nativeElement?.querySelector('[aria-label="Minutes"]');
            minutesInput?.select(); 
            // minutesInput?.focus();
            } else if (currentElement?.ariaLabel == 'Minutes'){
                const element = this.elementRef?.nativeElement?.querySelector('.ngb-tp-meridian');
            }
        }
    }


  ngOnDestroy(): void {
    const hoursInput = this.elementRef?.nativeElement?.querySelector('[aria-label="hours"]');
    const minutesInput = this.elementRef?.nativeElement?.querySelector('[aria-label="minutes"]');

    if (hoursInput && minutesInput) {
      hoursInput.removeEventListener('keypress', this.onHoursInputKeyPress.bind(this));
      minutesInput.removeEventListener('keypress', this.onMinutesInputKeyPress.bind(this));
    }
  }

//   keydownUpdateValue(e){
//     this.updateTime(this.ngbTimePickerValue)
//   }

    openChange(event){
        if(event){
            setTimeout(()=>{
                const hoursInput = this.elementRef.nativeElement.querySelector('[aria-label="Hours"]');
                const horsInput = this.elementRef.nativeElement.querySelector('#date-time-edit');
                // hoursInput?.focus();
                hoursInput?.select();
            },100)
        }
    }

}

